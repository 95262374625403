import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useGetIdentity,
  useNavigation,
  useNotification,
  useShow,
  useUpdate,
} from '@pankod/refine-core';
import {
  Alert,
  Avatar,
  Button,
  Card,
  Col,
  DateField,
  Descriptions,
  Divider,
  EditButton,
  EmailField,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  NumberField,
  Row,
  Show,
  ShowButton,
  Space,
  Spin,
  Table,
  TagField,
  TextField,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import {
  AntDesignOutlined,
  UserOutlined,
  SolutionOutlined,
} from '@ant-design/icons';

import { IPicture, IUser } from 'interfaces';
import React, { useContext, useEffect, useState } from 'react';
import {
  MinusSquareOutlined,
  PlusSquareOutlined,
  ApiOutlined,
  FileSearchOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { Role } from '../../enums/role';
import { UserType } from '../../enums/user.type';
import { ScanImage } from '../../components/offers/scanImage';
import { UserStatus } from '../../enums/user.status';
import { axiosInstance } from '../../App';
import { AxiosError } from 'axios';
import { DocumentType } from '../../enums/document.type';
import { type } from 'os';
import { ModalAgentList } from 'components/users/modalAgentList';
import { appendFileSync } from 'fs';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { PersonalDocuments } from './documents';
import { ModalAddRole } from 'components/profile/addRole';
import { UserContext } from 'UserProvider';

const { Text, Title } = Typography;

export const ProfileUserShow: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const showManual = process.env.REACT_APP_PROD === '0';
  type CategoryMutationResult = {
    id: number;
    title: string;
  };
  const navigate = useNavigate();

  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const [record, setRecord] = useState<IUser | null>(me);
  const { open: notify } = useNotification();
  const refetch = async () => {
    if (notify) {
      try {
        if (me !== null) {
          const userResponse = await axiosInstance.get(
            process.env.REACT_APP_BACKEND_URL + '/api/v1/users/' + me.uuid,
          );

          const user: IUser = await userResponse.data;
          await setRecord(user);
        }
      } catch (e: any) {
        notify({
          description: 'Ошибка',
          message: 'Ошибка получения данных',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  useEffect(() => {
    refetch();
  }, []);

  let avatar;
  if (record) {
    avatar = record.pictures?.find(
      (p: IPicture) => p.type === DocumentType.AVATAR,
    );
  }
  return record ? (
    <>
      <CanAccess resource="profile" action="show">
        <Row gutter={16}>
          <Col md={{ span: 6 }} style={{ width: '100%' }}>
            <Card>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center ',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: 8,
                  }}
                >
                  {!avatar && (
                    <Avatar
                      style={{
                        width: '60%',
                        minHeight: '80px',
                        minWidth: '80px',
                        height: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      size={64}
                      shape="square"
                      icon={<UserOutlined />}
                    />
                  )}
                  {avatar && (
                    <Avatar
                      style={{ width: '60%', height: 'auto' }}
                      shape="square"
                      icon={<UserOutlined />}
                      src={process.env.REACT_APP_BACKEND_URL + avatar.path}
                    />
                  )}
                </div>
                <Text strong style={{ textAlign: 'center' }}>
                  {record?.lastName} {record?.firstName}
                </Text>
                <Text style={{ textAlign: 'center' }}>{me?.role}</Text>

                <Divider />

                <Button
                  onClick={() => navigate(`/users/edit/${record.uuid}`)}
                  children="Править"
                />
              </div>
            </Card>
          </Col>
          <Col md={{ span: 18 }} style={{ width: '100%' }}>
            <Card
              title={<Text type="secondary">Лицевой счет № {record.uuid}</Text>}
              bodyStyle={{ padding: 0 }}
            >
              <Descriptions title="" bordered column={{ xxl: 1, md: 1, xs: 1 }}>
                <Descriptions.Item label="ФИО">
                  <TextField value={record?.firstName} />
                </Descriptions.Item>
                <Descriptions.Item label="Телефон">
                  <TextField value={record.phone} />
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  <TagField value={record.email} color="blue" />
                </Descriptions.Item>
                <Descriptions.Item label="Зарегистрирован как">
                  <EmailField value={record.email} />
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
      </CanAccess>
    </>
  ) : (
    <Spin style={{ padding: 20 }} />
  );
};
