import {
  Button,
  Card,
  Col,
  DateField,
  Descriptions,
  NumberField,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  TagField,
  TextField,
  Tooltip,
  Typography,
  Upload,
} from '@pankod/refine-antd';
import { CanAccess, useNotification } from '@pankod/refine-core';
import { PaymentType } from 'enums/payment.type';
import { IOffer, IUser } from 'interfaces';
import { PlusSquareOutlined, FileAddOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { axiosInstance } from 'App';
import { UserType } from 'enums/user.type';
import { UserStatus } from 'enums/user.status';
import { Role } from 'enums/role';
import { DocumentType } from 'enums/document.type';
import { uuidV4 } from 'utility/uuidv4';
import { AgentOfferType } from 'enums/agent.offer.type';
import moment from 'moment';
const { Text } = Typography;

const OfferInfo = function (props: { offer: IOffer; refetch: any }) {
  return props.offer ? (
    <Card size="small" title="Сведения о запрашиваемом займе">
      <Row gutter={[16, 16]} style={{ width: '100%' }}>
        <Col sm={24} md={24} lg={12} xl={12} xxl={8}>
          <Card hoverable size="small">
            <Descriptions
              title={
                <Text type="secondary">Сведения о запрашиваемом займе</Text>
              }
              bordered
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="Агент">
                <TextField
                  value={
                    props.offer.agent ? props.offer.agent.orgName : 'Без агента'
                  }
                />
              </Descriptions.Item>
              <Descriptions.Item label="Тип расчета ставки агента">
                <TextField value={props.offer.agentProcentType} />
              </Descriptions.Item>
              {props.offer.agentProcentType === AgentOfferType.PROCENT ? (
                <>
                  <Descriptions.Item label="Процент агента">
                    <TextField value={props.offer.agentProcent} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Процент компании">
                    <TextField value={props.offer.companyProcent} />
                  </Descriptions.Item>
                </>
              ) : (
                <>
                  <Descriptions.Item label="Сумма агента">
                    <TextField
                      value={`${Number(props.offer.agentSumm).toLocaleString(
                        'ru-RU',
                      )} ₽`}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Сумма компании">
                    <TextField
                      value={`${Number(props.offer.companySumm).toLocaleString(
                        'ru-RU',
                      )} ₽`}
                    />
                  </Descriptions.Item>
                </>
              )}
              <Descriptions.Item label="Процент по договору">
                <TextField value={props.offer.offerProcent} />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col sm={24} md={24} lg={12} xl={12} xxl={8}>
          <Card hoverable size="small">
            <Descriptions
              title={<Text type="secondary">Данные заявки</Text>}
              bordered
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="Тип займа">
                <TextField value={props.offer.purchaseType} />
              </Descriptions.Item>
              <Descriptions.Item label="Сумма займа">
                <TextField
                  value={`${Number(props.offer.purchaseSumm).toLocaleString(
                    'ru-RU',
                  )} ₽`}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Срок займа">
                <TextField value={'30 дней'} />
              </Descriptions.Item>
              <Descriptions.Item label="Гарантийный взнос">
                <TextField
                  value={`${Number(
                    props.offer.purchaseGuarantee,
                  ).toLocaleString('ru-RU')} ₽`}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Номер заявки">
                <TextField value={props.offer.offerNumber} />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        {props.offer.purchase && (
          <Col sm={24} md={24} lg={24} xl={24} xxl={8}>
            <Card hoverable size="small">
              <Descriptions
                title={<Text type="secondary">Общая информация о закупке</Text>}
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                <Descriptions.Item label="№ Аукциона">
                  <TextField value={props.offer.purchase} />
                </Descriptions.Item>
                <Descriptions.Item label="Площадка">
                  <TextField value={props.offer.purchasePortal} />
                </Descriptions.Item>
                <Descriptions.Item label="Наименование закупки">
                  <TextField value={props.offer.purchaseInfo} />
                </Descriptions.Item>
                <Descriptions.Item label="Дата и время окончания срока подачи заявок на участие в электронном аукционе">
                  <TextField
                    value={moment(props.offer.purchaseDateFinalDocs).format(
                      'DD.MM.YYYY HH:mm',
                    )}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Дата окончания срока рассмотрения заявок на участие в электронном аукционе">
                  <TextField
                    value={moment(props.offer.purchaseDateFinalOffer).format(
                      'DD.MM.YYYY',
                    )}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Дата проведения электронного аукциона">
                  <TextField
                    value={moment(props.offer.purchaseDate).format(
                      'DD.MM.YYYY',
                    )}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Начальная (максимальная) цена договора">
                  <TextField
                    value={`${Number(
                      props.offer.purchaseOfferMax,
                    ).toLocaleString('ru-RU')} ₽`}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Размер обеспечения заявки на участие в электронном аукционе">
                  <TextField
                    value={`${Number(props.offer.purchaseSumm).toLocaleString(
                      'ru-RU',
                    )} ₽`}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Размер обеспечения исполнения обязательств по договору">
                  <TextField
                    value={`${Number(
                      props.offer.purchaseElectronAuction,
                    ).toLocaleString('ru-RU')} ₽`}
                  />
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        )}
      </Row>
    </Card>
  ) : (
    <Spin style={{ padding: 20 }} />
  );
};

export default OfferInfo;
