import { IOffer } from 'interfaces';
import moment from 'moment';

export default function dateOfCancel(offer: IOffer | undefined): string {
  if (!offer) return '';
  if (!offer.dateFactIssue) return '';
  if (!offer.prolongs?.length) {
    return moment(offer?.dateFactIssue).add(30, 'days').format('DD.MM.YYYY');
  } else if (offer.prolongs?.length) {
    if (offer.prolongs[offer.prolongs?.length - 1].date) {
      return moment(offer.prolongs[offer.prolongs?.length - 1].date).format(
        'DD.MM.YYYY',
      );
    } else if (offer.prolongs?.length > 1) {
      if (offer.prolongs[offer.prolongs?.length - 2].date) {
        return moment(offer.prolongs[offer.prolongs?.length - 2].date).format(
          'DD.MM.YYYY',
        );
      } else {
        return moment(offer?.dateFactIssue)
          .add(30, 'days')
          .format('DD.MM.YYYY');
      }
    } else {
      return moment(offer?.dateFactIssue).add(30, 'days').format('DD.MM.YYYY');
    }
  }

  return '';
}
