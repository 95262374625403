import {
  CrudFilters,
  IResourceComponentsProps,
  useGetIdentity,
  useNavigation,
  useNotification,
} from '@pankod/refine-core';
import {
  Button,
  Card,
  CreateButton,
  DateField,
  EditButton,
  EmailField,
  FilterDropdown,
  FilterDropdownProps,
  getDefaultSortOrder,
  Input,
  List,
  NumberField,
  Select,
  Show,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  Tooltip,
  useTable,
  DatePicker,
  Form,
  Row,
  Col,
  Typography,
} from '@pankod/refine-antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const { Text } = Typography;
const { RangePicker } = DatePicker;

import { SolutionOutlined, FileAddOutlined } from '@ant-design/icons';
import { IOffer, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { Role } from '../../enums/role';
import { ModalAgentList } from 'components/users/modalAgentList';
import { UserContext } from 'UserProvider';
import { axiosInstance } from 'App';
import moment from 'moment';
import { ProlongType } from 'enums/prolong.type';

export const ReportList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const dateFormat = 'DD.MM.YYYY';
  const [loading, setLoading] = useState<boolean>(false);
  const [reportData, setReportData] = useState<any[]>([]);
  const { open: notify } = useNotification();
  const [form] = Form.useForm();

  const [summ, setSumm] = useState<number>(0);

  const calculate = (array: any[]) => {
    if (!array) return 0;
    let summWithdraw = 0;
    let summProlong = 0;
    array.map((el: any) => {
      for (const item of el.data[0]) {
        if (item.type === ProlongType.PROLONG) {
          summProlong =
            summProlong +
            +item.data.reduce(
              (
                result: number,
                sum: { prolongAgentProcent: string; offer: IOffer },
              ) => {
                if (sum.prolongAgentProcent) {
                  return (result =
                    +result +
                    (+sum.offer.purchaseSumm / 100) * +sum.prolongAgentProcent);
                } else {
                  return (result =
                    +result +
                    (+sum.offer.purchaseSumm / 100) * +sum.offer.agentProcent +
                    +sum.offer.agentSumm);
                }
              },
              [0],
            );
        } else {
          summWithdraw =
            summWithdraw +
            +item.data.reduce(
              (result: number, sum: { offer: IOffer }) => {
                return (result =
                  +result +
                  (+sum.offer.purchaseSumm / 100) * +sum.offer.agentProcent +
                  +sum.offer.agentSumm);
              },
              [0],
            );
        }
      }
    });
    setSumm(+summWithdraw + +summProlong);
  };

  const handleCreateReport = async (value: { period: any[] }) => {
    if (notify) {
      const dateFrom = dayjs(value.period[0]).format('YYYY-MM-DD');
      const dateTo = dayjs(value.period[1]).format('YYYY-MM-DD');
      setLoading(true);
      try {
        const response = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/report/create',
          {
            dateFrom,
            dateTo,
          },
        );
        setReportData(response.data);

        await setLoading(false);
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: 'Возникла проблема , сообщите сис-админу',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
        setTimeout(async () => {
          setLoading(false);
        }, 2000);
      }
    }
  };

  const downloadReport = async () => {
    const value = form.getFieldValue('period');
    const dateFrom = dayjs(value[0]).format('YYYY-MM-DD');
    const dateTo = dayjs(value[1]).format('YYYY-MM-DD');
    if (notify) {
      setLoading(true);
      try {
        const result = await axiosInstance.get(
          process.env.REACT_APP_BACKEND_URL +
            `/api/v1/report/createExcel?from=${dateFrom}&to=${dateTo}`,
          { responseType: 'blob' },
        );
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Qliq_report.xlsx');
        document.body.appendChild(link);
        link.click();

        await setLoading(false);
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: 'Возникла проблема , сообщите сис-админу',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
        setTimeout(async () => {
          setLoading(false);
        }, 2000);
      }
    }
  };
  useEffect(() => {
    calculate(reportData);
  }, [reportData]);

  const columns = [
    {
      title: 'Название организации',
      dataIndex: 'orgName',
      key: 'orgName',
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
      key: 'inn',
      render: (value: any) => <>{value !== 0 ? value : ''}</>,
    },
    {
      title: 'Пролонгации',
      dataIndex: 'data',
      key: 'countProlong',
      render: (value: any[]) => {
        const repoCount = value[0].find(
          (report: any) => report.type === ProlongType.PROLONG,
        );
        return (
          <Text>{repoCount?.data?.length ? repoCount?.data?.length : '0'}</Text>
        );
      },
    },

    {
      title: 'Сумма пролонгаций',
      dataIndex: 'data',
      key: 'countProlong',
      render: (value: any[]) => {
        const withdraw: any = value[0].find(
          (withd: any) => withd.type === ProlongType.PROLONG,
        );
        const withdrawSumm = withdraw
          ? withdraw.data.reduce(
              (result: any, item: { offer: IOffer }) =>
                (result = +result + +item.offer.purchaseSumm),
              [0],
            )
          : '0';
        return (
          <Text>
            {Number(withdrawSumm).toLocaleString('ru-Ru', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </Text>
        );
      },
    },
    {
      title: 'Выдачи',
      dataIndex: 'data',
      key: 'countProlong',
      render: (value: any[]) => {
        const repoCount = value[0].find(
          (report: any) => report.type === ProlongType.WITHDRAW,
        );
        return (
          <Text>{repoCount?.data?.length ? repoCount?.data?.length : '0'}</Text>
        );
      },
    },
    {
      title: 'Сумма выдач',
      dataIndex: 'data',
      key: 'countProlong',
      render: (value: any[]) => {
        const withdraw: any = value[0].find(
          (withd: any) => withd.type === ProlongType.WITHDRAW,
        );
        const withdrawSumm = withdraw
          ? withdraw.data.reduce(
              (result: any, item: { offer: IOffer }) =>
                (result = +result + +item.offer.purchaseSumm),
              [0],
            )
          : '0';
        return (
          <Text>
            {Number(withdrawSumm).toLocaleString('ru-Ru', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </Text>
        );
      },
    },
    {
      title: <Text style={{ float: 'right' }}>Сумма комиссии</Text>,
      dataIndex: 'data',
      key: 'countProlong',
      render: (value: any[]) => {
        let summWithdraw = 0;
        let summProlong = 0;
        for (const item of value[0]) {
          if (item.type === ProlongType.PROLONG) {
            summProlong = item.data.reduce(
              (
                result: number,
                sum: { prolongAgentProcent: string; offer: IOffer },
              ) => {
                if (sum.prolongAgentProcent) {
                  return (result =
                    +result +
                    (+sum.offer.purchaseSumm / 100) * +sum.prolongAgentProcent);
                } else {
                  return (result =
                    +result +
                    (+sum.offer.purchaseSumm / 100) * +sum.offer.agentProcent +
                    +sum.offer.agentSumm);
                }
              },
              0,
            );
          } else {
            summWithdraw = item.data.reduce(
              (result: number, sum: { offer: IOffer }) => {
                return (result =
                  +result +
                  (+sum.offer.purchaseSumm / 100) * +sum.offer.agentProcent +
                  +sum.offer.agentSumm);
              },
              0,
            );
          }
        }
        return (
          <Text style={{ float: 'right' }}>
            {Number(+summWithdraw + +summProlong).toLocaleString('ru-Ru', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </Text>
        );
      },
    },
  ];

  return (
    <Show
      title="Отчеты"
      isLoading={loading}
      breadcrumb={['']}
      contentProps={{ style: { background: '#d7d7d7' } }}
      headerButtons={[]}
    >
      <Space direction="vertical" size={12} style={{ width: '100%' }}>
        <Card
          hoverable
          title="Укажите диапозон и сформируйте отчет"
          size="small"
        >
          <Form form={form} onFinish={handleCreateReport}>
            <Form.Item
              label="Период"
              name="period"
              initialValue={[
                dayjs(dayjs().startOf('month'), dateFormat),
                dayjs(dayjs().endOf('month'), dateFormat),
              ]}
            >
              <RangePicker format={dateFormat} />
            </Form.Item>
            <Row gutter={[12, 12]}>
              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Просмотреть отчет
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button type="primary" onClick={downloadReport}>
                    Скачать отчет
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card title="Отчет">
          <Table
            size="small"
            dataSource={reportData}
            columns={columns}
            pagination={false}
            footer={() => (
              <div style={{ float: 'right' }}>
                <Text strong>{`Итого : ${Number(summ).toLocaleString('ru-Ru', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}`}</Text>
              </div>
            )}
          />
        </Card>
      </Space>
    </Show>
  );
};
