import {
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Show,
  Space,
  Steps,
  TextField,
  Typography,
  Modal,
  Tabs,
  Alert,
  Upload,
  List,
  AntdList,
  Avatar,
} from '@pankod/refine-antd';
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNotification,
  useShow,
} from '@pankod/refine-core';
import { AgreementType } from 'enums/agreement.type';
import { InvestmentStatus } from 'enums/investment.status';
import { IAgreement, IInvestment, IOffer, IPicture, IUser } from 'interfaces';
import React, { useContext, useEffect, useState } from 'react';
import { HeaderStepperBlock } from '../../components/offers/header/headerStepperBlock';
import { OfferStatus } from 'enums/offer.status';
import { Role } from 'enums/role';
import { DescriptionsBlockMain } from '../../components/offers/descriptionsBlock';
import { OfferTabs } from 'pages/offers';
import { UserContext } from 'UserProvider';
import Info from 'components/offers/show/info';
import OfferInfo from 'components/offers/show/offer';
import { axiosInstance } from 'App';
import { PrinterOutlined } from '@ant-design/icons';
import { ModalStatuses } from 'components/offers/modals/modalStatuses';
import {
  AndroidOutlined,
  AppleOutlined,
  InfoCircleOutlined,
  SnippetsOutlined,
  FieldTimeOutlined,
  FileExclamationOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import Prolong from 'components/offers/tabs/prolong';
import moment from 'moment';
import { OfferType } from 'enums/offer.type';
import Delay from 'components/offers/tabs/delay';
import dateOfCancel from 'utility/cancelOffer';
import { DocumentType } from 'enums/document.type';
import { uuidV4 } from 'utility/uuidv4';

const { Text } = Typography;

function saveDoc(url: string, filename: string) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      console.log('Файл успешно сохранен.');
    })
    .catch((error) => {
      console.error('Ошибка при сохранении файла:', error);
    });
}

export const OfferShow: React.FC<IResourceComponentsProps> = () => {
  // const { data: me } = useGetIdentity<IUser>();
  const userContext = useContext(UserContext);
  const [currentTab, setCurrentTab] = useState<string>('1');
  const me = userContext ? userContext.user : null;
  const [loading, setLoading] = useState<boolean>(false);
  const [openModalStatuses, setOpenModalStatuses] = useState<boolean>(false);
  const { open: notify } = useNotification();
  const {
    queryResult: { data, isLoading, refetch: refetchOffer },
  } = useShow<IOffer>();
  const record = data?.data;
  const [loadingBtn, setLoadinBtn] = useState<boolean>(false);
  const createDocs = async () => {
    setLoadinBtn(true);
    if (notify) {
      setLoading(true);
      if (!record) return;
      try {
        {
          const response = await axiosInstance.post(
            process.env.REACT_APP_BACKEND_URL + '/api/v1/agreements/create/',
            { uuid: record.uuid },
          );
          notify({
            description: 'Удачно',
            message: 'Данные сохранены',
            type: 'success',
            key: '2',
            undoableTimeout: 20000,
          });
          refetchOffer();
          setLoadinBtn(false);
          await setLoading(false);
        }
      } catch (error) {
        console.log(error);
        notify({
          description: 'Ошибка',
          message: 'Возникла проблема , сообщите сис-админу',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
        setTimeout(async () => {
          setLoadinBtn(false);
          setLoading(false);
        }, 2000);
      }
    }
  };

  const handleDownloadArchive = async () => {
    setLoadinBtn(true);
    if (notify) {
      setLoading(true);
      if (!record) return;
      try {
        {
          const response = await axiosInstance
            .get(
              `${process.env.REACT_APP_BACKEND_URL}/api/v1/agreements/download/${record.uuid}`,
              {
                responseType: 'blob', // Установите тип ответа на 'blob'
              },
            )
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                `Документы ${record.offerNumber}.zip`,
              );
              document.body.appendChild(link);
              link.click();
              link.parentNode?.removeChild(link);
            })
            .catch((error) => {
              console.error('Ошибка при скачивании архива:', error);
            });

          notify({
            description: 'Удачно',
            message: 'Данные сохранены',
            type: 'success',
            key: '2',
            undoableTimeout: 20000,
          });

          setLoadinBtn(false);
          await setLoading(false);
        }
      } catch (error) {
        console.log(error);
        notify({
          description: 'Ошибка',
          message: 'Возникла проблема , сообщите сис-админу',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
        setTimeout(async () => {
          setLoadinBtn(false);
          setLoading(false);
        }, 2000);
      }
    }
  };

  const startProlong = async (days: number) => {
    setLoadinBtn(true);
    if (notify) {
      setLoading(true);
      if (!record) return;
      try {
        {
          const response = await axiosInstance.post(
            process.env.REACT_APP_BACKEND_URL + '/api/v1/offers/prolong/create',
            { uuid: record.uuid, days },
          );
          notify({
            description: 'Удачно',
            message: 'Данные сохранены',
            type: 'success',
            key: '2',
            undoableTimeout: 20000,
          });
          refetchOffer();
          setLoadinBtn(false);
          await setLoading(false);
        }
      } catch (error) {
        console.log(error);
        notify({
          description: 'Ошибка',
          message: 'Возникла проблема , сообщите сис-админу',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
        setTimeout(async () => {
          setLoadinBtn(false);
          setLoading(false);
        }, 2000);
      }
    }
  };

  const uploadedDoc = record?.uploadedOfferDoc
    ? record.uploadedOfferDoc.filter(
        (doc: IPicture) => doc.type === DocumentType.OTHER,
      )
    : [];

  const apiUrl = useApiUrl();
  const tabs = [
    {
      icon: <InfoCircleOutlined />,
      key: '1',
      label: 'О займе',
      children: (
        <>
          {record && (
            <Space size={16} direction="vertical">
              <Info offer={record} refetch={refetchOffer} />
              <OfferInfo offer={record} refetch={refetchOffer} />
            </Space>
          )}
        </>
      ),
    },

    {
      icon: <SnippetsOutlined />,
      key: '2',
      label: <Text>Документы</Text>,
      children: (
        <Space size={12} direction="vertical" style={{ width: '100%' }}>
          {record && record.agreements.length !== 0 && (
            <Card
              title="Сформированные документы"
              extra={
                <Button
                  type="primary"
                  size="small"
                  onClick={handleDownloadArchive}
                >
                  Скачать архивом
                </Button>
              }
            >
              <Row gutter={[12, 12]}>
                {record.agreements
                  .filter((doc: IAgreement) => doc.type !== AgreementType.OTHER)
                  .map((card: IAgreement) => {
                    return (
                      <Col>
                        <Card
                          hoverable
                          title={card.type}
                          size="small"
                          bodyStyle={{ padding: 0 }}
                          style={{ maxWidth: '280px' }}
                          onClick={() => {
                            window.open(
                              process.env.REACT_APP_BACKEND_URL + card.pdf,
                              '_blank',
                            );
                          }}
                        >
                          <iframe
                            src={process.env.REACT_APP_BACKEND_URL + card.pdf}
                            width="100%"
                            style={{ border: 'none', height: '320px' }}
                            title="PDF Viewer"
                          />
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Card>
          )}
          {record && (
            <Card title="Другие документы">
              <Space size={12} direction="vertical" style={{ width: '100%' }}>
                <Upload
                  name="file"
                  action={`${apiUrl}/media/uploadDocument`}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  }}
                  multiple
                  style={{ padding: '4px 16px' }}
                  onChange={(e) => {
                    if (e.file.status === 'done') {
                      refetchOffer();
                    }
                  }}
                  onRemove={(e) => {
                    // console.log(e);
                  }}
                  data={(e) => {
                    const fileUuid = uuidV4();
                    // addOrRemoveItem(files, fileUuid);
                    // form.setFieldsValue({
                    //   files,
                    // });
                    return {
                      fileUuid,
                      type: AgreementType.OTHER,
                      origName: e.name,
                      offerUuid: record.uuid,
                    };
                  }}
                >
                  <Button type="primary" icon={<UploadOutlined />}>
                    Загрузить новые
                  </Button>
                </Upload>

                <AntdList
                  itemLayout="horizontal"
                  dataSource={uploadedDoc}
                  renderItem={(item, index) => (
                    <AntdList.Item>
                      <AntdList.Item.Meta
                        avatar={
                          <Avatar
                            src={
                              'https://api.dicebear.com/7.x/icons/svg?seed=Casper'
                            }
                          />
                        }
                        title={
                          <Button
                            onClick={() =>
                              saveDoc(
                                process.env.REACT_APP_BACKEND_URL + item.path,
                                item.origName ? item.origName : 'Документ',
                              )
                            }
                          >
                            {item.origName}
                          </Button>
                        }
                      />
                    </AntdList.Item>
                  )}
                />
                {/* {record.uploadedOfferDoc &&
                    record.uploadedOfferDoc
                      .filter(
                        (doc: IPicture) => doc.type === DocumentType.OTHER,
                      )
                      .map((card: IPicture) => {
                        return (
                          <Card
                            hoverable
                            title={card.type}
                            size="small"
                            bodyStyle={{ padding: 0 }}
                            style={{ maxWidth: '280px' }}
                            onClick={() => {
                              window.open(
                                process.env.REACT_APP_BACKEND_URL + card.path,
                                '_blank',
                              );
                            }}
                          >
                            <iframe
                              src={
                                process.env.REACT_APP_BACKEND_URL + card.path
                              }
                              width="100%"
                              style={{ border: 'none', height: '320px' }}
                              title="PDF Viewer"
                            />
                          </Card>
                        );
                      })} */}
              </Space>
            </Card>
          )}
        </Space>
      ),
    },
    {
      icon: <FieldTimeOutlined />,
      key: '3',
      label: 'Пролонгация ',
      children: record ? (
        <Prolong record={record} refetch={refetchOffer} />
      ) : (
        <>Нет информации</>
      ),
    },
    {
      icon: <FileExclamationOutlined />,
      key: '4',
      label: 'Просрочка ',
      children: record ? (
        <Delay record={record} refetch={refetchOffer} />
      ) : (
        <>Нет информации</>
      ),
    },
  ];

  const btnsHeader = [
    [],
    [],
    [
      <Button type="primary" onClick={createDocs} loading={loadingBtn}>
        Сформировать документы
      </Button>,
    ],
    [
      record?.agreements.length ? (
        <>
          {(record?.purchaseType === OfferType.FZ ||
            record?.purchaseType === OfferType.NORMAL) &&
            (!record?.prolongs || record?.prolongs?.length < 4) && (
              <Button
                type="primary"
                onClick={() => {
                  startProlong(7);
                }}
                loading={loadingBtn}
              >
                Начать пролонгацию{' '}
                {record?.prolongs ? record.prolongs.length + 1 : 1} на 7 дней
              </Button>
            )}
          {(!record?.prolongs || record?.prolongs?.length < 3) && (
            <Button
              type="primary"
              onClick={() => {
                startProlong(30);
              }}
              loading={loadingBtn}
            >
              Начать пролонгацию{' '}
              {record?.prolongs ? record.prolongs.length + 1 : 1} на 30 дней
            </Button>
          )}
        </>
      ) : (
        <></>
      ),
    ],
  ];
  return (
    <Show
      isLoading={isLoading}
      // breadcrumb={['']}
      contentProps={{ style: { background: '#d7d7d7' } }}
      headerButtons={
        record?.status !== OfferStatus.CANCELED
          ? [
              btnsHeader[Number(currentTab)]
                ? [...btnsHeader[Number(currentTab)]]
                : [],
              <Button
                onClick={() => {
                  setOpenModalStatuses(true);
                }}
              >
                Установить статус
              </Button>,
            ]
          : []
      }
    >
      <Alert
        closable
        message={
          <div>
            Комментарий :{' '}
            {record && record.comment ? record.comment : 'Отсутствует'}
          </div>
        }
        type="info"
        showIcon
        style={{ fontSize: 14, marginBottom: 12 }}
      />
      {record?.dateFactIssue && (
        <>
          {record.status === OfferStatus.CANCELED ? (
            <Alert
              message={
                <div>
                  Займ закрыт :{' '}
                  {moment(record.dateFactClose).format('DD.MM.YYYY')}
                </div>
              }
              type="success"
              showIcon
              style={{ fontSize: 14, marginBottom: 12 }}
            />
          ) : (
            <Alert
              message={<div>Дата закрытия займа : {dateOfCancel(record)}</div>}
              type="success"
              showIcon
              style={{ fontSize: 14, marginBottom: 12 }}
            />
          )}
        </>
      )}
      {record && (
        <ModalStatuses
          open={openModalStatuses}
          cancel={() => {
            setOpenModalStatuses(false);
          }}
          dateFactIssue={record.dateFactIssue}
          status={record.status}
          refetch={refetchOffer}
          uuid={record.uuid}
        />
      )}
      <Tabs
        defaultActiveKey={currentTab}
        onChange={(tab) => {
          setCurrentTab(tab);
        }}
        items={tabs.map((tab) => {
          return {
            key: tab.key,
            label: tab.label,
            children: tab.children,
            icon: tab.icon,
          };
        })}
      />
    </Show>
  );
};
