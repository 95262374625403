import {
  Alert,
  Badge,
  Button,
  Calendar,
  Card,
  Col,
  Collapse,
  CollapseProps,
  Empty,
  Form,
  Row,
  Select,
  Space,
  BadgeProps,
  Typography,
  NumberField,
  Table,
  Spin,
  theme,
} from '@pankod/refine-antd';
import { CaretRightOutlined } from '@ant-design/icons';
import type { Dayjs } from 'dayjs';
import type { CalendarProps } from 'antd';
import { CanAccess, useNotification } from '@pankod/refine-core';
import { axiosInstance } from 'App';
import { ProlongStatus } from 'enums/prolong.type';
import { IAgreement, IOffer } from 'interfaces';
import moment from 'moment';
import React, { CSSProperties, useState } from 'react';
import dateOfCancel from 'utility/cancelOffer';

import dayjs from 'dayjs';
import { mathRound } from 'utility/mathRound';
const { Text } = Typography;

const getListData = (value: Dayjs, offer: IOffer) => {
  const endDate = dayjs(value);
  const startDate = dayjs(
    moment(dateOfCancel(offer), 'DD.MM.YYYY').format('YYYY-MM-DD'),
  );
  const daysDiff = endDate.diff(startDate, 'days');
  const yearNow = moment().year();
  const daysInYear = moment(yearNow, 'YYYY').isLeapYear() ? 366 : 365;
  return [
    {
      content: (
        <Space direction="vertical">
          <Text>Долг :</Text>
          <Text>
            {calculateToDay(
              Number(offer.purchaseSumm),
              72 / daysInYear,
              0.5,
              daysDiff,
            )}
          </Text>
        </Space>
      ),
    },
  ];
};

function calculateProgression(
  initialNumber: number,
  interestRate: number,
  additionalRate: number,
  numberOfDays: number,
) {
  const result: any[] = [];
  let lastNumber = initialNumber;
  let currentNumber = initialNumber;
  if (numberOfDays > 0) {
    for (let i = 0; i < numberOfDays; i++) {
      const interest = (initialNumber * interestRate) / 100;
      const additionalInterest =
        i === 0
          ? (currentNumber * additionalRate) / 100
          : (result[i - 1].current * additionalRate) / 100;

      lastNumber += interest;
      currentNumber += interest + additionalInterest;

      result.push({
        current: mathRound(lastNumber, true),
        currentTotal: mathRound(currentNumber),
        interest: mathRound(interest),
        additionalInterest: mathRound(additionalInterest),
        days: i + 1,
      });
    }
  } else {
    result.push({
      initialNumber: mathRound(currentNumber),
      current: mathRound(currentNumber),
      currentTotal: mathRound(currentNumber),
      interest: 0,
      additionalInterest: 0,
      days: 0,
    });
  }
  return result;
}

function calculateToDay(
  initialNumber: number,
  interestRate: number,
  additionalRate: number,
  numberOfDays: number,
) {
  const result: any[] = [];
  let lastNumber = initialNumber;
  let currentNumber = initialNumber;
  if (numberOfDays > 0) {
    for (let i = 0; i < numberOfDays; i++) {
      const interest = (initialNumber * interestRate) / 100;
      const additionalInterest =
        i === 0
          ? (currentNumber * additionalRate) / 100
          : (result[i - 1].current * additionalRate) / 100;

      lastNumber += interest;
      currentNumber += interest + additionalInterest;

      result.push({
        current: mathRound(lastNumber, true),
        currentTotal: mathRound(currentNumber),
        interest: mathRound(interest),
        additionalInterest: mathRound(additionalInterest),
        days: i + 1,
      });
    }
  } else {
    result.push({
      initialNumber: mathRound(currentNumber),
      current: mathRound(currentNumber),
      currentTotal: mathRound(currentNumber),
      interest: 0,
      additionalInterest: 0,
      days: 0,
    });
  }
  return Number(result[result.length - 1].currentTotal).toLocaleString(
    'ru-Ru',
    { maximumFractionDigits: 2, minimumFractionDigits: 2 },
  );
}

const columns = [
  {
    title: 'День просрочки',
    dataIndex: 'days',
  },
  {
    title: 'Просроченные проценты',
    dataIndex: 'interest',
    render: (value: any) => (
      <NumberField
        value={mathRound(value)}
        options={{
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }}
      />
    ),
  },
  {
    title: 'Штраф',
    dataIndex: 'additionalInterest',
    render: (value: any) => (
      <NumberField
        value={mathRound(value)}
        options={{
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }}
      />
    ),
  },
  {
    title: 'Остаток долга',
    dataIndex: 'currentTotal',
    render: (value: any) => (
      <NumberField
        value={mathRound(value)}
        options={{
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }}
      />
    ),
  },
];

export default function Delay(props: { record: IOffer; refetch: any }) {
  const { open: notify } = useNotification();
  const [saveBtn, setSaveBtn] = useState<boolean>(false);
  const [loading, setLoadin] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [diff, setDiff] = useState<number>(0);
  const [totalSumm, setTotalSumm] = useState<string>('0');
  const yearNow = moment().year();
  const daysInYear = moment(yearNow, 'YYYY').isLeapYear() ? 366 : 365;
  const data: any[] = calculateProgression(
    parseFloat(props.record.purchaseSumm),
    72 / daysInYear,
    0.5,
    diff,
  );

  const createPetition = async () => {
    setLoadin(true);
    if (notify) {
      try {
        {
          const procents = data.reduce((acc, cur) => acc + cur.interest, 0);
          const peny = data.reduce(
            (acc, cur) => acc + cur.additionalInterest,
            0,
          );
          const response = await axiosInstance.post(
            process.env.REACT_APP_BACKEND_URL +
              '/api/v1/agreements/createPetition',
            {
              offer: props.record,
              totalSumm,
              diff,
              selectedDate,
              procents,
              peny,
            },
          );
          notify({
            description: 'Удачно',
            message: 'Данные сохранены',
            type: 'success',
            key: '2',
            undoableTimeout: 20000,
          });
          setLoadin(false);
          props.refetch();
        }
      } catch (error) {
        console.log(error);
        notify({
          description: 'Ошибка',
          message: 'Возникла проблема , сообщите сис-админу',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
        setTimeout(async () => {
          setLoadin(false);
        }, 2000);
      }
    }
  };

  const handleChange = (value: Dayjs) => {
    const endDate = dayjs(value);
    const startDate = dayjs(
      moment(dateOfCancel(props.record), 'DD.MM.YYYY').format('YYYY-MM-DD'),
    );
    const daysDiff = endDate.diff(startDate, 'days');
    const yearNow = moment().year();
    const daysInYear = moment(yearNow, 'YYYY').isLeapYear() ? 366 : 365;
    setTotalSumm(
      calculateToDay(
        Number(props.record.purchaseSumm),
        72 / daysInYear,
        0.5,
        daysDiff,
      ),
    );

    if (daysDiff >= 1) {
      setSaveBtn(true);
      setSelectedDate(dayjs(value));
      setDiff(daysDiff);
    } else {
      setSaveBtn(false);
      setDiff(0);
    }
    console.log(value.format('YYYY-MM-DD'));
  };
  const disabledDate = (current: any) => {
    if (
      current &&
      current <=
        moment(
          moment(dateOfCancel(props.record), 'DD.MM.YYYY')
            .add(1, 'days')
            .format('YYYY-MM-DD'),
        ).startOf('day')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value, props.record);
    if (
      value &&
      value <=
        moment(
          moment(dateOfCancel(props.record), 'DD.MM.YYYY')
            .add(1, 'days')
            .format('YYYY-MM-DD'),
        ).startOf('day')
    ) {
      return;
    }
    return (
      <ul style={{ listStyle: 'none' }}>
        {listData.map((item: any) => (
          <li key={item.content}>
            <Badge status="processing" text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    return info.originNode;
  };

  const { token } = theme.useToken();
  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  const tabs = [
    {
      key: '1',
      label: 'Календарь',
      children: (
        <Calendar
          disabledDate={disabledDate}
          cellRender={cellRender}
          onChange={handleChange}
        />
      ),
      style: panelStyle,
    },
    {
      key: '2',
      label: 'Описание',
      children: (
        <Table
          columns={columns}
          dataSource={data}
          size="small"
          pagination={false}
        />
      ),
      style: panelStyle,
    },
  ];

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (
    panelStyle,
  ) => tabs;

  return (
    <Spin spinning={loading}>
      <CanAccess resource="offers" action="review">
        <Space size={16} direction="vertical" style={{ width: '100%' }}>
          {props.record.petition && props.record.petition?.length > 0 && (
            <Card size="small" title="Документы">
              <Row gutter={[12, 12]}>
                {props.record.petition.map((card: IAgreement) => {
                  return (
                    <Col>
                      <Card
                        hoverable
                        title={card.type}
                        size="small"
                        bodyStyle={{ padding: 0 }}
                        style={{ maxWidth: '280px' }}
                        onClick={() => {
                          window.open(
                            process.env.REACT_APP_BACKEND_URL + card.pdf,
                            '_blank',
                          );
                        }}
                      >
                        <iframe
                          src={process.env.REACT_APP_BACKEND_URL + card.pdf}
                          width="100%"
                          style={{ border: 'none', height: '320px' }}
                          title="PDF Viewer"
                        />
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Card>
          )}
          <Card title="Календарь просрочки" size="small" hoverable>
            <Space size={16} direction="vertical" style={{ width: '100%' }}>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                style={{ background: token.colorBgContainer }}
                items={getItems(panelStyle)}
              />
              {saveBtn && (
                <Card size="small">
                  <Space
                    size={16}
                    direction="vertical"
                    style={{ width: '100%' }}
                  >
                    <Button onClick={createPetition} type="primary">
                      Сформировать претензию
                    </Button>
                  </Space>
                </Card>
              )}
            </Space>
          </Card>
        </Space>
      </CanAccess>
    </Spin>
  );
}
