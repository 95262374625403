import {
  Alert,
  AutoComplete,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputProps,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  Upload,
} from '@pankod/refine-antd';

import { useNotification } from '@pankod/refine-core';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { axiosInstance } from 'App';
import axios from 'axios';
import { AgentOfferType } from 'enums/agent.offer.type';
import { LoanType } from 'enums/loan.type';
import { OfferType } from 'enums/offer.type';
import { UserType } from 'enums/user.type';
import { IAgent, IOffer, IUser } from 'interfaces';
import moment from 'moment';
import { InputRef } from 'rc-input';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { ArrowDownOutlined } from '@ant-design/icons';
import dadataAddress from 'utility/dadata/address';
import { dateStandartFormat } from 'utility/dateStandartFormat';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const { Text } = Typography;
const { TextArea } = Input;
const formatDate = 'DD.MM.YYYY';

export const Step3 = (props: { uuid: number | undefined }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const { open: notify } = useNotification();
  function setPassNumber(passNumber: string) {
    return `серия: ${passNumber.split(' ')[0]}, № ${passNumber.split(' ')[1]}`;
  }
  const [founders, setFounders] = useState<any[]>([]);

  const navigate = useNavigate();
  const onSave = async (value: any) => {
    if (notify) {
      setLoading(true);
      try {
        const response = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/offers/updateStep3',
          {
            ...value,
            uuid: props.uuid,
            guarantorJurFounders: founders,
          },
        );
        notify({
          description: 'Удачно',
          message: 'Данные обновлены',
          type: 'success',
          key: '2',
          undoableTimeout: 20000,
        });
        navigate('/offers');
        await setLoading(false);
      } catch (e) {
        notify({
          description: 'Ошибка',
          message:
            'Возникла проблема , мы уже знаем о ней и устраним в кратчайшие сроки',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
        setTimeout(async () => {
          setLoading(false);
        }, 2000);
      }
    }
  };

  const [isTwoGuarantators, setIsTwoGuarantators] = useState<boolean>(false);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );
  const [hideIp, setHideIp] = useState<boolean>(true);
  const handleSearch = async (value: string) => {
    let res: { value: string; label: string }[] = [];
    const dadataSugs = await dadataAddress(value);
    res = dadataSugs.map((s: any) => {
      return {
        value: s.unrestricted_value,
        label: s.unrestricted_value,
        disabled: !s.data.house,
      };
    });
    setOptions(res);
  };
  const handleUpload = async (file: File | null | Blob, type: string) => {
    if (notify) {
      const formData = new FormData();
      setLoading(true);
      if (file instanceof File) {
        formData.append('image', file, file.name);
      } else if (file) {
        formData.append('image', file);
      }

      // formData.append('token', '3330064408517a29ec114c14a2be4080');
      formData.append('token', '9447574a3527cd997e51e79080de31fc');

      try {
        const response = await axios.post(
          'https://api.ocr.ads-soft.ru/recognition/',

          formData,
        );
        const data = response.data.data[0].data.results;
        for (const passportData of data) {
          if (type === 'guarantorFiz') {
            switch (passportData.label) {
              case 'birth_date':
                form.setFieldsValue({
                  guarantorFizDateOfBirth: dayjs(passportData.text),
                });
                break;
              case 'birth_place':
                form.setFieldsValue({
                  guarantorFizPlaceOfBirth: passportData.text,
                });
                break;
              case 'serial_1':
                form.setFieldsValue({
                  guarantorFizPassportNumber: setPassNumber(passportData.text),
                });
                break;
              case 'issued_date':
                form.setFieldsValue({
                  guarantorFizPassportIssue: dayjs(passportData.text),
                });
                break;
              case 'issued':
                form.setFieldsValue({
                  guarantorFizPassportIssueBy: passportData.text,
                });
                break;
              case 'issued_number':
                form.setFieldsValue({
                  guarantorFizPassportIssueCode: passportData.text,
                });
                break;
              case 'name':
                form.setFieldsValue({
                  guarantorFizFirstName: passportData.text,
                });

                break;
              case 'middlename':
                form.setFieldsValue({
                  guarantorFizMiddleName: passportData.text,
                });

                break;
              case 'lastname':
                form.setFieldsValue({
                  guarantorFizLastName: passportData.text,
                });
                break;
              case 'sex':
                form.setFieldsValue({
                  guarantorFizGender:
                    passportData.text === 'МУЖ' ? 'Мужской' : 'Женский',
                });
                break;

              default:
                break;
            }
          } else {
            switch (passportData.label) {
              case 'birth_date':
                form.setFieldsValue({
                  guarantorJurDateOfBirth: dayjs(
                    passportData.text,
                    'YYYY-MM-DD',
                  ),
                });
                break;
              case 'birth_place':
                form.setFieldsValue({
                  guarantorJurPlaceOfBirth: passportData.text,
                });
                break;
              case 'serial_1':
                form.setFieldsValue({
                  guarantorJurPassportNumber: setPassNumber(passportData.text),
                });
                break;
              case 'issued_date':
                form.setFieldsValue({
                  guarantorJurPassportIssue: dayjs(
                    passportData.text,
                    'YYYY-MM-DD',
                  ),
                });
                break;
              case 'issued':
                form.setFieldsValue({
                  guarantorJurPassportIssueBy: passportData.text,
                });
                break;
              case 'issued_number':
                form.setFieldsValue({
                  guarantorJurPassportIssueCode: passportData.text,
                });
                break;
              case 'name':
                form.setFieldsValue({
                  guarantorJurFirstName: passportData.text,
                });

                break;
              case 'middlename':
                form.setFieldsValue({
                  guarantorJurMiddleName: passportData.text,
                });

                break;
              case 'lastname':
                form.setFieldsValue({
                  guarantorJurLastName: passportData.text,
                });
                break;
              case 'sex':
                form.setFieldsValue({
                  guarantorJurGender:
                    passportData.text === 'МУЖ' ? 'Мужской' : 'Женский',
                });
                break;

              default:
                break;
            }
          }
        }
        notify({
          description: 'Успех',
          message: 'Паспорт распознан , данные вставлены в необходимые поля',
          type: 'success',
          key: '2',
          undoableTimeout: 20000,
        });
      } catch (error) {
        notify({
          description: 'Ошибка',
          message: 'Не удалось распознать паспорт',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
        console.log(error);
      }
      setLoading(false);
    }
  };

  const getDadataInn = async (event: any) => {
    const { value } = event.target;
    function findedFounders(data: any) {
      return data.map((obj: any) => {
        return {
          fio: obj.fio.source,
          value: obj.share.value,
        };
      });
    }
    if (value.length === 10) {
      axiosInstance
        .get(process.env.REACT_APP_BACKEND_URL + '/api/v1/dadata/' + value)
        .then((res) => {
          setHideIp(true);
          form.setFieldsValue({
            guarantorJurType: UserType.UL,
            guarantorJurNameOrg:
              res.data.suggestions[0].data.name.short_with_opf,
            guarantorJurFullNameOrg:
              res.data.suggestions[0].data.name.full_with_opf,
            guarantorJurLicenceOrg: '',
            guarantorJurOop: res.data.suggestions[0].data.opf.full,
            guarantorJurDirectorPos:
              res.data.suggestions[0].data.managers[0].post,
            guarantorJurManager:
              res.data.suggestions[0].data.managers[0].fio.source,
            guarantorJurOgrn: res.data.suggestions[0].data.ogrn,
            guarantorJurKppOrg: res.data.suggestions[0].data.kpp,
            guarantorJurRegOrg: dateStandartFormat(
              res.data.suggestions[0].data.state.registration_date,
            ),

            guarantorJurAddressOrg: res.data.suggestions[0].data.address.value,
            guarantorJurKapitalOrg: res.data.suggestions[0].data.capital.value,
            guarantorJurEmail: res.data.suggestions[0].data.emails
              ? res.data.suggestions[0].data.emails[0].value
              : '',
            guarantorJurPhone: res.data.suggestions[0].data.phones
              ? res.data.suggestions[0].data.phones[0].value
              : '',
          });

          form.setFieldsValue({
            guarantorJurFirstName:
              res.data.suggestions[0].data.managers[0].fio.name,
            guarantorJurLastName:
              res.data.suggestions[0].data.managers[0].fio.surname,
            guarantorJurMiddleName:
              res.data.suggestions[0].data.managers[0].fio.patronymic,
          });

          setFounders(findedFounders(res.data.suggestions[0].data.founders));
          // setCantEdit(true);
        });
    } else if (value.length === 12) {
      axiosInstance
        .get(process.env.REACT_APP_BACKEND_URL + '/api/v1/dadata/' + value)
        .then((res) => {
          setHideIp(false);
          form.setFieldsValue({
            guarantorJurType: UserType.IP,
            guarantorJurNameOrg:
              res.data.suggestions[0].data.name.short_with_opf,
            guarantorJurFullNameOrg:
              res.data.suggestions[0].data.name.full_with_opf,
            guarantorJurLicenceOrg: '',
            guarantorJurOop: res.data.suggestions[0].data.opf.full,
            // guarantorJurDirectorPos:
            //   res.data.suggestions[0].data.managers[0].post,
            // guarantorJurManager:
            //   res.data.suggestions[0].data.managers[0].fio.source,
            guarantorJurOgrn: res.data.suggestions[0].data.ogrn,
            guarantorJurRegOrg: dateStandartFormat(
              res.data.suggestions[0].data.state.registration_date,
            ),

            guarantorJurAddressOrg: res.data.suggestions[0].data.address.value,
            // guarantorJurKapitalOrg: res.data.suggestions[0].data.capital.value,
            guarantorJurEmail: res.data.suggestions[0].data.emails
              ? res.data.suggestions[0].data.emails[0].value
              : '',
            guarantorJurPhone: res.data.suggestions[0].data.phones
              ? res.data.suggestions[0].data.phones[0].value
              : '',
          });

          // form.setFieldsValue({
          //   guarantorJurFirstName:
          //     res.data.suggestions[0].data.managers[0].fio.name,
          //   guarantorJurLastName:
          //     res.data.suggestions[0].data.managers[0].fio.surname,
          //   guarantorJurMiddleName:
          //     res.data.suggestions[0].data.managers[0].fio.patronymic,
          // });
          // setCantEdit(true);
        });
    }
  };

  const dadataBank = (event: any) => {
    if (event.target.value.length >= 9) {
      axiosInstance
        .get(
          process.env.REACT_APP_BACKEND_URL +
            '/api/v1/dadata/bank/' +
            event.target.value,
        )
        .then((res) => {
          form.setFieldsValue({
            guarantorJurIssueBank: res.data.suggestions[0].data.name.short
              ? res.data.suggestions[0].data.name.short
              : res.data.suggestions[0].data.name.payment,
            guarantorJurIssueCorNumber:
              res.data.suggestions[0].data.correspondent_account,
          });
        });
    }
  };

  const [offer, setOffer] = useState<IOffer>();
  useEffect(() => {
    if (props.uuid) {
      (async () => {
        if (notify) {
          setLoading(true);
          try {
            const response = await axiosInstance.get(
              process.env.REACT_APP_BACKEND_URL +
                '/api/v1/offers/' +
                props.uuid,
            );
            const data = await response.data;
            setOffer(data);
            form.setFieldsValue({
              guarantorFizLastName: data.guarantorFizLastName,
              guarantorFizMiddleName: data.guarantorFizMiddleName,
              guarantorFizFirstName: data.guarantorFizFirstName,
              guarantorFizDateOfBirth: data.guarantorFizDateOfBirth
                ? dayjs(data.guarantorFizDateOfBirth)
                : null,
              guarantorFizPlaceOfBirth: data.guarantorFizPlaceOfBirth,
              guarantorFizPassportNumber: data.guarantorFizPassportNumber,
              guarantorFizPassportIssue: data.guarantorFizPassportIssue
                ? dayjs(data.guarantorFizPassportIssue)
                : null,
              guarantorFizСitizenship: data.guarantorFizСitizenship
                ? data.guarantorFizСitizenship
                : 'РФ',
              guarantorFizPassportIssueCode: data.guarantorFizPassportIssueCode,
              guarantorFizPassportIssueBy: data.guarantorFizPassportIssueBy,
              guarantorFizAddressRegistration:
                data.guarantorFizAddressRegistration,
              guarantorFizAddressFact: data.guarantorFizAddressFact,
            });

            if (data.guarantorJurInnOrg) {
              setIsTwoGuarantators(true);
              if (data.guarantorJurInnOrg.length === 10) {
                setHideIp(true);
                setFounders(JSON.parse(data.guarantorJurFounders));
                form.setFieldsValue({
                  guarantorJurGender: data.guarantorJurGender,
                  guarantorJurType: data.guarantorJurType,
                  guarantorJurInnOrg: data.guarantorJurInnOrg,
                  guarantorJurNameOrg: data.guarantorJurNameOrg,
                  guarantorJurFullNameOrg: data.guarantorJurFullNameOrg,
                  guarantorJurLicenceOrg: data.guarantorJurLicenceOrg,
                  guarantorJurOop: data.guarantorJurOop,
                  guarantorJurOgrn: data.guarantorJurOgrn,
                  guarantorJurKppOrg: data.guarantorJurKppOrg,
                  guarantorJurRegOrg: dayjs(
                    data.guarantorJurRegOrg,
                    'YYYY-MM-DD',
                  ),
                  guarantorJurAddressOrg: data.guarantorJurAddressOrg,
                  guarantorJurFullAddressOrg: data.guarantorJurAddressFact,
                  guarantorJurKapitalOrg: data.guarantorJurKapitalOrg,
                  guarantorJurDirectorPos: data.guarantorJurDirectorPos,
                  guarantorJurManager: data.guarantorJurManager,
                  guarantorJurIssueBik: data.guarantorJurIssueBik,
                  guarantorJurIssueAccNumber: data.guarantorJurIssueAccNumber,
                  guarantorJurIssueBank: data.guarantorJurIssueBank,
                  guarantorJurIssueCorNumber: data.guarantorJurIssueCorNumber,
                  guarantorJurEmail: data.guarantorJurEmail,
                  guarantorJurPhone: data.guarantorJurIssueCorNumber,
                  guarantorJurLastName: data.guarantorJurLastName,
                  guarantorJurFirstName: data.guarantorJurFirstName,
                  guarantorJurMiddleName: data.guarantorJurMiddleName,
                  guarantorJurDateOfBirth: dayjs(
                    data.guarantorJurDateOfBirth,
                    'YYYY-MM-DD',
                  ),
                  guarantorJurPlaceOfBirth: data.guarantorJurPlaceOfBirth,
                  guarantorJurPassportNumber: data.guarantorJurPassportNumber,
                  guarantorJurPassportIssue: dayjs(
                    data.guarantorJurPassportIssue,
                    'YYYY-MM-DD',
                  ),
                  guarantorJurСitizenship: data.guarantorJurСitizenship,
                  guarantorJurPassportIssueCode:
                    data.guarantorJurPassportIssueCode,
                  guarantorJurPassportIssueBy: data.guarantorJurPassportIssueBy,
                  guarantorJurAddressRegistration:
                    data.guarantorJurAddressRegistration,
                  guarantorJurAddressFact: data.guarantorJurAddressRegistration,
                });
              } else {
                setHideIp(false);
                setFounders([]);
                form.setFieldsValue({
                  guarantorJurGender: data.guarantorJurGender,
                  guarantorJurType: data.guarantorJurType,
                  guarantorJurInnOrg: data.guarantorJurInnOrg,
                  guarantorJurNameOrg: data.guarantorJurNameOrg,
                  guarantorJurFullNameOrg: data.guarantorJurFullNameOrg,
                  guarantorJurLicenceOrg: data.guarantorJurLicenceOrg,
                  guarantorJurOop: data.guarantorJurOop,
                  guarantorJurOgrn: data.guarantorJurOgrn,
                  guarantorJurRegOrg: dayjs(
                    data.guarantorJurRegOrg,
                    'YYYY-MM-DD',
                  ),
                  guarantorJurAddressOrg: data.guarantorJurAddressOrg,
                  guarantorJurFullAddressOrg: data.guarantorJurAddressFact,
                  guarantorJurIssueBik: data.guarantorJurIssueBik,
                  guarantorJurIssueAccNumber: data.guarantorJurIssueAccNumber,
                  guarantorJurIssueBank: data.guarantorJurIssueBank,
                  guarantorJurIssueCorNumber: data.guarantorJurIssueCorNumber,
                  guarantorJurEmail: data.guarantorJurEmail,
                  guarantorJurPhone: data.guarantorJurIssueCorNumber,
                  guarantorJurLastName: data.guarantorJurLastName,
                  guarantorJurFirstName: data.guarantorJurFirstName,
                  guarantorJurMiddleName: data.guarantorJurMiddleName,
                  guarantorJurDateOfBirth: dayjs(
                    data.guarantorJurDateOfBirth,
                    'YYYY-MM-DD',
                  ),
                  guarantorJurPlaceOfBirth: data.guarantorJurPlaceOfBirth,
                  guarantorJurPassportNumber: data.guarantorJurPassportNumber,
                  guarantorJurPassportIssue: moment(
                    data.guarantorJurPassportIssue,
                    'YYYY-MM-DD',
                  ).format('DD.MM.YYYY'),
                  guarantorJurСitizenship: data.guarantorJurСitizenship,
                  guarantorJurPassportIssueCode:
                    data.guarantorJurPassportIssueCode,
                  guarantorJurPassportIssueBy: data.guarantorJurPassportIssueBy,
                  guarantorJurAddressRegistration:
                    data.guarantorJurAddressRegistration,
                  guarantorJurAddressFact: data.guarantorJurAddressRegistration,
                });
              }
            }
            await setLoading(false);
          } catch (e) {
            notify({
              description: 'Ошибка',
              message: 'Возникла проблема , сообщите сис-админу',
              type: 'error',
              key: '2',
              undoableTimeout: 20000,
            });
            setTimeout(async () => {
              setLoading(false);
            }, 2000);
          }
        }
      })();
    }
  }, [props.uuid]);

  const copy = () => {
    console.log(offer);
    if (offer) {
      form.setFieldsValue({
        guarantorFizLastName: offer.lenderLastName,
        guarantorFizMiddleName: offer.lenderMiddleName,
        guarantorFizFirstName: offer.lenderFirstName,
        guarantorFizDateOfBirth: dayjs(offer.lenderDateOfBirth),
        guarantorFizPlaceOfBirth: offer.lenderPlaceOfBirth,
        guarantorFizPassportNumber: offer.lenderPassportNumber,
        guarantorFizPassportIssue: dayjs(offer.lenderPassportIssue),
        guarantorFizСitizenship: offer.lenderСitizenship,
        guarantorFizPassportIssueCode: offer.lenderPassportIssueCode,
        guarantorFizPassportIssueBy: offer.lenderPassportIssueBy,
        guarantorFizAddressRegistration: offer.lenderAddressRegistration,
        guarantorFizAddressFact: offer.lenderAddressFact,
      });
    }
  };
  return (
    <div style={{ padding: '16px 0px' }}>
      <Spin tip="Обновление данных" spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onSave}>
          <Card
            style={{ marginTop: '16px' }}
            size="small"
            hoverable
            title={<Text type="secondary">Поручитель Физ. лицо</Text>}
          >
            <Alert
              style={{ marginBottom: 12 }}
              message="Заполнение информации"
              description={
                <Space direction="vertical">
                  <Text>
                    Вы можете воспользоваться сервисом распознования изображений
                  </Text>
                  <Upload
                    beforeUpload={(file) => {
                      handleUpload(file, 'guarantorFiz');
                      return false;
                    }}
                  >
                    <Button type="dashed">Загрузить фото паспорта</Button>
                  </Upload>
                </Space>
              }
              type="info"
              showIcon
            />

            <Button
              style={{ marginBottom: '16px' }}
              onClick={copy}
              type="primary"
            >
              Копирование данных в Поручителя из данных руководителя
            </Button>
            <Row gutter={[12, 12]}>
              <Col xs={6}>
                <Form.Item
                  hasFeedback
                  label="Фамилия"
                  name="guarantorFizLastName"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item
                  hasFeedback
                  label="Имя"
                  name="guarantorFizFirstName"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item
                  hasFeedback
                  label="Отчество"
                  name="guarantorFizMiddleName"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item
                  hasFeedback
                  label="Дата рождения"
                  name="guarantorFizDateOfBirth"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      validator: (_, value) => {
                        const date = moment(value, 'DD-MM-YYYY').toDate();
                        date.setFullYear(date.getFullYear() + 18);
                        const today = new Date();
                        if (date > today) {
                          return Promise.reject(
                            new Error('Регистрация возможна с 18 лет'),
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <DatePicker format={formatDate} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              hasFeedback
              label="Место рождения"
              name="guarantorFizPlaceOfBirth"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Row gutter={[12, 12]}>
              <Col xs={6}>
                <Form.Item
                  hasFeedback
                  label="Серия и № паспорта"
                  name="guarantorFizPassportNumber"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputMask
                    placeholder={''}
                    mask="серия: 9999, № 999999"
                    // maskChar=' '
                  >
                    {(
                      inputProps: JSX.IntrinsicAttributes &
                        InputProps &
                        React.RefAttributes<InputRef>,
                    ) => <Input allowClear {...inputProps} />}
                  </InputMask>
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item
                  hasFeedback
                  label="Дата выдачи"
                  name="guarantorFizPassportIssue"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker format={formatDate} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item
                  hasFeedback
                  label="Гражданство"
                  name="guarantorFizСitizenship"
                  initialValue={'РФ'}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item
                  hasFeedback
                  label="Код подразделения"
                  name="guarantorFizPassportIssueCode"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              hasFeedback
              label="Кем выдан"
              name="guarantorFizPassportIssueBy"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              hasFeedback
              label="Адрес регистрации (город, улица, дом)"
              name="guarantorFizAddressRegistration"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <AutoComplete
                onSearch={handleSearch}
                placeholder=""
                options={options}
              />
            </Form.Item>
            <Form.Item label="Фактический адрес">
              <div style={{ display: 'flex' }}>
                <Button
                  size="middle"
                  style={{ marginRight: 10 }}
                  icon={<ArrowDownOutlined />}
                  onClick={() => {
                    form.setFieldsValue({
                      guarantorFizAddressFact: form.getFieldValue(
                        'guarantorFizAddressRegistration',
                      ),
                    });
                  }}
                >
                  Совпадает
                </Button>
                <Form.Item
                  style={{ display: 'inline-block', width: '100%' }}
                  name="guarantorFizAddressFact"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message:
                        'Пожалуйста, введите фактический адрес регистрации',
                    },
                  ]}
                >
                  <AutoComplete
                    size={'middle'}
                    onSearch={handleSearch}
                    placeholder=""
                    options={options}
                  />
                </Form.Item>
              </div>
            </Form.Item>
          </Card>
          {isTwoGuarantators && (
            <Card
              style={{ marginTop: '16px' }}
              size="small"
              hoverable
              title={<Text type="secondary">Поручитель Юр. лицо</Text>}
            >
              <Card
                style={{ marginTop: '16px' }}
                size="small"
                hoverable
                title={
                  <Text type="secondary">Заполнение информации о компании</Text>
                }
              >
                <Row gutter={[12, 12]}>
                  <Col xs={8}>
                    <Form.Item
                      hasFeedback
                      label="Пол"
                      name="guarantorJurGender"
                      hidden
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      hasFeedback
                      label="Тип"
                      name="guarantorJurType"
                      hidden
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      hasFeedback
                      label="ИНН организации"
                      name="guarantorJurInnOrg"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input onChange={getDadataInn} allowClear />
                    </Form.Item>
                  </Col>
                  <Col xs={8}>
                    <Form.Item
                      hasFeedback
                      label="Название организации"
                      name="guarantorJurNameOrg"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={8}>
                    <Form.Item
                      hasFeedback
                      label="Полное название организации"
                      name="guarantorJurFullNameOrg"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={4}>
                    <Form.Item
                      hasFeedback
                      label="Наличие лицензии"
                      name="guarantorJurLicenceOrg"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={8}>
                    <Form.Item
                      hasFeedback
                      label="Организационно-правовая ответственность"
                      name="guarantorJurOop"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[12, 12]}>
                  <Col xs={6}>
                    <Form.Item
                      hasFeedback
                      label="ОГРН"
                      name="guarantorJurOgrn"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  {hideIp && (
                    <Col xs={6}>
                      <Form.Item
                        hasFeedback
                        label="КПП"
                        name="guarantorJurKppOrg"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={6}>
                    <Form.Item
                      hasFeedback
                      label="Дата регистрации"
                      name="guarantorJurRegOrg"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputMask placeholder={''} mask="99.99.9999">
                        {(
                          inputProps: JSX.IntrinsicAttributes &
                            InputProps &
                            React.RefAttributes<InputRef>,
                        ) => <Input allowClear {...inputProps} />}
                      </InputMask>
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      hasFeedback
                      label="Юридический адрес"
                      name="guarantorJurAddressOrg"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item label="Фактический адрес">
                      <div style={{ display: 'flex' }}>
                        <Button
                          size="middle"
                          style={{ marginRight: 10 }}
                          icon={<ArrowDownOutlined />}
                          onClick={() => {
                            form.setFieldsValue({
                              guarantorJurFullAddressOrg: form.getFieldValue(
                                'guarantorJurAddressOrg',
                              ),
                            });
                          }}
                        >
                          Совпадает
                        </Button>
                        <Form.Item
                          style={{ display: 'inline-block', width: '100%' }}
                          name="guarantorJurFullAddressOrg"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <AutoComplete
                            size={'middle'}
                            onSearch={handleSearch}
                            placeholder=""
                            options={options}
                          />
                        </Form.Item>
                      </div>
                    </Form.Item>
                  </Col>
                  {hideIp && (
                    <>
                      <Col xs={4}>
                        <Form.Item
                          hasFeedback
                          label="Уставной капитал"
                          name="guarantorJurKapitalOrg"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={8}>
                        <Form.Item
                          hasFeedback
                          label="Должность руководителя"
                          name="guarantorJurDirectorPos"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col xs={8}>
                        <Form.Item
                          hasFeedback
                          label="Руководитель"
                          name="guarantorJurManager"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </Card>
              {hideIp && (
                <Card
                  style={{ marginTop: '16px' }}
                  size="small"
                  hoverable
                  title={
                    <Text type="secondary">
                      Участники (собственники) организации Заемщика
                    </Text>
                  }
                >
                  {founders?.map((founder, id) => {
                    return (
                      <div key={id}>
                        <Divider orientation="left" style={{ margin: 0 }}>
                          <Text type="secondary">Участники {+id + 1}</Text>
                        </Divider>
                        <Row gutter={[12, 12]}>
                          <Col xs={24} md={12}>
                            <Form.Item
                              hasFeedback
                              label="ФИО"
                              rules={[
                                {
                                  required: false,
                                },
                              ]}
                            >
                              <Input readOnly value={founder.fio} />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item
                              hasFeedback
                              label="Процент в компании"
                              rules={[
                                {
                                  required: false,
                                },
                              ]}
                            >
                              <Input readOnly value={founder.value} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </Card>
              )}
              <Card
                style={{ marginTop: '16px' }}
                size="small"
                hoverable
                title={<Text type="secondary">Банковские данные</Text>}
              >
                <Row gutter={[12, 12]}>
                  <Col xs={12}>
                    <Form.Item
                      hasFeedback
                      label="БИК"
                      name="guarantorJurIssueBik"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input onInput={dadataBank} />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      hasFeedback
                      label="Расчетный счет"
                      name="guarantorJurIssueAccNumber"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      hasFeedback
                      label="Наименование банка"
                      name="guarantorJurIssueBank"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      hasFeedback
                      label="Корр. счет"
                      name="guarantorJurIssueCorNumber"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Card
                style={{ marginTop: '16px' }}
                size="small"
                hoverable
                title={<Text type="secondary">Контакты</Text>}
              >
                <Row gutter={[12, 12]}>
                  <Col xs={12}>
                    <Form.Item
                      hasFeedback
                      label="Email"
                      name="guarantorJurEmail"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>{' '}
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      hasFeedback
                      label="Телефон"
                      name="guarantorJurPhone"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>{' '}
                  </Col>
                </Row>
              </Card>
              <Card
                style={{ marginTop: '16px' }}
                size="small"
                hoverable
                title={
                  <Text type="secondary">
                    Заполнение информации о руководителе
                  </Text>
                }
              >
                <Alert
                  style={{ marginBottom: 12 }}
                  message="Заполнение информации"
                  description={
                    <Space direction="vertical">
                      <Text>
                        Вы можете воспользоваться сервисом распознования
                        изображений
                      </Text>
                      <Upload
                        beforeUpload={(file) => {
                          handleUpload(file, 'guarantorJur');
                          return false;
                        }}
                      >
                        <Button type="dashed">Загрузить фото паспорта</Button>
                      </Upload>
                    </Space>
                  }
                  type="info"
                  showIcon
                />
                <Row gutter={[12, 12]}>
                  <Col xs={6}>
                    <Form.Item
                      hasFeedback
                      label="Фамилия"
                      name="guarantorJurLastName"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={6}>
                    <Form.Item
                      hasFeedback
                      label="Имя"
                      name="guarantorJurFirstName"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={6}>
                    <Form.Item
                      hasFeedback
                      label="Отчество"
                      name="guarantorJurMiddleName"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={6}>
                    <Form.Item
                      hasFeedback
                      label="Дата рождения"
                      name="guarantorJurDateOfBirth"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          validator: (_, value) => {
                            const date = moment(value, 'DD-MM-YYYY').toDate();
                            date.setFullYear(date.getFullYear() + 18);
                            const today = new Date();
                            if (date > today) {
                              return Promise.reject(
                                new Error('Регистрация возможна с 18 лет'),
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                    >
                      <InputMask placeholder={''} mask="99.99.9999">
                        {(
                          inputProps: JSX.IntrinsicAttributes &
                            InputProps &
                            React.RefAttributes<InputRef>,
                        ) => <Input allowClear {...inputProps} />}
                      </InputMask>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  hasFeedback
                  label="Место рождения"
                  name="guarantorJurPlaceOfBirth"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Row gutter={[12, 12]}>
                  <Col xs={6}>
                    <Form.Item
                      hasFeedback
                      label="Серия и № паспорта"
                      name="guarantorJurPassportNumber"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputMask
                        placeholder={''}
                        mask="серия: 9999, № 999999"
                        // maskChar=' '
                      >
                        {(
                          inputProps: JSX.IntrinsicAttributes &
                            InputProps &
                            React.RefAttributes<InputRef>,
                        ) => <Input allowClear {...inputProps} />}
                      </InputMask>
                    </Form.Item>
                  </Col>
                  <Col xs={6}>
                    <Form.Item
                      hasFeedback
                      label="Дата выдачи"
                      name="guarantorJurPassportIssue"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputMask
                        placeholder={''}
                        mask="99.99.9999"
                        // maskChar=' '
                      >
                        {(
                          inputProps: JSX.IntrinsicAttributes &
                            InputProps &
                            React.RefAttributes<InputRef>,
                        ) => <Input allowClear {...inputProps} />}
                      </InputMask>
                    </Form.Item>
                  </Col>
                  <Col xs={6}>
                    <Form.Item
                      hasFeedback
                      label="Гражданство директора"
                      name="guarantorJurСitizenship"
                      initialValue={'РФ'}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={6}>
                    <Form.Item
                      hasFeedback
                      label="Код подразделения"
                      name="guarantorJurPassportIssueCode"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  hasFeedback
                  label="Кем выдан"
                  name="guarantorJurPassportIssueBy"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  hasFeedback
                  label="Адрес регистрации (город, улица, дом)"
                  name="guarantorJurAddressRegistration"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <AutoComplete
                    onSearch={handleSearch}
                    placeholder=""
                    options={options}
                  />
                </Form.Item>
                <Form.Item label="Фактический адрес">
                  <div style={{ display: 'flex' }}>
                    <Button
                      size="middle"
                      style={{ marginRight: 10 }}
                      icon={<ArrowDownOutlined />}
                      onClick={() => {
                        form.setFieldsValue({
                          guarantorJurAddressFact: form.getFieldValue(
                            'guarantorJurAddressRegistration',
                          ),
                        });
                      }}
                    >
                      Совпадает
                    </Button>
                    <Form.Item
                      style={{ display: 'inline-block', width: '100%' }}
                      name="guarantorJurAddressFact"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message:
                            'Пожалуйста, введите фактический адрес регистрации',
                        },
                      ]}
                    >
                      <AutoComplete
                        size={'middle'}
                        onSearch={handleSearch}
                        placeholder=""
                        options={options}
                      />
                    </Form.Item>
                  </div>
                </Form.Item>
              </Card>
            </Card>
          )}
          <div style={{ display: 'flex', width: '100%' }}>
            {!isTwoGuarantators && (
              <Button
                onClick={() => setIsTwoGuarantators(true)}
                style={{ margin: '16px 0' }}
                type="primary"
              >
                Добавить поручителя Юр.лицо
              </Button>
            )}
            <Button
              style={{
                marginLeft: 'auto',
                marginRight: '0px',
                display: 'flex',
                marginTop: '16px',
              }}
              type="primary"
              htmlType="submit"
            >
              Сохранить и завершить
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};
