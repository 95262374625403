import {
  AutoComplete,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  InputProps,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from '@pankod/refine-antd';

import { useNotification } from '@pankod/refine-core';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { axiosInstance } from 'App';
import { AgentOfferType } from 'enums/agent.offer.type';
import { LoanType } from 'enums/loan.type';
import { OfferType } from 'enums/offer.type';
import { UserType } from 'enums/user.type';
import { IAgent, IUser } from 'interfaces';
import moment from 'moment';
import { InputRef } from 'rc-input';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import dadataAddress from 'utility/dadata/address';
import { mathRound } from 'utility/mathRound';
const { Text } = Typography;
const { TextArea } = Input;

export const Step2 = (props: { setStep: any; uuid: number | undefined }) => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );
  const handleSearch = async (value: string) => {
    let res: { value: string; label: string }[] = [];
    const dadataSugs = await dadataAddress(value);
    res = dadataSugs.map((s: any) => {
      return {
        value: s.unrestricted_value,
        label: s.unrestricted_value,
        disabled: !s.data.house,
      };
    });
    setOptions(res);
  };

  const [form] = Form.useForm();
  const [isEdited, setEdited] = useState<boolean>(false);
  const [auction, setAuction] = useState<boolean>(false);
  const [auctionFZ, setAuctionFZ] = useState<boolean>(false);
  const [fixAgent, setFixAgent] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const { open: notify } = useNotification();
  const onSave = async (value: any) => {
    if (notify) {
      setLoading(true);
      try {
        const response = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/offers/updateStep2',
          { ...value, agent: agentSelected, uuid: props.uuid ? props.uuid : 5 },
        );
        setTimeout(async () => {
          notify({
            description: 'Удачно',
            message: 'Данные обновлены',
            type: 'success',
            key: '2',
            undoableTimeout: 20000,
          });
          await props.setStep(2);
          await setLoading(false);
        }, 2000);
      } catch (e) {
        notify({
          description: 'Ошибка',
          message:
            'Возникла проблема , мы уже знаем о ней и устраним в кратчайшие сроки',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
        setTimeout(async () => {
          setLoading(false);
        }, 2000);
      }
    }
  };

  const setAgentFix = (value: any) => {
    if (value === 'Фиксированная ставка агенту') {
      setFixAgent(false);
      form.resetFields([
        'agentProcent',
        'companyProcent',
        'offerProcent',
        'purchaseGuarantee',
      ]);
    } else {
      setFixAgent(true);
      form.resetFields([
        'agentSumm',
        'companySumm',
        'offerProcent',
        'purchaseGuarantee',
      ]);
    }
  };

  const checkSumm = () => {
    if (fixAgent) {
      const sum1 = form.getFieldValue('agentProcent')
        ? +form.getFieldValue('agentProcent')
        : 0;
      const sum2 = form.getFieldValue('companyProcent')
        ? +form.getFieldValue('companyProcent')
        : 0;
      const OfferSumm = form.getFieldValue('purchaseSumm')
        ? +form.getFieldValue('purchaseSumm')
        : 0;
      form.setFieldsValue({
        offerProcent: (+sum1 + +sum2).toFixed(!fixAgent ? 5 : 2),
      });
      form.setFieldsValue({
        purchaseGuarantee: mathRound(
          (+OfferSumm / 100) * (+sum1 + +sum2),
          true,
        ),
      });
    } else {
      const sum1 = form.getFieldValue('agentSumm')
        ? +form.getFieldValue('agentSumm')
        : 0;
      const sum2 = form.getFieldValue('companySumm')
        ? +form.getFieldValue('companySumm')
        : 0;
      const OfferSumm = form.getFieldValue('purchaseSumm')
        ? +form.getFieldValue('purchaseSumm')
        : 0;
      form.setFieldsValue({
        offerProcent: (((+sum1 + +sum2) * 100) / +OfferSumm).toFixed(
          !fixAgent ? 5 : 2,
        ),
      });
      form.setFieldsValue({
        purchaseGuarantee: mathRound(
          (+OfferSumm / 100) * (+sum1 + +sum2),
          true,
        ),
      });
    }
  };

  const [agentList, setAgentList] = useState<IAgent[] | null>(null);
  useEffect(() => {
    (async () => {
      if (notify) {
        setLoading(true);
        try {
          const response = await axiosInstance.get(
            process.env.REACT_APP_BACKEND_URL + '/api/v1/agents',
          );
          setAgentList(response.data.data);
          setLoading(false);
        } catch (e) {
          notify({
            description: 'Ошибка',
            message:
              'Возникла проблема , мы уже знаем о ней и устраним в кратчайшие сроки',
            type: 'error',
            key: '2',
            undoableTimeout: 20000,
          });
          setLoading(false);
        }
      }
    })();
  }, []);
  const [agentSelected, setAgentSelected] = useState<IAgent | undefined>();

  const damia = async (event: any) => {
    if (event.target.value.length >= 9) {
      setLoading(true);
      try {
        const responseAxios = await axiosInstance.get(
          process.env.REACT_APP_BACKEND_URL +
            '/api/v1/dadata/damia/' +
            event.target.value,
        );
        const response = await responseAxios;
        // 204550000012300260
        const purchaseDateFinalOffer = `${
          response.data[event.target.value]['ДатаОконч']
        } ${response.data[event.target.value]['ВремяОконч']}`;

        form.setFieldsValue({
          purchaseInfo:
            response.data[event.target.value]['Продукт']['Название'],
          purchaseDateFinalDocs: moment(
            purchaseDateFinalOffer,
            'YYYY-MM-DD HH:mm',
          ).format('DD.MM.YYYY HH:mm'),
          purchaseDateFinalOffer: moment(
            response.data[event.target.value]['ДатаРассм'],
            'YYYY-MM-DD',
          ).format('DD.MM.YYYY'),
          purchaseDate: response.data[event.target.value]['ДатаАукц']
            ? moment(
                response.data[event.target.value]['ДатаАукц'],
                'YYYY-MM-DD',
              ).format('DD.MM.YYYY')
            : null,
          purchaseOfferMax:
            response.data[event.target.value]['НачЦена']['Сумма'],
          purchaseElectronAuction:
            response.data[event.target.value]['ОбеспУчаст']['Сумма'],
          purchaseOfferProvide:
            response.data[event.target.value]['ОбеспИсп']['Сумма'],
          purchasePortal:
            response.data[event.target.value]['ЭТП']['Наименование'],
          purchaseSumm:
            +response.data[event.target.value]['ОбеспУчаст']['Сумма'],
        });
        checkSumm();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (props.uuid) {
      (async () => {
        if (notify) {
          setLoading(true);
          try {
            const response = await axiosInstance.get(
              process.env.REACT_APP_BACKEND_URL +
                '/api/v1/offers/' +
                props.uuid,
            );
            const data = await response.data;
            if (data.agent) {
              form.setFieldsValue({
                agent: data.agent.uuid,
              });
            } else {
              form.setFieldsValue({
                agent: 0,
              });
            }
            form.setFieldsValue({
              agentProcentType: data.agentProcentType,
              agentProcent: data.agentProcent,
              agentSumm: data.agentSumm,
              companyProcent: data.companyProcent,
              companySumm: data.companySumm,
              offerProcent: data.offerProcent,
              purchaseType: data.purchaseType,
            });
            setAuction(
              (data.purchaseType && data.purchaseType === OfferType.NORMAL) ||
                data.purchaseType === OfferType.FZ
                ? true
                : false,
            );
            setAgentSelected(data.agent ? data.agent : undefined);
            form.setFieldsValue({
              purchaseSumm: data.purchaseSumm,
              purchaseGuarantee: data.purchaseGuarantee,
              offerNumber: data.offerNumber
                ? data.offerNumber
                : moment().format('DDMMYYHHmm'),
            });
            if (
              data.purchaseType === OfferType.NORMAL ||
              data.purchaseType === OfferType.FZ
            ) {
              form.setFieldsValue({
                purchase: data.purchase,
                purchasePortal: data.purchasePortal,
                purchaseInfo: data.purchaseInfo,
                purchaseOfferMax: data.purchaseOfferMax,
                purchaseOfferProvide: data.purchaseOfferProvide,
                purchaseElectronAuction: data.purchaseElectronAuction,
                purchaseDateFinalDocs: new Date(
                  data.purchaseDateFinalDocs,
                ).toLocaleString(),
                purchaseDateFinalOffer: new Date(
                  data.purchaseDateFinalOffer,
                ).toLocaleString(),
                purchaseDate: new Date(data.purchaseDate).toLocaleString(),
              });
            }
            checkSumm();
            await setLoading(false);
          } catch (e) {
            console.log(e);
            notify({
              description: 'Ошибка',
              message: 'Возникла проблема , сообщите сис-админу',
              type: 'error',
              key: '2',
              undoableTimeout: 20000,
            });
            setTimeout(async () => {
              setLoading(false);
            }, 2000);
          }
        }
      })();
    }
  }, [props.uuid]);

  return (
    // <div style={{ padding: '16px 0px' }}>
    <Spin tip="Обновление данных" spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSave}
        onChange={() => setEdited(true)}
      >
        <Card
          style={{ marginTop: '16px' }}
          size="small"
          hoverable
          title={<Text type="secondary">Данные агента</Text>}
        >
          <Row gutter={[12, 12]}>
            <Col xs={24} md={16} xl={16} xxl={4}>
              {agentList && (
                <Form.Item
                  hasFeedback
                  label="Выберите агента"
                  name="agent"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionLabelProp="label" // Отображение label в опциях
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onChange={(value) => {
                      if (value === 0) {
                        form.setFieldsValue({ agentSumm: 0, agentProcent: 0 });
                      }
                      console.log(agentList.find((el) => el.uuid === value));
                      setAgentSelected(
                        agentList.find((el) => el.uuid === value),
                      );
                    }}
                    placeholder=""
                  >
                    <Select.Option value={0} label="Без агента">
                      Без агента
                    </Select.Option>
                    {agentList.map((item) => (
                      <Select.Option
                        key={item.uuid}
                        value={item.uuid}
                        label={item.orgName}
                      >
                        {item.orgName}
                      </Select.Option>
                    ))}
                  </Select>
                  {/* <Select
                    onChange={(value) => {
                      if (value === 0) {
                        form.setFieldsValue({ agentSumm: 0, agentProcent: 0 });
                        console.log(null);
                      }
                      setAgentSelected(
                        agentList.find((el) => el.uuid === value),
                      );
                    }}
                  >
                    <Select.Option value={0}>Без агента</Select.Option>
                    {agentList.map((item) => (
                      <Select.Option key={item.uuid} value={item.uuid}>
                        {item.orgName}
                      </Select.Option>
                    ))}
                  </Select> */}
                </Form.Item>
              )}
            </Col>
            <Col xs={24} md={12} xl={8} xxl={4}>
              <Form.Item
                hasFeedback
                label="Тип расчета ставки агента"
                name="agentProcentType"
                initialValue={AgentOfferType.PROCENT}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={(event) => setAgentFix(event)}
                  options={[
                    {
                      value: AgentOfferType.PROCENT,
                      label: AgentOfferType.PROCENT,
                    },
                    {
                      value: AgentOfferType.FIX,
                      label: AgentOfferType.FIX,
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            {/* </Row>
          <Row gutter={[12, 12]}> */}
            {!fixAgent ? (
              <>
                <Col xs={8} xl={8} xxl={4}>
                  <Form.Item
                    hasFeedback
                    label="Сумма агента"
                    name="agentSumm"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                      }
                      onChange={checkSumm}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8} xl={8} xxl={4}>
                  <Form.Item
                    hasFeedback
                    label="Сумма компании"
                    name="companySumm"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                      }
                      onChange={checkSumm}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8} xl={8} xxl={4}>
                  <Form.Item
                    hasFeedback
                    label="Процент по договору"
                    name="offerProcent"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input style={{ width: '100%' }} disabled />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
                <Col xs={8} xl={8} xxl={4}>
                  <Form.Item
                    hasFeedback
                    label="Процент агента"
                    name="agentProcent"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input type="number" onChange={checkSumm} />
                  </Form.Item>
                </Col>
                <Col xs={8} xl={8} xxl={4}>
                  <Form.Item
                    hasFeedback
                    label="Процент компании"
                    name="companyProcent"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input type="number" onChange={checkSumm} />
                  </Form.Item>
                </Col>
                <Col xs={8} xl={8} xxl={4}>
                  <Form.Item
                    hasFeedback
                    label="Процент по договору"
                    name="offerProcent"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Card>
        <Card
          style={{ marginTop: '16px' }}
          size="small"
          hoverable
          title={<Text type="secondary">Данные заявки</Text>}
        >
          <Row gutter={[12, 12]}>
            <Col xs={3}>
              <Form.Item
                hasFeedback
                label="Тип займа"
                name="purchaseType"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={() => {
                    if (
                      form.getFieldValue('purchaseType') === OfferType.NORMAL
                    ) {
                      setAuction(true);
                      setAuctionFZ(true);
                    } else if (
                      form.getFieldValue('purchaseType') === OfferType.FZ
                    ) {
                      setAuction(true);
                      setAuctionFZ(true);
                    } else {
                      setAuction(false);
                      setAuctionFZ(false);
                    }
                  }}
                  options={[
                    { value: OfferType.NORMAL, label: OfferType.NORMAL },
                    { value: OfferType.FZ, label: OfferType.FZ },
                    { value: OfferType.RS, label: OfferType.RS },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Form.Item
                hasFeedback
                label="Сумма займа"
                name="purchaseSumm"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  }
                  onChange={checkSumm}
                />
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Form.Item
                hasFeedback
                label="Срок займа"
                name="srok"
                initialValue={'30 дней'}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Form.Item
                hasFeedback
                label="Гарантийный взнос"
                name="purchaseGuarantee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  }
                  readOnly
                />
              </Form.Item>
            </Col>

            <Col xs={4}>
              <Form.Item
                hasFeedback
                label="Номер заявки"
                name="offerNumber"
                initialValue={moment().format('DDMMYYHHmm')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {!auction && (
            <Form.Item>
              <Button
                style={{ marginLeft: 'auto', display: 'flex' }}
                type="primary"
                htmlType="submit"
              >
                Сохранить и продолжить
              </Button>
            </Form.Item>
          )}
        </Card>
        {auction && (
          <>
            <Card
              style={{ marginTop: '16px' }}
              size="small"
              hoverable
              title={<Text type="secondary">Общая информация о закупке</Text>}
            >
              <Row gutter={[12, 12]}>
                <Col xs={6}>
                  <Form.Item
                    hasFeedback
                    label="№ Аукциона"
                    name="purchase"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input onChange={damia} />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item
                    hasFeedback
                    label="Площадка"
                    name="purchasePortal"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    hasFeedback
                    label="Наименование закупки"
                    name="purchaseInfo"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextArea readOnly placeholder="Заполните номер аукциона" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card
              style={{ marginTop: '16px' }}
              size="small"
              hoverable
              title={
                <Text type="secondary">
                  Информация о процедуре электронного аукциона
                </Text>
              }
            >
              <Row gutter={[12, 12]}>
                <Col xs={12}>
                  <Form.Item
                    hasFeedback
                    label="Дата и время окончания срока подачи заявок на участие в электронном аукционе"
                    name="purchaseDateFinalDocs"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputMask placeholder={''} mask="99.99.9999 99:99">
                      {(
                        inputProps: JSX.IntrinsicAttributes &
                          InputProps &
                          React.RefAttributes<InputRef>,
                      ) => <Input allowClear {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    hasFeedback
                    label="Дата окончания срока рассмотрения заявок на участие в электронном аукционе"
                    name="purchaseDateFinalOffer"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputMask placeholder={''} mask="99.99.9999">
                      {(
                        inputProps: JSX.IntrinsicAttributes &
                          InputProps &
                          React.RefAttributes<InputRef>,
                      ) => <Input allowClear {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    hasFeedback
                    label="Дата проведения электронного аукциона"
                    name="purchaseDate"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputMask placeholder={''} mask="99.99.9999">
                      {(
                        inputProps: JSX.IntrinsicAttributes &
                          InputProps &
                          React.RefAttributes<InputRef>,
                      ) => <Input allowClear {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                </Col>

                <Divider orientation="left" plain>
                  <Text type="secondary">Условия договора</Text>
                </Divider>
                <Col md={24} xl={12} xxl={8}>
                  <Form.Item
                    hasFeedback
                    label="Начальная (максимальная) цена договора"
                    name="purchaseOfferMax"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                      }
                      readOnly
                    />
                  </Form.Item>
                </Col>
                <Col md={24} xl={12} xxl={8}>
                  <Form.Item
                    hasFeedback
                    label="Размер обеспечения заявки на участие в электронном аукционе"
                    name="purchaseElectronAuction"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                      }
                      onChange={(event) => {
                        form.setFieldValue('purchaseSumm', event);
                        checkSumm();
                      }}
                      readOnly={!auctionFZ}
                    />
                  </Form.Item>
                </Col>

                <Col md={24} xl={12} xxl={8}>
                  <Form.Item
                    hasFeedback
                    label="Размер обеспечения исполнения обязательств по договору"
                    name="purchaseOfferProvide"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                      }
                      readOnly={!auctionFZ}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  style={{ marginLeft: 'auto', display: 'flex' }}
                  type="primary"
                  htmlType="submit"
                >
                  Сохранить и продолжить
                </Button>
              </Form.Item>
            </Card>
          </>
        )}
      </Form>
    </Spin>
    // </div>
  );
};
