import { IOffer } from '../../interfaces';
import {
  Divider,
  MarkdownField,
  NumberField,
  Table,
  Typography,
} from '@pankod/refine-antd';
import React from 'react';
import { InvestmentStatus } from '../../enums/investment.status';
const { Text, Title } = Typography;
export function OfferInfoBlock(props: { record: IOffer }) {
  return (
    <>
      <Divider orientation="left" plain>
        <Text type="secondary">Дополнительная информация</Text>
      </Divider>
      <Table
        size={'small'}
        showHeader={false}
        pagination={false}
        columns={[
          {
            title: '',
            dataIndex: 'infoKey',
            key: 'infoKey',
            width: '60%',
          },
          {
            title: '',
            dataIndex: 'infoValue',
            key: 'infoValue',
          },
        ]}
        dataSource={[
          {
            infoKey: 'Цели использования суммы займа',
            infoValue: 'test',
          },
          {
            infoKey:
              'Информация, позволяющая составить общее представление о целях привлечения инвестиций и об обстоятельствах, которые могут оказать влияние на достижение указанных целей',
            infoValue: 'test',
          },
          {
            infoKey:
              'Обязанность Заемщика по предоставлению обеспечения исполнения обязательств по договору Займа.',
            infoValue: 'test',
          },
          {
            infoKey: 'Прочие условия Инвестиционного предложения',
            infoValue: 'test',
          },
        ]}
      />
      <br />
      <br />
    </>
  );
}
