import React, { useState, useContext } from 'react';
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNotification,
} from '@pankod/refine-core';
import {
  Alert,
  AutoComplete,
  Create,
  Divider,
  Form,
  Input,
  Select,
  Typography,
  Upload,
  UploadFile,
  useFileUploadState,
  useForm,
  Row,
  Col,
  Card,
  Button,
  InputNumber,
  Space,
  Spin,
} from '@pankod/refine-antd';
import { ArrowDownOutlined } from '@ant-design/icons';

import 'react-mde/lib/styles/css/react-mde-all.css';
import { IPicture, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import { UserType } from '../../enums/user.type';
import { axiosInstance } from '../../App';
import moment from 'moment';
import { FormRule, InputProps, InputRef } from 'antd';
import { Role } from '../../enums/role';
import InputMask from 'react-input-mask';
import { normalizeFile } from '../../utility/normalize';
import { DocumentType } from '../../enums/document.type';
import { dateStandartFormat } from 'utility/dateStandartFormat';
import dadataAddress from 'utility/dadata/address';
import { UserContext } from 'UserProvider';
import axios from 'axios';
import { useNavigate } from '@pankod/refine-react-router-v6';
const { Text } = Typography;
export const AgentCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, form } = useForm<IUser>({
    successNotification: {
      description: 'Пользователь создан',
      message: 'Просматривайте пользователей в разделе Пользователи',
      type: 'success',
    },
    errorNotification: {
      description: 'Пользователь не создан',
      message: 'Email или телефон уже заняты',
      type: 'error',
    },
  });
  const [files, setFiles] = useState<string[]>([]);
  // const { data: me, refetch } = useGetIdentity<IUser>();
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const { open: notify } = useNotification();
  const { onChange } = useFileUploadState();
  const navigate = useNavigate();
  const apiUrl = useApiUrl();
  const [userType, setUserType] = useState<UserType>();
  const [role, setRole] = useState<Role>();
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );
  const handleSearch = async (value: string) => {
    let res: { value: string; label: string }[] = [];
    const dadataSugs = await dadataAddress(value);
    res = dadataSugs.map((s: any) => {
      return {
        value: s.unrestricted_value,
        label: s.unrestricted_value,
        disabled: !s.data.house,
      };
    });
    setOptions(res);
  };
  const [cantEdit, setCantEdit] = useState<boolean>(false);
  const [hideIp, setHideIp] = useState<boolean>(true);
  //
  const dadataBank = (event: any) => {
    if (event.target.value.length >= 9) {
      axiosInstance
        .get(
          process.env.REACT_APP_BACKEND_URL +
            '/api/v1/dadata/bank/' +
            event.target.value,
        )
        .then((res) => {
          form.setFieldsValue({
            bankName: res.data.suggestions[0].value,
            bankCorNumber: res.data.suggestions[0].data.correspondent_account,
          });
        });
    }
  };
  const datataOrg = (inn: string) => {
    function getFounders(arrFounders: any) {
      let foundersString = '';
      arrFounders.forEach((el: any, id: number) => {
        foundersString += `${el.fio.source} ${el.share.value}%`;
        if (id !== arrFounders.length - 1) {
          foundersString += ' ,';
        }
      });
      return foundersString;
    }
    if (inn.length === 10) {
      setUserType(UserType.UL);

      axiosInstance
        .get(process.env.REACT_APP_BACKEND_URL + '/api/v1/dadata/' + inn)
        .then((res) => {
          setHideIp(true);
          form.setFieldsValue({
            type: UserType.UL,
          });
          form.setFieldsValue({
            organisationInn: inn,
          });
          form.setFieldsValue({
            managerPosition: res.data.suggestions[0].data.managers[0].post,
          });
          form.setFieldsValue({
            orgFullName: res.data.suggestions[0].data.name.full_with_opf,
          });
          form.setFieldsValue({
            orgName: res.data.suggestions[0].data.name.short_with_opf,
          });
          form.setFieldsValue({
            revenue: +res.data.suggestions[0].data.finance.income,
          });
          form.setFieldsValue({
            income:
              +res.data.suggestions[0].data.finance.income -
              res.data.suggestions[0].data.finance.expense,
          });
          form.setFieldsValue({
            infoDebt: res.data.suggestions[0].data.finance.debt,
          });
          form.setFieldsValue({
            infoFounders: JSON.stringify(res.data.suggestions[0].data.founders),
          });
          form.setFieldsValue({
            regDate: dateStandartFormat(
              res.data.suggestions[0].data.state.registration_date,
            ),
          });
          form.setFieldsValue({
            regDate: dateStandartFormat(
              new Date(res.data.suggestions[0].data.ogrn_date),
            ),
          });
          form.setFieldsValue({
            infoTax: res.data.suggestions[0].data.finance.tax_system,
          });
          form.setFieldsValue({
            inn: res.data.suggestions[0].data.managers[0].inn,
          });
          form.setFieldsValue({
            firstName: res.data.suggestions[0].data.managers[0].fio.name,
          });

          form.setFieldsValue({
            middleName: res.data.suggestions[0].data.managers[0].fio.patronymic,
          });
          form.setFieldsValue({
            lastName: res.data.suggestions[0].data.managers[0].fio.surname,
          });
          form.setFieldsValue({
            infoActivities: res.data.suggestions[0].data.okveds[0].name,
          });
          form.setFieldsValue({ orgOgrn: res.data.suggestions[0].data.ogrn });
          form.setFieldsValue({ kpp: res.data.suggestions[0].data.kpp });
          form.setFieldsValue({ orgName: res.data.suggestions[0].value });
          form.setFieldsValue({
            orgLocation: res.data.suggestions[0].data.address.value,
          });
          form.setFieldsValue({
            ipRegDate: moment(res.data.suggestions[0].data.ogrn_date).format(
              'DD.MM.YYYY',
            ),
          });
          form.setFieldsValue({
            capital: res.data.suggestions[0].data.capital.value,
          });
          form.setFieldsValue({
            infoStructure: res.data.suggestions[0].data.management.name,
          });

          form.setFieldsValue({
            infoPercent: getFounders(res.data.suggestions[0].data.founders),
          });

          setCantEdit(true);
        });
    }
  };
  const dadataPersonal = (inn: string) => {
    if (inn.length >= 12) {
      setUserType(UserType.IP);
      setHideIp(false);

      axiosInstance
        .get(process.env.REACT_APP_BACKEND_URL + '/api/v1/dadata/' + inn)
        .then((res) => {
          form.setFieldsValue({
            type: UserType.IP,
          });
          form.setFieldsValue({
            organisationInn: inn,
          });
          form.setFieldsValue({
            managerPosition: res.data.suggestions[0].data.opf.full,
          });
          form.setFieldsValue({
            orgFullName: res.data.suggestions[0].data.name.full_with_opf,
          });
          form.setFieldsValue({
            ipRegDate: moment(res.data.suggestions[0].data.ogrn_date).format(
              'DD.MM.YYYY',
            ),
          });

          form.setFieldsValue({ orgOgrn: res.data.suggestions[0].data.ogrn });
          form.setFieldsValue({ kpp: res.data.suggestions[0].data.kpp });
          form.setFieldsValue({ orgName: res.data.suggestions[0].value });
          form.setFieldsValue({
            orgLocation: res.data.suggestions[0].data.address.value,
          });
          form.setFieldsValue({
            firstName: res.data.suggestions[0].data.fio.name,
          });
          form.setFieldsValue({
            middleName: res.data.suggestions[0].data.fio.patronymic,
          });
          form.setFieldsValue({
            lastName: res.data.suggestions[0].data.fio.surname,
          });
          form.setFieldsValue({
            infoActivities: res.data.suggestions[0].data.okveds[0].name,
          });
          form.setFieldsValue({
            regDate: dateStandartFormat(
              res.data.suggestions[0].data.state.registration_date,
            ),
          });
          form.setFieldsValue({
            ipRegDate: dateStandartFormat(
              res.data.suggestions[0].data.state.registration_date,
            ),
          });
          form.setFieldsValue({
            inn: inn,
          });
          form.setFieldsValue({
            infoStructure: res.data.suggestions[0].data.name.full,
          });
          form.setFieldsValue({
            infoPercent: res.data.suggestions[0].data.name.full,
          });
          console.log(res.data.suggestions[0].data.state.registration_date);
        });
    }
  };

  const checkDatata = (event: any) => {
    const inn = event.target.value;

    if (inn.length === 10) {
      datataOrg(inn);
    }

    if (inn.length >= 12) {
      dadataPersonal(inn);
    }
  };

  const removeFile = async (file: any) => {
    let result;
    if (notify) {
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/media/remove',
          { file },
        );
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: '',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };

  const [loading, setLoading] = useState<boolean>(false);
  const handleUpload = async (file: File | null | Blob) => {
    const formData = new FormData();
    setLoading(true);
    if (file instanceof File) {
      formData.append('image', file, file.name);
    } else if (file) {
      formData.append('image', file);
    }

    // formData.append('token', '3330064408517a29ec114c14a2be4080');
    formData.append('token', '9447574a3527cd997e51e79080de31fc');
    function setPassNumber(passNumber: string) {
      return `серия: ${passNumber.split(' ')[0]}, № ${
        passNumber.split(' ')[1]
      }`;
    }
    try {
      const response = await axios.post(
        'https://api.ocr.ads-soft.ru/recognition/',

        formData,
      );
      const data = response.data.data[0].data.results;

      for (const passportData of data) {
        switch (passportData.label) {
          case 'birth_date':
            form.setFieldsValue({
              dateOfBirth: moment(passportData.text).format('DD-MM-YYYY'),
            });
            break;
          case 'birth_place':
            form.setFieldsValue({
              placeOfBirth: passportData.text,
            });
            break;
          case 'serial_1':
            form.setFieldsValue({
              passportNumber: setPassNumber(passportData.text),
            });
            break;
          case 'issued_date':
            form.setFieldsValue({
              issueDate: moment(passportData.text).format('DD-MM-YYYY'),
            });
            break;
          case 'issued':
            form.setFieldsValue({
              passportIssuedBy: passportData.text,
            });
            break;
          case 'issued_number':
            form.setFieldsValue({
              passportCodeDepartment: passportData.text,
            });
            break;
          case 'name':
            form.setFieldsValue({
              firstName: passportData.text,
            });

            break;
          case 'middlename':
            form.setFieldsValue({
              middleName: passportData.text,
            });

            break;
          case 'lastname':
            form.setFieldsValue({
              lastName: passportData.text,
            });
            break;

          default:
            break;
        }
        console.log(passportData);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onType = (type: UserType) => {
    setUserType(type);
  };
  const innRule: FormRule = {
    type: 'string',
    min: 12,
    max: 12,
    message: 'ИНН физлица содержит 12 цифр',
  };
  const orgInnRule: FormRule = {
    type: 'string',
    min: 10,
    max: 12,
    message: 'ИНН юрлица содержит 10 цифр',
  };
  const orgOgrnRule: FormRule = {
    type: 'string',
    min: 13,
    max: 13,
    message: 'ОГРН юрлица содержит 13 цифр',
  };
  const ogrnipRule: FormRule = {
    type: 'string',
    min: 15,
    max: 15,
    message: 'ОГРНИП содержит 15 цифр',
  };

  const onInfoSubmit = async (values: IUser) => {
    let result;
    if (!me) return;

    if (notify) {
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/users/saveNewUser',
          { phone: me.phone, ...values },
        );
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: 'Убедитесь что данные заполнены правильно',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };

  return (
    <Create
      headerProps={{
        subTitle: '',
      }}
      title="Создать пользователя"
      contentProps={{ style: { background: 'none', boxShadow: 'none' } }}
      saveButtonProps={{
        ...saveButtonProps,
        children: 'Сохранить',
      }}
    >
      <Spin tip="Загрузка.." spinning={loading}>
        <Form
          {...formProps}
          onFinish={async (values) => {
            // /api/v1/agents/
            const result = await axiosInstance.post(
              process.env.REACT_APP_BACKEND_URL + '/api/v1/agents/',
              { ...values },
            );
            navigate('/agents');
          }}
          layout="vertical"
          style={{ background: 'none' }}
        >
          <Space size={12} style={{ width: '100%' }} direction="vertical">
            <Card title="Основные данные" hoverable>
              <Row gutter={[12, 12]}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Form.Item
                    label="ИНН"
                    name="orgInn"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                      orgInnRule,
                    ]}
                  >
                    <Input onInput={checkDatata} maxLength={orgInnRule.max} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Form.Item
                    label="Полное наименование"
                    name="orgFullName"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Form.Item
                    label="Электронная почта"
                    name="email"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                      {
                        type: 'email',
                        message: 'Пожалуйста, введите Ваш email',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Form.Item
                    label="Номер мобильного телефона"
                    name="phone"
                    hasFeedback
                    // validateTrigger='onSubmit'
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            ((+value[3] >= 1 && +value[3] <= 9) ||
                              value[3] === '0') &&
                            ((+value[4] >= 1 && +value[4] <= 9) ||
                              value[4] === '0') &&
                            ((+value[5] >= 1 && +value[5] <= 9) ||
                              value[5] === '0') &&
                            ((+value[7] >= 1 && +value[7] <= 9) ||
                              value[7] === '0') &&
                            ((+value[8] >= 1 && +value[8] <= 9) ||
                              value[8] === '0') &&
                            ((+value[9] >= 1 && +value[9] <= 9) ||
                              value[9] === '0') &&
                            ((+value[11] >= 1 && +value[11] <= 9) ||
                              value[11] === '0') &&
                            ((+value[12] >= 1 && +value[12] <= 9) ||
                              value[12] === '0') &&
                            ((+value[14] >= 1 && +value[14] <= 9) ||
                              value[14] === '0') &&
                            ((+value[15] >= 1 && +value[15] <= 9) ||
                              value[15] === '0')
                          ) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error('Пожалуйста, введите номер мобильного'),
                            );
                          }
                        },
                      },
                    ]}
                  >
                    {/*<Input />*/}
                    <InputMask
                      placeholder={'+7(___)-___-__-__'}
                      mask="+7(999)999-99-99"
                      // maskChar=' '
                    >
                      {(
                        inputProps: JSX.IntrinsicAttributes &
                          InputProps &
                          React.RefAttributes<InputRef>,
                      ) => <Input {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Сокращенное наименование"
                name="orgName"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label="Организационно-правовая форма"
                name="type"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  disabled={cantEdit}
                  onChange={onType}
                  options={[
                    {
                      label: UserType.IP,
                      value: UserType.IP,
                    },
                    {
                      label: UserType.UL,
                      value: UserType.UL,
                    },
                  ]}
                />
              </Form.Item>
              {/* ОГРН ИНН и тд */}
              <Row gutter={8}>
                <Col>
                  <Form.Item
                    label="ОГРН"
                    name="orgOgrn"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                {hideIp && (
                  <>
                    <Col>
                      <Form.Item
                        label="КПП"
                        name="kpp"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col>
                  <Form.Item
                    label="ИНН"
                    name="organisationInn"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Дата регистрации"
                    name="regDate"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Адрес регистрации (город, улица, дом)"
                name="orgLocation"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <AutoComplete
                  disabled={cantEdit}
                  onSearch={handleSearch}
                  placeholder=""
                  options={options}
                />
              </Form.Item>
              <Form.Item label="Адрес фактического местонахождения (город, улица, дом)">
                <div style={{ display: 'flex' }}>
                  <Button
                    size="middle"
                    style={{ marginRight: 10 }}
                    icon={<ArrowDownOutlined />}
                    onClick={() => {
                      form.setFieldsValue({
                        orgFactLocation: form.getFieldValue('orgLocation'),
                      });
                    }}
                  >
                    Совпадает
                  </Button>
                  <Form.Item
                    style={{ display: 'inline-block', width: '100%' }}
                    name="orgFactLocation"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <AutoComplete
                      size={'middle'}
                      onSearch={handleSearch}
                      placeholder=""
                      options={options}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
              {hideIp ? (
                <Row>
                  <Col>
                    <Form.Item
                      label="Уставной капитал (₽)"
                      name="capital"
                      hasFeedback
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Form.Item
                  label="Регистрация ИП"
                  name="ipRegDate"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
            </Card>
            <Card title="Банковские реквизиты" hoverable>
              <Form.Item
                label="БИК"
                name="bik"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input onInput={dadataBank} />
              </Form.Item>
              <Form.Item
                label="Расчетный счет"
                name="bankAccNumber"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Наименование банка"
                name="bankName"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Корр. счет"
                name="bankCorNumber"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Card>

            <Card title="Данные агента" hoverable>
              <Alert
                message="Заполнение информации"
                description={
                  <Space direction="vertical">
                    <Text>
                      Вы можете воспользоваться сервисом распознования
                      изображений
                    </Text>
                    <Upload
                      beforeUpload={(file) => {
                        handleUpload(file);
                        return false;
                      }}
                    >
                      <Button type="dashed">Загрузить фото паспорта</Button>
                    </Upload>
                  </Space>
                }
                type="info"
                showIcon
              />

              {userType === UserType.UL && (
                <Divider orientation="left" plain>
                  <Text type="secondary">Единоличный исполнительный орган</Text>
                </Divider>
              )}
              {userType === UserType.IP && (
                <Divider orientation="left" plain>
                  <Text type="secondary">Индивидуальный предприниматель</Text>
                </Divider>
              )}
              <Form.Item
                label="ИНН"
                name="inn"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                  innRule,
                ]}
              >
                <Input maxLength={innRule.max} />
              </Form.Item>
              <Form.Item
                label="Фамилия"
                name="lastName"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Имя"
                name="firstName"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Отчество"
                name="middleName"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Дата рождения"
                name="dateOfBirth"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) => {
                      const date = moment(value, 'DD-MM-YYYY').toDate();
                      date.setFullYear(date.getFullYear() + 18);
                      const today = new Date();
                      if (date > today) {
                        return Promise.reject(
                          new Error('Регистрация возможна с 18 лет'),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <InputMask placeholder={''} mask="99.99.9999">
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input allowClear {...inputProps} />}
                </InputMask>
              </Form.Item>
              <Form.Item
                label="Место рождения"
                name="placeOfBirth"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Серия и № паспорта"
                name="passportNumber"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputMask placeholder={''} mask="серия: 9999, № 999999">
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input allowClear {...inputProps} />}
                </InputMask>
              </Form.Item>
              <Form.Item
                label="Дата выдачи"
                name="issueDate"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputMask placeholder={''} mask="99.99.9999">
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input allowClear {...inputProps} />}
                </InputMask>
              </Form.Item>
              <Form.Item
                label="Кем выдан"
                name="passportIssuedBy"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Код подразделения"
                name="passportCodeDepartment"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputMask placeholder={''} mask="999-999">
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input allowClear {...inputProps} />}
                </InputMask>
              </Form.Item>
              <Form.Item
                label="Адрес регистрации (город, улица, дом)"
                name="address"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <AutoComplete
                  onSearch={handleSearch}
                  placeholder=""
                  options={options}
                />
              </Form.Item>
              <Form.Item label="Адрес фактического местонахождения (город, улица, дом)">
                <div style={{ display: 'flex' }}>
                  <Button
                    size="middle"
                    style={{ marginRight: 10 }}
                    icon={<ArrowDownOutlined />}
                    onClick={() => {
                      form.setFieldsValue({
                        factAddress: form.getFieldValue('address'),
                      });
                    }}
                  >
                    Совпадает
                  </Button>
                  <Form.Item
                    style={{ display: 'inline-block', width: '100%' }}
                    name="factAddress"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <AutoComplete
                      size={'middle'}
                      onSearch={handleSearch}
                      placeholder=""
                      options={options}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
            </Card>

            <Card title="Настройки договора" hoverable size="small">
              <Row gutter={[12, 12]}>
                <Col xs={3}>
                  <Form.Item
                    label="Номер договора"
                    name="oferta"
                    hasFeedback
                    initialValue={1}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber min={0} step={1} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Дата договора"
                    name="ofertaDate"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputMask placeholder={''} mask="99.99.9999">
                      {(
                        inputProps: JSX.IntrinsicAttributes &
                          InputProps &
                          React.RefAttributes<InputRef>,
                      ) => <Input allowClear {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                </Col>
                <Divider orientation="left" plain>
                  <Text type="secondary">Ставки пролонгации</Text>
                </Divider>
                <Col>
                  <Form.Item
                    label="7 дней"
                    name="prolong1"
                    hasFeedback
                    initialValue={0.1}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      step={0.1}
                      precision={2}
                      addonAfter={'%'}
                      formatter={(value: any) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="14 дней"
                    name="prolong2"
                    hasFeedback
                    initialValue={0.2}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      step={0.1}
                      precision={2}
                      addonAfter={'%'}
                      formatter={(value: any) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="21 день"
                    name="prolong3"
                    hasFeedback
                    initialValue={0.3}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      step={0.1}
                      precision={2}
                      addonAfter={'%'}
                      formatter={(value: any) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="30 дней"
                    name="prolong4"
                    hasFeedback
                    initialValue={0.4}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      step={0.1}
                      precision={2}
                      addonAfter={'%'}
                      formatter={(value: any) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Space>
        </Form>
      </Spin>
    </Create>
  );
};
