import React, { useState, useContext, useEffect } from 'react';
import { IResourceComponentsProps } from '@pankod/refine-core';
import {
  Breadcrumb,
  Create,
  Typography,
  useForm,
  Steps,
  ShowButton,
  SaveButton,
  Button,
} from '@pankod/refine-antd';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { IOffer } from 'interfaces';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { UserContext } from 'UserProvider';
import { Step1 } from 'components/offers/create/step1';
import { Step2 } from 'components/offers/create/step2';
import { Step3 } from 'components/offers/create/step3';

export function getPayment(sum: number, period: number, rate: number) {
  const i = rate / 12 / 100;
  const koef = (i * Math.pow(1 + i, period)) / (Math.pow(1 + i, period) - 1);
  const result = sum * koef;
  return Math.round(result * 100) / 100;
}

export const OfferCreate: React.FC<IResourceComponentsProps | any> = (props: {
  refetch: any;
}) => {
  const navigate = useNavigate();

  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const { formProps, saveButtonProps, form } = useForm<IOffer>({
    successNotification: {
      description: 'Предложение создано',
      message: 'Успешно',
      type: 'success',
    },
    submitOnEnter: true,
    redirect: false,
    onMutationSuccess: (value: any) => {
      navigate('/offers/show/' + value.data.uuid);
    },
    errorNotification: {
      description: 'Предложение не создано',
      message: 'Произошла ошибка: мы уже исправляем, просьба подождать',
      type: 'error',
    },
  });

  const [step, setStep] = useState<number>(0);
  const [uuid, setUuid] = useState<number | undefined>();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const offer = urlParams.get('offer');
    if (offer) setUuid(Number(offer));
  }, []);
  return (
    <Create
      headerProps={{
        subTitle: '',
        breadcrumb: <Breadcrumb breadcrumbProps={{ separator: '/' }} />,
      }}
      contentProps={{
        style: { background: 'none', border: 'none', boxShadow: 'none' },
      }}
      title="Создать инвестиционное предложение"
      saveButtonProps={{ hidden: true }}
    >
      <Steps
        current={step}
        onChange={(value: number) => setStep(value)}
        items={[
          {
            title: 'Сведения об организации заемщика',
          },
          {
            title: 'Сведения о запрашиваемом займе',
          },
          {
            title: 'Сведения о поручительстве',
          },
        ]}
      />
      {step === 0 && (
        <Step1 setStep={() => setStep(1)} uuid={uuid} setUuid={setUuid} />
      )}
      {step === 1 && <Step2 setStep={() => setStep(2)} uuid={uuid} />}
      {step === 2 && <Step3 uuid={uuid} />}
      {/* {step === 2 && (
        <Form
          labelWrap
          scrollToFirstError
          {...formProps}
          {...formItemLayout}
          initialValues={initialValues}
        >
          <Row gutter={{ xs: 8, sm: 24, md: 24, lg: 32 }}>
            <Col className="gutter-row" md={{ span: 24 }} lg={{ span: 12 }}>
              <Divider orientation="left" plain>
                <Text type="secondary">
                  Сведения об инвестиционном предложении
                </Text>
              </Divider>
              <Form.Item
                label="Тип займа"
                name="loanType"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={(value) => {
                    const rateValue =
                      value === LoanType.QLIQ_DEDICATED_LOAN ? 2 : 3;
                    setLoanType(value);
                    setRate(rateValue);
                    changeCalc(sum, months, rateValue);
                    changeMinCalc(minSum, months, rateValue);
                    form.setFieldsValue({
                      interestRate: rateValue,
                      infoTargetGoals:
                        value === LoanType.QLIQ_DEDICATED_LOAN
                          ? 'Участие в аукционе'
                          : 'Пополнение оборотных средств',
                      infoGoals:
                        value === LoanType.QLIQ_DEDICATED_LOAN
                          ? 'Аукцион'
                          : 'Пополнение оборотных средств',
                    });
                  }}
                  options={[
                    {
                      label: LoanType.QLIQ_LOAN,
                      value: LoanType.QLIQ_LOAN,
                    },
                    {
                      label: LoanType.QLIQ_DEDICATED_LOAN,
                      value: LoanType.QLIQ_DEDICATED_LOAN,
                    },
                  ]}
                />
              </Form.Item>
              {loanType === LoanType.QLIQ_DEDICATED_LOAN && (
                <>
                  <Divider orientation="left" plain>
                    <Text type="secondary">Информация об аукционе</Text>
                  </Divider>
                  <Form.Item
                    label="Номер аукциона"
                    name="tenderNumber"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={30}
                      rows={1}
                      onInput={damia}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Наименование закупки"
                    name="tenderName"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input.TextArea showCount maxLength={2000} rows={1} />
                  </Form.Item>
                  <Form.Item
                    label="Наименование электронной площадки"
                    name="tenderPlatform"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input.TextArea showCount maxLength={100} rows={1} />
                  </Form.Item>
                </>
              )}
              <Form.Item
                label="Минимальная сумма"
                name="minVolume"
                tooltip={
                  'При сборе этой суммы, оферта будет исполнена и инвестиции состоятся, даже если максимальная сумма не будет собрана до истечения срока оферты'
                }
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  onChange={onMinChange}
                  min={20000}
                  step={20000}
                  width={300}
                  precision={2}
                  addonAfter={'₽'}
                  // defaultValue={0}
                  formatter={(value: any) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  }
                />
              </Form.Item>
              <Form.Item
                label="Максимальная сумма"
                name="maxVolume"
                tooltip={
                  'При сборе этой суммы, оферта считается исполненной и снимается с публикации'
                }
                dependencies={['minVolume']}
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, введите сумму',
                  },
                  ({ getFieldValue, setFieldsValue }) => ({
                    validator(_, value) {
                      if (getFieldValue('minVolume') > value) {
                        return Promise.reject(
                          'Должна быть не ниже минимальной суммы',
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  onChange={onSumChange}
                  min={20000}
                  step={20000}
                  width={300}
                  precision={2}
                  addonAfter={'₽'}
                  // defaultValue={0}
                  formatter={(value: any) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  }
                />
              </Form.Item>
              <Form.Item
                label="Срок действия предложения"
                name="deadline"
                initialValue={dayjs(addDaysToDate(new Date(), 10))}
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) => {
                      const date = value.$d;
                      const today = new Date();
                      if (date && date.toString() == 'Invalid Date')
                        return Promise.reject(
                          new Error(
                            'Ошибка формата даты , заполните в формате ДД.ММ.ГГГГ',
                          ),
                        );

                      if (+date < +today) {
                        return Promise.reject(
                          new Error('Дата должна быть в будущем'),
                        );
                      } else if ((+date - +today) / 1000 / 60 / 60 < 24) {
                        return Promise.reject(
                          new Error(
                            'Разница в дате должна быть минимум 24 часа от текущего момента.',
                          ),
                        );
                      } else if ((+date - +today) / 1000 / 60 / 60 > 24 * 10) {
                        return Promise.reject(
                          new Error(
                            'Разница в дате не может быть более 10 дней от текущего момента.',
                          ),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <DatePicker
                  allowClear
                  locale={locale}
                  showTime
                  // defaultValue={}
                  format="DD.MM.YYYY HH:mm"
                />
              </Form.Item>
              <Form.Item
                label="Процентная ставка"
                name="interestRate"
                tooltip={'Ставка фиксирована и зависит от типа займа'}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  disabled
                  width={300}
                  defaultValue={
                    loanType === LoanType.QLIQ_DEDICATED_LOAN ? 2 : 3
                  }
                  addonAfter={'%'}
                />
              </Form.Item>
              <Form.Item
                label="Период займа"
                name="offerPeriodDays"
                tooltip={
                  'Период фиксирован и составляет 30 календарных дней для всех типов займов'
                }
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <InputNumber
                  disabled
                  width={300}
                  defaultValue={30}
                  addonAfter={'дней'}
                />
              </Form.Item>
            </Col>
            <Col
              style={{ paddingBottom: '30px' }}
              className="gutter-row"
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              {
                <>
                  <Divider orientation="left" plain>
                    <Text type="secondary">
                      Прогноз при сборе минимальной суммы
                    </Text>
                  </Divider>
                  <Descriptions
                    title=""
                    size="small"
                    bordered
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                  >
                    <Descriptions.Item label={'К возвращению'}>
                      {
                        <>
                          <NumberField
                            value={minCalc?.toReturn ? minCalc.toReturn : 0}
                          />
                          <span>&nbsp;₽</span>
                        </>
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={'Проценты'}>
                      {
                        <>
                          <NumberField
                            value={minCalc?.profit ? minCalc.profit : 0}
                          />
                          <span>&nbsp;₽</span>
                        </>
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={'Гарантийный взнос'}>
                      {
                        <>
                          <NumberField
                            value={minCalc?.profit ? minCalc.profit : 0}
                          />
                          <span>&nbsp;₽</span>
                        </>
                      }
                    </Descriptions.Item>
                  </Descriptions>
                </>
              }
              {
                <>
                  <Divider orientation="left" plain>
                    <Text type="secondary">
                      Прогноз при сборе максимальной суммы
                    </Text>
                  </Divider>
                  <Descriptions
                    title=""
                    size="small"
                    bordered
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                  >
                    <Descriptions.Item label={'К возвращению'}>
                      {
                        <>
                          <NumberField
                            value={calc?.toReturn ? calc.toReturn : 0}
                          />
                          <span>&nbsp;₽</span>
                        </>
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={'Проценты'}>
                      {
                        <>
                          <NumberField value={calc?.profit ? calc.profit : 0} />
                          <span>&nbsp;₽</span>
                        </>
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={'Гарантийный взнос'}>
                      {
                        <>
                          <NumberField value={calc?.profit ? calc.profit : 0} />
                          <span>&nbsp;₽</span>
                        </>
                      }
                    </Descriptions.Item>
                  </Descriptions>
                </>
              }
            </Col>
          </Row>

          <>
            <Divider orientation="left" plain>
              <Text>Информация о поручителе</Text>
            </Divider>

            <Alert
              style={{ marginBottom: 12 }}
              message="Заполнение информации"
              description={
                <Space direction="vertical">
                  <Text>
                    Вы можете воспользоваться сервисом распознования изображений
                  </Text>
                  <Upload
                    beforeUpload={(file) => {
                      handleUpload(file);
                      return false;
                    }}
                  >
                    <Button type="dashed">Загрузить фото паспорта</Button>
                  </Upload>
                </Space>
              }
              type="info"
              showIcon
            />
            <Spin
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              tip="Загрузка"
              size="large"
              spinning={loading}
            >
              <Button
                size="middle"
                style={{ float: 'right', marginBottom: '20px' }}
                icon={<ArrowDownOutlined />}
              >
                Автозаполнение
              </Button>
              <div style={{ clear: 'both' }}></div>

              <Form.Item
                label="Фамилия"
                name="guarantorLastName"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) => {
                      const regex = /^[а-яА-ЯёЁ\s-]*$/;
                      if (!regex.test(value)) {
                        return Promise.reject(
                          new Error('Пожалуйста, введите только кириллицу'),
                        );
                      } else if (value.length > 50) {
                        return Promise.reject(
                          new Error('Допустимо максимум 50 символов'),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input onInput={onGuarantorFio} />
              </Form.Item>
              <Form.Item
                label="Имя"
                name="guarantorFirstName"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) => {
                      const regex = /^[а-яА-ЯёЁ\s-]*$/;
                      if (!regex.test(value)) {
                        return Promise.reject(
                          new Error('Пожалуйста, введите только кириллицу'),
                        );
                      } else if (value.length > 50) {
                        return Promise.reject(
                          new Error('Допустимо максимум 50 символов'),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input onInput={onGuarantorFio} />
              </Form.Item>
              <Form.Item
                label="Отчество"
                name="guarantorMiddleName"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) => {
                      const regex = /^[а-яА-ЯёЁ\s-]*$/;
                      if (!regex.test(value)) {
                        return Promise.reject(
                          new Error('Пожалуйста, введите только кириллицу'),
                        );
                      } else if (value.length > 50) {
                        return Promise.reject(
                          new Error('Допустимо максимум 50 символов'),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input onInput={onGuarantorFio} />
              </Form.Item>
              <Form.Item
                label="Дата рождения"
                name="guarantorDateOfBirth"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) => {
                      const date = moment(value, 'DD-MM-YYYY').toDate();
                      if (date && date.toString() == 'Invalid Date')
                        return Promise.reject(
                          new Error(
                            'Ошибка формата даты , заполните в формате ДД.ММ.ГГГГ',
                          ),
                        );

                      date.setFullYear(date.getFullYear() + 18);
                      const today = new Date();
                      if (date > today) {
                        return Promise.reject(
                          new Error('Поручительство возможно с 18 лет'),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <InputMask placeholder={''} mask="99.99.9999">
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input allowClear {...inputProps} />}
                </InputMask>
              </Form.Item>
              <Form.Item
                label="Место рождения"
                name="guarantorPlaceOfBirth"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) => {
                      const regex = /^[0-9,./а-яА-ЯёЁ\s-]*$/;
                      if (!regex.test(value)) {
                        return Promise.reject(
                          new Error('Пожалуйста, введите только кириллицу'),
                        );
                      } else if (value.length > 1000) {
                        return Promise.reject(
                          new Error('Допустимо максимум 1000 символов'),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Серия и № паспорта"
                name="guarantorPassportNumber"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputMask
                  placeholder={''}
                  mask="серия: 9999, № 999999"
                  // maskChar=' '
                >
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input allowClear {...inputProps} />}
                </InputMask>
              </Form.Item>
              <Form.Item
                label="Дата выдачи"
                name="guarantorIssueDate"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputMask placeholder={''} mask="99.99.9999">
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input allowClear {...inputProps} />}
                </InputMask>
              </Form.Item>
              <Form.Item
                label="Кем выдан"
                name="guarantorPassportIssuedBy"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) => {
                      const regex = /^[0-9,./а-яА-ЯёЁ\s-]*$/;
                      if (!regex.test(value)) {
                        return Promise.reject(
                          new Error('Пожалуйста, введите только кириллицу'),
                        );
                      } else if (value.length > 1000) {
                        return Promise.reject(
                          new Error('Допустимо максимум 1000 символов'),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Код подразделения"
                name="guarantorPassportCodeDepartment"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputMask
                  placeholder={''}
                  mask="999-999"
                  // maskChar=' '
                >
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input allowClear {...inputProps} />}
                </InputMask>
              </Form.Item>
              <Form.Item
                label="Адрес регистрации (город, улица, дом)"
                name="guarantorAddress"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) => {
                      const regex = /^[0-9,./а-яА-ЯёЁ\s-]*$/;
                      if (!regex.test(value)) {
                        return Promise.reject(
                          new Error('Пожалуйста, введите только кириллицу'),
                        );
                      } else if (value.length > 2000) {
                        return Promise.reject(
                          new Error('Допустимо максимум 2000 символов'),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <AutoComplete
                  onSearch={handleSearch}
                  placeholder=""
                  options={options}
                />
              </Form.Item>
              <Form.Item label="Адрес проживания (город, улица, дом)">
                <div style={{ display: 'flex' }}>
                  <Button
                    size="middle"
                    style={{ marginRight: 10 }}
                    icon={<ArrowDownOutlined />}
                    onClick={() => {
                      form.setFieldsValue({
                        guarantorFactAddress:
                          form.getFieldValue('guarantorAddress'),
                      });
                    }}
                  >
                    Совпадает
                  </Button>
                  <Form.Item
                    style={{
                      display: 'inline-block',
                      width: '100%',
                      marginBottom: 0,
                    }}
                    name="guarantorFactAddress"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                      {
                        validator: (_, value) => {
                          const regex = /^[0-9,./а-яА-ЯёЁ\s-]*$/;
                          if (!regex.test(value)) {
                            return Promise.reject(
                              new Error('Пожалуйста, введите только кириллицу'),
                            );
                          } else if (value.length > 2000) {
                            return Promise.reject(
                              new Error('Допустимо максимум 2000 символов'),
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <AutoComplete
                      size={'middle'}
                      onSearch={handleSearch}
                      placeholder=""
                      options={options}
                    />
                  </Form.Item>
                </div>
              </Form.Item>

              <Form.Item
                label="ИНН"
                name="guarantorInn"
                rules={[
                  {
                    required: false,
                  },
                  innRule,
                ]}
              >
                <Input onInput={dadataPersonal} maxLength={innRule.max} />
              </Form.Item>
              <Form.Item
                label="Email"
                name="guarantorEmail"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Номер мобильного телефона"
                name="guarantorPhone"
                hasFeedback
                rules={[
                  {
                    required: false,
                  },

                  {
                    validator: (_, value) => {
                      if (value[3] === '9') {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error('Мобильный номер РФ должен начинаться с 9'),
                        );
                      }
                    },
                  },
                  {
                    validator: (_, value) => {
                      if (
                        ((+value[3] >= 1 && +value[3] <= 9) ||
                          value[3] === '0') &&
                        ((+value[4] >= 1 && +value[4] <= 9) ||
                          value[4] === '0') &&
                        ((+value[5] >= 1 && +value[5] <= 9) ||
                          value[5] === '0') &&
                        ((+value[7] >= 1 && +value[7] <= 9) ||
                          value[7] === '0') &&
                        ((+value[8] >= 1 && +value[8] <= 9) ||
                          value[8] === '0') &&
                        ((+value[9] >= 1 && +value[9] <= 9) ||
                          value[9] === '0') &&
                        ((+value[11] >= 1 && +value[11] <= 9) ||
                          value[11] === '0') &&
                        ((+value[12] >= 1 && +value[12] <= 9) ||
                          value[12] === '0') &&
                        ((+value[14] >= 1 && +value[14] <= 9) ||
                          value[14] === '0') &&
                        ((+value[15] >= 1 && +value[15] <= 9) ||
                          value[15] === '0')
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error('Пожалуйста, введите номер мобильного'),
                        );
                      }
                    },
                  },
                ]}
              >
                <InputMask
                  placeholder={'+7(___)-___-__-__'}
                  mask="+7(999)999-99-99"
                >
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input {...inputProps} />}
                </InputMask>
              </Form.Item>
            </Spin>
          </>

          <Divider orientation="left" plain>
            <Text type="secondary">
              Дополнительная информация о предложении
            </Text>
          </Divider>
          <Form.Item
            label="Цели использования суммы займа"
            name="infoTargetGoals"
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  if (value.length > 1000) {
                    return Promise.reject(
                      new Error('Допустимо максимум 1000 символов'),
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input.TextArea showCount maxLength={1000} rows={3} />
          </Form.Item>
          <Form.Item
            label="Информация, позволяющая составить общее представление о целях привлечения инвестиций и об обстоятельствах, которые могут оказать влияние на достижение указанных целей"
            name="infoGoals"
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  if (value.length > 1000) {
                    return Promise.reject(
                      new Error('Допустимо максимум 1000 символов'),
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input.TextArea showCount maxLength={1000} rows={3} />
          </Form.Item>
          <Form.Item
            label="Обязанность Заемщика по предоставлению обеспечения исполнения обязательств по договору Займа."
            name="infoExecuting"
            tooltip={'ФИО поручителей'}
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  if (value.length > 1000) {
                    return Promise.reject(
                      new Error('Допустимо максимум 1000 символов'),
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input.TextArea showCount maxLength={1000} rows={3} />
          </Form.Item>
          <Form.Item
            label="Прочие условия Инвестиционного предложения"
            name="infoOther"
            rules={[
              {
                required: false,
              },
              {
                validator: (_, value) => {
                  if (value.length > 1000) {
                    return Promise.reject(
                      new Error('Допустимо максимум 1000 символов'),
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input.TextArea
              showCount
              maxLength={1000}
              rows={3}
              defaultValue={'Отсутствуют'}
            />
          </Form.Item>

          <Form.Item
            label="Инвесторы, которым доступно предложение"
            name="needInvestors"
            initialValue="Всем"
            tooltip="В случае если желаете показать предложение всем инвесторам , оставьте значение Всем , иначе напишите название или ИНН предпочтительных"
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  if (value.length > 1000) {
                    return Promise.reject(
                      new Error('Допустимо максимум 1000 символов'),
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input.TextArea showCount maxLength={1000} />
          </Form.Item>

          <Divider orientation="left" plain>
            <Text type="secondary">
              Информация о займах, размещенных на других платформах в текущем
              году
            </Text>
          </Divider>
          <Form.Item
            label="Общая сумма займов на других платформах"
            name="lendOtherAccount"
            tooltip={
              'Общая сумма займов, размещенных на других платформах в текущем календарном году, на момент размещения предложения'
            }
            rules={[
              {
                required: false,
              },
            ]}
          >
            <InputNumber
              min={0}
              step={20000}
              width={300}
              precision={2}
              addonAfter={'₽'}
              formatter={(value: any) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              }
            />
          </Form.Item>
          <Form.Item
            style={{ height: '0px' }}
            name="publicFiles"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input type={'hidden'} />
          </Form.Item>
          <Divider orientation="left" plain>
            <Text type="secondary">Документы</Text>
          </Divider>
          <div>
            <Doc documents={myDocs} />
          </div>

          <Form.Item
            style={{ height: '0px' }}
            name="privateFiles"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input type={'hidden'} />
          </Form.Item>
        </Form>
      )} */}
    </Create>
  );
};
