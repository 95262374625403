import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useGetIdentity,
  useNavigation,
  useNotification,
  useShow,
  useUpdate,
} from '@pankod/refine-core';
import {
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Col,
  DateField,
  Descriptions,
  Divider,
  EditButton,
  EmailField,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  NumberField,
  Row,
  Show,
  ShowButton,
  Space,
  Spin,
  Table,
  Tabs,
  TagField,
  TextField,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import { SolutionOutlined } from '@ant-design/icons';

import { IAgent, IAgreement, IPicture, IUser } from 'interfaces';
import React, { useState } from 'react';
import { UserOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Role } from '../../enums/role';
import { UserType } from '../../enums/user.type';
import { ScanImage } from '../../components/offers/scanImage';
import { UserStatus } from '../../enums/user.status';
import { axiosInstance } from '../../App';
import { DocumentType } from '../../enums/document.type';
import { ModalAgentList } from 'components/users/modalAgentList';
import { PersonalDocuments } from 'pages/profile/documents';
import { ModalSendMessage } from 'components/users/modalSendMessage';
import { AgentReport } from 'components/agents/agentReport';
import { AgreementType } from 'enums/agreement.type';

const { Text } = Typography;

export const AgentShow: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const showManual = process.env.REACT_APP_PROD === '0';
  const [loading, setLoading] = useState<boolean>(false);
  const { data: me } = useGetIdentity();
  const { queryResult } = useShow<IAgent>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;

  let userTypeName;
  if (record?.type === UserType.IP) {
    userTypeName = 'Индивидуальный предприниматель';
  } else if (record?.type === UserType.UL) {
    userTypeName = 'Единоличный исполнительный орган';
  } else {
    userTypeName = 'Физическое лицо';
  }
  let fio;
  if (record && record?.lastName && record?.firstName && record?.middleName) {
    fio = record?.lastName + ' ' + record?.firstName + ' ' + record?.middleName;
  } else {
    fio = 'Новый пользователь';
  }
  const dataSourceFL = [
    {
      name: 'ФИО',
      value: fio,
    },
    {
      name: 'ИНН',
      value: record?.inn,
    },
    {
      name: 'Дата рождения',
      value: <DateField value={record?.dateOfBirth} format={'DD.MM.YYYY'} />,
    },
    {
      name: 'Место рождения',
      value: record?.placeOfBirth,
    },
    {
      name: 'Адрес регистрации',
      value: record?.address,
    },
    {
      name: 'Адрес фактического местонахождения',
      value: record?.factAddress,
    },
    {
      name: 'Серия, № паспорта',
      value: record?.passportNumber,
    },
    {
      name: 'Дата выдачи',
      value: <DateField value={record?.issueDate} format={'DD.MM.YYYY'} />,
    },
    {
      name: 'Кем выдан',
      value: record?.passportIssuedBy,
    },
    {
      name: 'Код подразделения',
      value: record?.passportCodeDepartment,
    },
  ];
  const dataSourceUL = [
    {
      name: 'ИНН организации',
      value: record?.orgInn ? record?.orgInn : record?.inn,
    },
    {
      name: 'ОГРН организации',
      value: record?.orgOgrn,
    },
    {
      name: 'Наименование организации',
      value: record?.orgName,
    },
    {
      name: 'Месторасположение организации',
      value: record?.orgLocation,
    },
  ];
  const dataSourceBank = [
    {
      name: 'БИК',
      value: record?.bik,
    },
    {
      name: 'Наименование банка',
      value: record?.bankName,
    },
    {
      name: 'Расчетный счет',
      value: record?.bankAccNumber,
    },
    {
      name: 'Корр. счет',
      value: record?.bankCorNumber,
    },
  ];
  const columns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const { open: notify } = useNotification();

  let initialFilter: CrudFilters = [];
  if (me) {
    if (me.role === Role.AGENT) {
      initialFilter = [
        {
          field: 'agent.uuid',
          operator: 'eq',
          value: me.uuid,
        },
      ];
    }
  }

  let avatar;
  if (record) {
    avatar = record.pictures?.find(
      (p: IPicture) => p.type === DocumentType.AVATAR,
    );
  }

  const aktAgreements = record
    ? record.agreements.filter(
        (agreem) =>
          agreem.type === AgreementType.AKT_REPORT ||
          agreem.type === AgreementType.AKT_WORK,
      )
    : [];
  const lastNo =
    aktAgreements.length > 0
      ? aktAgreements.reduce((prev, current) => {
          return +current.number > +prev.number ? current : prev;
        }).number
      : 0;

  const tabs = [
    {
      key: '1',
      label: 'Информация',
      icon: <InfoCircleOutlined />,
      data: (
        <Row gutter={[4, 4]}>
          <Col xs={24}>
            <Space size={4} direction="vertical" style={{ width: '100%' }}>
              {record && (
                <Row gutter={[4, 4]}>
                  <Col
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    style={{ marginBottom: 8 }}
                  >
                    <Card
                      title={
                        <Text type="secondary">
                          Агентский профиль № {record.uuid}
                        </Text>
                      }
                      bodyStyle={{ padding: 0 }}
                    >
                      <Descriptions
                        title=""
                        bordered
                        column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
                      >
                        <Descriptions.Item label="Тип участника">
                          <TagField value={record.type} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Зарегистрирован как">
                          <TextField value={record.type} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Email">
                          <EmailField value={record.email} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Телефон">
                          <TextField value={record.phone} />
                        </Descriptions.Item>
                      </Descriptions>
                    </Card>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    style={{ marginBottom: 8 }}
                  >
                    <Card
                      title={<Text type="secondary">Банковские реквизиты</Text>}
                      bodyStyle={{ padding: 0 }}
                    >
                      <Table
                        dataSource={dataSourceBank}
                        columns={columns}
                        pagination={false}
                      />
                    </Card>
                  </Col>
                </Row>
              )}
              <Card
                title={<Text type="secondary">{userTypeName}</Text>}
                bodyStyle={{ padding: 0 }}
              >
                <Table
                  dataSource={dataSourceFL}
                  columns={columns}
                  pagination={false}
                />
              </Card>
              <Card
                title={<Text type="secondary">Информация об организации</Text>}
                bodyStyle={{ padding: 0 }}
              >
                <Table
                  dataSource={dataSourceUL}
                  columns={columns}
                  pagination={false}
                />
              </Card>
            </Space>
          </Col>
        </Row>
      ),
    },
    {
      key: '2',
      label: 'Отчеты',
      icon: <InfoCircleOutlined />,
      data: record ? (
        <Space size={12} direction="vertical" style={{ width: '100%' }}>
          <AgentReport
            agentUuid={record.uuid}
            loading={loading}
            setLoading={setLoading}
            refetch={refetch}
            lastNo={lastNo}
          />
          <Card title="Документы" hoverable>
            <Row gutter={[12, 12]}>
              {record.agreements
                .filter(
                  (agreement: IAgreement) =>
                    agreement.type === AgreementType.AKT_REPORT ||
                    agreement.type === AgreementType.AKT_WORK,
                )
                .map((agreement: IAgreement) => {
                  return (
                    <Col xs={8}>
                      <Card
                        hoverable
                        title={agreement.type}
                        size="small"
                        bodyStyle={{ padding: 0 }}
                        style={{ maxWidth: '280px' }}
                        onClick={() => {
                          window.open(
                            process.env.REACT_APP_BACKEND_URL + agreement.pdf,
                            '_blank',
                          );
                        }}
                      >
                        <iframe
                          src={
                            process.env.REACT_APP_BACKEND_URL + agreement.pdf
                          }
                          width="100%"
                          style={{ border: 'none', height: '320px' }}
                          title="PDF Viewer"
                        />
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </Card>
        </Space>
      ) : (
        <></>
      ),
    },
  ];

  return record ? (
    <>
      <Show
        isLoading={isLoading}
        headerProps={{
          extra: (
            <>
              <div className="my-space">
                <CanAccess resource="users" action="edit">
                  <EditButton
                    style={{ marginLeft: 10, marginTop: 5 }}
                    children="Править"
                  />
                </CanAccess>
              </div>
            </>
          ),
        }}
        contentProps={{
          style: {
            background: 'none',
            // padding: '16px',
          },
        }}
      >
        <Spin size="large" spinning={loading}>
          <CanAccess resource="users" action="show">
            <Row gutter={16}>
              <Col
                xs={{ span: 24 }}
                md={{ span: 8 }}
                xl={{ span: 6 }}
                style={{ marginBottom: 8 }}
              >
                <Card>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center ',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: 8,
                      }}
                    >
                      {!avatar && (
                        <Avatar
                          style={{
                            width: '60%',
                            height: 'auto',
                            minWidth: '80px',
                            minHeight: '80px',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          size={64}
                          shape="square"
                          icon={<UserOutlined />}
                        />
                      )}
                      {avatar && (
                        <Avatar
                          style={{ width: '60%', height: 'auto' }}
                          shape="square"
                          icon={<UserOutlined />}
                          src={process.env.REACT_APP_BACKEND_URL + avatar.path}
                        />
                      )}
                    </div>
                    <Text strong style={{ textAlign: 'center' }}>
                      {record?.orgName
                        ? record?.orgName
                        : `${record?.lastName} ${record?.firstName}`}
                    </Text>

                    <Divider orientation="left" plain>
                      <Text type="secondary" style={{ fontSize: 12 }}>
                        Документы агента
                      </Text>
                    </Divider>
                    <div style={{ width: '100%' }}>
                      <PersonalDocuments user={record} refetch={refetch} />
                    </div>
                  </div>
                </Card>
              </Col>
              <Col sm={{ span: 24 }} md={{ span: 16 }} xl={{ span: 18 }}>
                <Card size="small" headStyle={{ height: '0px' }}>
                  <Tabs
                    defaultActiveKey="1"
                    items={tabs.map((item) => {
                      return {
                        key: item.key,
                        label: item.label,
                        children: item.data,
                        icon: item.icon,
                      };
                    })}
                  />
                </Card>
              </Col>
            </Row>
          </CanAccess>
        </Spin>
      </Show>
    </>
  ) : (
    <Spin style={{ padding: 20 }} />
  );
};
