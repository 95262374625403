import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNavigation,
  useNotification,
  useOne,
  useShow,
  useUpdate,
} from '@pankod/refine-core';
import {
  Button,
  Input,
  Popconfirm,
  Select,
  Show,
  Tooltip,
  Typography,
  Upload,
} from '@pankod/refine-antd';
import { IAgreement, IUser } from 'interfaces';
import {
  ArrowDownOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  FormOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState, useContext } from 'react';
import { Role } from '../../enums/role';
import { axiosInstance } from '../../App';
import {
  createDetachedSignature,
  addDetachedSignature,
  createHash,
  getUserCertificates,
  isValidSystemSetup,
} from 'crypto-pro-js';
import { AgreementType } from '../../enums/agreement.type';
import {
  EmailIcon,
  EmailShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  VKIcon,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { InvestmentStatus } from '../../enums/investment.status';
import { UserContext } from 'UserProvider';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { UserStatus } from 'enums/user.status';
import { uuidV4 } from 'utility/uuidv4';
const CADESCOM_CADES_X_LONG_TYPE_1 = 93;
const { Title } = Typography;
interface ICertificate {
  thumbprint: string;
  name: string;
  validTo: string;
}

const AddDocBtn = function (props: {
  uuid: number | undefined;
  refetch: any;
  typeAgreement: AgreementType;
}) {
  type MutationResult = {
    id: number;
    title: string;
  };
  const { open: notify } = useNotification();
  const { mutate } = useUpdate<MutationResult>();
  const [revokeButton, setRevokeButton] = useState<boolean>(false);
  const apiUrl = useApiUrl();
  return (
    <Tooltip title="Загрузить документ">
      <Upload
        name="file"
        action={`${apiUrl}/media/uploadSignDocument`}
        headers={{
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }}
        listType="text"
        maxCount={1}
        multiple
        onChange={() => props.refetch()}
        onRemove={(e) => {
          // console.log(e);
        }}
        data={(e) => {
          const fileUuid = uuidV4();
          return {
            fileUuid,
            uuid: props.uuid,
            type: props.typeAgreement,
            filename: e.name,
          };
        }}
      >
        <Button
          type={'dashed'}
          disabled={revokeButton}
          icon={<FileAddOutlined />}
        >
          Добавить подписанный документ
        </Button>
      </Upload>
    </Tooltip>
  );
};

const ukepDisabled = !(process.env.REACT_APP_UKEP === '1');
export const AgreementShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IAgreement>();
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const { data, isLoading, refetch } = queryResult;
  const record: IAgreement | undefined = data?.data;
  let collateralAccept: IAgreement | undefined, pendingInvestment;
  if (record && me) {
    collateralAccept = record.offer?.agreements.find((agr) => {
      return agr.type === AgreementType.AGENT_JOIN_IP;
    });
  }

  let showInvestorSignature = true;
  if (record?.type === AgreementType.AGENT_JOIN_IP) {
    showInvestorSignature = false;
  }
  return (
    <Show title={'Просмотр документа'} isLoading={isLoading} headerButtons={[]}>
      <Title level={4}>{record?.type}</Title>

      {record && (
        <>
          <object
            style={{ width: '100%', height: '85vh' }}
            type="application/pdf"
            data={
              record.pdfSigned
                ? `${process.env.REACT_APP_BACKEND_URL}/upload/ids/${record.pdfSigned}?#zoom=85&scrollbar=1&toolbar=1&navpanes=1`
                : `${process.env.REACT_APP_BACKEND_URL}${record.pdf}?#zoom=85&scrollbar=1&toolbar=1&navpanes=1`
            }
          ></object>
        </>
      )}
    </Show>
  );
};
