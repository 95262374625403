import {
  CanAccess,
  CrudFilter,
  CrudFilters,
  CrudSort,
  IResourceComponentsProps,
  getDefaultFilter,
  useGetIdentity,
  useNotification,
} from '@pankod/refine-core';
import {
  Alert,
  CreateButton,
  DateField,
  DeleteButton,
  EditButton,
  FilterDropdown,
  getDefaultSortOrder,
  List,
  ShowButton,
  Space,
  Spin,
  Table,
  TextField,
  useTable,
  Select,
  Input,
  FilterDropdownProps,
  NumberField,
  Tabs,
  Tooltip,
  Button,
  Tag,
  Row,
  Col,
} from '@pankod/refine-antd';

import {
  UserOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  EditOutlined,
  CopyOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { IOffer, IUser } from 'interfaces';
import { Role } from '../../enums/role';
import { OfferStatus } from '../../enums/offer.status';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { UserProvider, UserContext } from 'UserProvider';
import { OffersCards } from 'components/offers/offersCards';
import { OfferPageTabs } from 'components/offers/tabs/offerPageTabs';
import { axiosInstance } from 'App';
import { useNavigate } from '@pankod/refine-react-router-v6';
import dateOfCancel from 'utility/cancelOffer';

const { Search } = Input;

function OffersTable(props: {
  filters: CrudFilters;
  status: OfferStatus[];
  searchingInn: string | null;
}) {
  const { tableProps, sorter, setFilters } = useTable<IOffer>({
    initialSorter: [
      {
        field: 'uuid',
        order: 'desc',
      },
    ],
    initialPageSize: 100,
    hasPagination: true,
    initialFilter: props.filters,
  });
  const navigate = useNavigate();
  const { open: notify } = useNotification();
  const duplicate = async (uuid: number) => {
    if (notify) {
      try {
        const response = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/offers/duplicate/',
          { uuid },
        );
        const NewUuid = await response.data.uuid;
        navigate(`/offers/create?offer=${NewUuid}`);
      } catch (error) {
        notify({
          description: 'Ошибка',
          message: 'Возникла проблема , сообщите сис-админу',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (props.status.length !== 0) {
      const statusFilter: CrudFilter = {
        field: 'status',
        operator: 'in',
        value: props.status,
      };
      setFilters([statusFilter]);
    } else {
      setFilters([
        {
          field: 'status',
          operator: 'eq',
          value: '',
        },
      ]);
    }
  }, [props.status]);

  useEffect(() => {
    if (props.searchingInn !== null) {
      const statusFilter: CrudFilter = {
        field: 'innOrg',
        operator: 'eq',
        value: props.searchingInn,
      };
      setFilters([statusFilter]);
    } else {
      setFilters([
        {
          field: 'innOrg',
          operator: 'eq',
          value: '',
        },
      ]);
    }
  }, [props.searchingInn]);

  return (
    <Table {...tableProps} rowKey="uuid">
      <Table.Column
        dataIndex="uuid"
        key="uuid"
        width={50}
        title="№"
        render={(value: IOffer) => (
          // <Tooltip title={'Открыть предложение'}>
          //   <ShowButton size="small" icon={false} recordItemId={record?.uuid}>
          //     <TextField value={record.uuid} />
          <TextField value={value} />
          // </ShowButton>
          // </Tooltip>
        )}
        defaultSortOrder={getDefaultSortOrder('uuid', sorter)}
        sorter
      />
      {/* <Table.Column
        dataIndex="offerNumber"
        key="offerNumber"
        title="Номер"
        render={(_, record: IOffer) => {
          return (
            <Tooltip title={'Открыть предложение'}>
              <ShowButton size="small" icon={false} recordItemId={record?.uuid}>
                <TextField value={record.offerNumber} />
              </ShowButton>
            </Tooltip>
          );
        }}
        defaultSortOrder={getDefaultSortOrder('number', sorter)}
        sorter
        filterDropdown={(props: FilterDropdownProps) => (
          <FilterDropdown {...props}>
            <Input />
          </FilterDropdown>
        )}
      /> */}
      <Table.Column
        dataIndex="nameOrg"
        key="nameOrg"
        title="Заемщик"
        render={(_, record: IOffer) => {
          return (
            <Tooltip title={'Открыть предложение'}>
              <ShowButton
                style={{
                  width: '200px',
                  whiteSpace: 'break-spaces',
                  height: 'auto',
                }}
                size="small"
                icon={false}
                recordItemId={record?.uuid}
              >
                <TextField value={record.nameOrg} />
              </ShowButton>
            </Tooltip>
          );
        }}
        defaultSortOrder={getDefaultSortOrder('nameOrg', sorter)}
        sorter
        // filterDropdown={(props: FilterDropdownProps) => (
        //   <FilterDropdown {...props}>
        //     <Input />
        //   </FilterDropdown>
        // )}
      />
      <Table.Column
        dataIndex="innOrg"
        key="innOrg"
        title="ИНН"
        render={(value) => value}
        defaultSortOrder={getDefaultSortOrder('innOrg', sorter)}
        sorter
        // filterDropdown={(props: FilterDropdownProps) => (
        //   <FilterDropdown {...props}>
        //     <Input />
        //   </FilterDropdown>
        // )}
      />

      <Table.Column
        dataIndex="agent"
        key="agent"
        title="Агент"
        render={(value) => (
          <TextField value={value ? value.orgName : 'Без агента'} />
        )}
        defaultSortOrder={getDefaultSortOrder('orgName', sorter)}
        sorter
      />
      <Table.Column
        dataIndex="purchaseSumm"
        key="purchaseSumm"
        title="Сумма займа"
        render={(value) => (
          <TextField
            value={Number(value).toLocaleString('ru-Ru', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          />
        )}
        defaultSortOrder={getDefaultSortOrder('purchase', sorter)}
        sorter
      />
      <Table.Column
        dataIndex="purchase"
        key="purchase"
        title="Номер закупки"
        render={(value) => value}
        defaultSortOrder={getDefaultSortOrder('purchase', sorter)}
        sorter
      />
      <Table.Column
        dataIndex="dateCancel"
        key="dateCancel"
        title="Дата закрытия"
        render={(_, record: IOffer) => {
          const value = dateOfCancel(record);
          return <TextField value={value} />;
        }}
        defaultSortOrder={getDefaultSortOrder('purchase', sorter)}
        sorter
      />

      <Table.Column
        dataIndex="status"
        key="status"
        title="Статус"
        render={(value: any, record: any) => {
          switch (value) {
            case OfferStatus.INWORK:
              return (
                <Tag
                  style={{
                    wordBreak: 'normal',
                  }}
                  icon={<SyncOutlined spin />}
                  color="#55acee"
                >
                  {value}
                </Tag>
              );
            case OfferStatus.REFUSE:
              return (
                <Tag
                  style={{
                    wordBreak: 'normal',
                    color: '#000',
                  }}
                  icon={<ExclamationCircleOutlined />}
                  color="#ffa900"
                >
                  {value}
                </Tag>
              );
            case OfferStatus.REFUSE_CLIENT:
              return (
                <Tag
                  style={{
                    wordBreak: 'normal',
                  }}
                  icon={<ExclamationCircleOutlined />}
                  color="#e29600"
                >
                  {value}
                </Tag>
              );
            case OfferStatus.SUCCESS:
              return (
                <Tag
                  style={{
                    wordBreak: 'normal',
                  }}
                  icon={<CheckCircleOutlined />}
                  color="#87d068"
                >
                  {value}
                </Tag>
              );
            case OfferStatus.PROLONG:
              return (
                <Tag
                  style={{
                    wordBreak: 'normal',
                  }}
                  icon={<CheckCircleOutlined />}
                  color="#278200"
                >
                  {value}
                </Tag>
              );
            case OfferStatus.VIOLATED:
              return (
                <Tag
                  style={{
                    wordBreak: 'normal',
                  }}
                  icon={<CloseCircleOutlined />}
                  color="#f50"
                >
                  {value}
                </Tag>
              );
            case OfferStatus.INCOURT:
              return (
                <Tag
                  style={{
                    wordBreak: 'normal',
                  }}
                  icon={<CloseCircleOutlined />}
                  color="#ff0000"
                >
                  {value}
                </Tag>
              );
            default:
              return (
                <Tag
                  icon={<CheckCircleOutlined />}
                  color="#989898"
                  style={{
                    wordBreak: 'normal',
                  }}
                >
                  {value}
                </Tag>
              );
          }
        }}
        defaultSortOrder={getDefaultSortOrder('status', sorter)}
        sorter
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Select
              style={{ minWidth: 200 }}
              mode="multiple"
              placeholder="Выберите статусы"
              options={Object.values(OfferStatus).map((status) => ({
                label: status,
                value: status,
              }))}
            />
          </FilterDropdown>
        )}
      />
      <Table.Column<IOffer>
        title="Действия"
        dataIndex="actions"
        render={(_, record) => (
          <Space wrap>
            <Tooltip title="Редактировать заявку">
              <Button
                icon={<EditOutlined />}
                href={`/offers/create?offer=${record.uuid}`}
                size="small"
                type="primary"
              />
            </Tooltip>
            <Tooltip title="Создать копию из этой заявки">
              <Button
                icon={<CopyOutlined />}
                onClick={() => duplicate(record.uuid)}
                size="small"
                type="primary"
              />
            </Tooltip>
            <DeleteButton
              hideText
              size="small"
              recordItemId={record.uuid}
              type="primary"
            />
          </Space>
        )}
      />
    </Table>
  );
}
export const OfferList: React.FC<IResourceComponentsProps> = () => {
  const [filter, setFilter] = useState<OfferStatus[]>([]);
  const [searchingInn, setSearchingInn] = useState<string | null>(null);
  const filters: CrudFilters = [];

  const btns = [
    { color: '#55acee', icon: <SyncOutlined spin />, type: OfferStatus.INWORK },
    {
      color: '#87d068',
      icon: <CheckCircleOutlined />,
      type: OfferStatus.SUCCESS,
    },
    {
      color: '#278200',
      icon: <CheckCircleOutlined />,
      type: OfferStatus.PROLONG,
    },
    {
      color: '#ffa900',
      icon: <ExclamationCircleOutlined />,
      type: OfferStatus.REFUSE,
    },
    {
      color: '#e29600',
      icon: <ExclamationCircleOutlined />,
      type: OfferStatus.REFUSE_CLIENT,
    },
    {
      color: '#f50',
      icon: <CloseCircleOutlined />,
      type: OfferStatus.VIOLATED,
    },
    {
      color: '#ff0000',
      icon: <CloseCircleOutlined />,
      type: OfferStatus.INCOURT,
    },
    {
      color: '#989898',
      icon: <CheckCircleOutlined />,
      type: OfferStatus.CANCELED,
    },
  ];

  function toggleItem<T>(array: T[], item: T): T[] {
    const index = array.indexOf(item);
    if (index === -1) {
      return [...array, item];
    } else {
      return array.filter((_, i) => i !== index);
    }
  }

  const filterBtns = btns.map((btn) => (
    <Tag
      style={{
        wordBreak: 'normal',
        cursor: 'pointer',
        opacity: filter.length === 0 || filter.includes(btn.type) ? 1 : 0.1,
      }}
      onClick={() => {
        setFilter(toggleItem(filter, btn.type));
      }}
      icon={btn.icon}
      color={btn.color}
    >
      {btn.type}
    </Tag>
  ));

  return (
    <List
      headerButtons={
        <CanAccess resource="offers" action="create">
          <Space direction="vertical">
            <CreateButton
              style={{ marginTop: 10, float: 'right' }}
              type={'primary'}
              children="Создать заявку"
            />
            <div style={{ float: 'right' }}>
              <Search
                allowClear
                placeholder="Введите ИНН для поиска"
                style={{ width: '250px' }}
                onSearch={(value) => setSearchingInn(value)}
              />
            </div>
          </Space>
        </CanAccess>
      }
      title={
        <div style={{ width: '100%' }}>
          <Space size={12} style={{ width: '100%' }} direction="vertical">
            <span style={{ margin: '0px 0 0 4px' }}>База заявок</span>
            <Row gutter={[4, 4]}>
              {filterBtns.map((btn) => (
                <Col>{btn}</Col>
              ))}
            </Row>
          </Space>
        </div>
      }
    >
      <OffersTable
        filters={filters}
        status={filter}
        searchingInn={searchingInn}
      />
    </List>
  );
};
