import { IOffer, IPayment } from '../../interfaces';
import {
  Descriptions,
  Divider,
  NumberField,
  Popconfirm,
  Progress,
  Rate,
  Table,
  Tooltip,
  Space,
  Typography,
} from '@pankod/refine-antd';
import React from 'react';
import { InvestmentStatus } from '../../enums/investment.status';
import { OfferStatus } from '../../enums/offer.status';
import { Badge } from 'antd';
import { FileProtectOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useList } from '@pankod/refine-core';
import { ChartOffer } from './chartOffer';
import { PaymentType } from 'enums/payment.type';

const { Text, Title } = Typography;
export function InvestmentsBlock(props: { record: IOffer }) {
  const { data } = useList<IPayment>({
    resource: 'payments',
    config: {
      hasPagination: true,
      pagination: {
        current: 1,
        pageSize: 100,
      },
      filters: [
        {
          field: 'offer.uuid',
          operator: 'eq',
          value: props.record.uuid,
        },
      ],
    },
  });
  const payments = data?.data;
  const init = {
    toReturn: 1,
    returned: 0,
    remains: 0,
  };
  let accumulator;
  if (payments) {
    accumulator = payments.reduce((acc, payment) => {
      if (payment.completed && payment.type === PaymentType.BODY) {
        acc.returned += +payment.sum;
      }
      acc.remains = +acc.toReturn - +acc.returned;
      return acc;
    }, init);
  }
  const columnsInv = [
    {
      title: 'Инвестор (ИНН, наименование)',
      dataIndex: 'investor',
      key: 'investor',
    },
    {
      title: 'Сумма, руб',
      dataIndex: 'sum',
      key: 'sum',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Подпись',
      dataIndex: 'commonSignature',
      key: 'commonSignature',
    },
  ];
  function download(signature: string | undefined) {
    if (signature) {
      const file = new Blob([signature]);
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = 'investor.sig';
      link.click();
    }
  }

  const cancel = (e: React.MouseEvent<HTMLElement> | undefined) => {
    console.log();
  };
  return accumulator ? (
    <>
      <Space
        direction="vertical"
        style={{ display: 'flex', width: '100%' }}
        size={32}
      >
        <Descriptions
          title=""
          size="small"
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
        >
          <Descriptions.Item
            label={
              <Tooltip title="При сборе этой суммы, оферта будет исполнена и инвестиции состоятся, даже если максимальная сумма не будет собрана до истечения срока оферты">
                <span>
                  Минимальная сумма <QuestionCircleOutlined />
                </span>
              </Tooltip>
            }
          >
            <Progress
              type="circle"
              percent={0}
              width={40}
              style={{ marginRight: 13 }}
            />
            <NumberField
              options={{
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }}
              style={{ fontSize: 20, position: 'relative', top: 5 }}
              value={1}
            />{' '}
            ₽
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Tooltip title="При сборе этой суммы, оферта считается исполненной и снимается с публикации">
                <span>
                  Максимальная сумма <QuestionCircleOutlined />
                </span>
              </Tooltip>
            }
          >
            <Progress
              type="circle"
              percent={0}
              width={40}
              style={{ marginRight: 13 }}
            />
            <NumberField
              options={{
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }}
              style={{ fontSize: 20, position: 'relative', top: 5 }}
              value={1}
            />{' '}
            ₽
          </Descriptions.Item>
          <Descriptions.Item label="Итого собрано средств">
            {
              <NumberField
                options={{
                  style: 'decimal',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }}
                value={1}
              />
            }{' '}
            ₽
          </Descriptions.Item>
          <Descriptions.Item label="К возвращению" span={2}>
            {
              <NumberField
                options={{
                  style: 'decimal',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }}
                value={accumulator.remains >= 0 ? accumulator.remains : 0}
              />
            }{' '}
            ₽
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <span style={{ color: accumulator.remains < 0 ? 'red' : '#000' }}>
                {accumulator.remains < 0 ? 'Оплачено + просрочка' : 'Оплачено'}
              </span>
            }
          >
            <Space direction="vertical">
              <div>
                <NumberField
                  options={{
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }}
                  value={accumulator.returned}
                />{' '}
                ₽
              </div>
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label={<span>% возврата средств</span>}>
            <Progress
              type="circle"
              percent={Math.round(
                (accumulator.returned / accumulator.toReturn) * 100,
              )}
              width={50}
            />
          </Descriptions.Item>
        </Descriptions>
      </Space>
    </>
  ) : null;
}
