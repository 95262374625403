import React, { useState, useContext } from 'react';
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNotification,
} from '@pankod/refine-core';
import {
  Alert,
  AutoComplete,
  Create,
  Divider,
  Form,
  Input,
  Select,
  Typography,
  Upload,
  UploadFile,
  useFileUploadState,
  useForm,
  Row,
  Col,
  Button,
} from '@pankod/refine-antd';
import { ArrowDownOutlined } from '@ant-design/icons';

import 'react-mde/lib/styles/css/react-mde-all.css';
import { IPicture, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import { UserType } from '../../enums/user.type';
import { axiosInstance } from '../../App';
import moment from 'moment';
import { FormRule, InputProps, InputRef } from 'antd';
import { Role } from '../../enums/role';
import InputMask from 'react-input-mask';
import { normalizeFile } from '../../utility/normalize';
import { DocumentType } from '../../enums/document.type';
import { dateStandartFormat } from 'utility/dateStandartFormat';
import dadataAddress from 'utility/dadata/address';
import { UserContext } from 'UserProvider';
import { uuidV4 } from 'utility/uuidv4';
const { Text } = Typography;
export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, form } = useForm<IUser>({
    successNotification: {
      description: 'Пользователь создан',
      message: 'Просматривайте пользователей в разделе Пользователи',
      type: 'success',
    },
    errorNotification: {
      description: 'Пользователь не создан',
      message: 'Email или телефон уже заняты',
      type: 'error',
    },
  });
  const [files, setFiles] = useState<string[]>([]);
  // const { data: me, refetch } = useGetIdentity<IUser>();
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  console.log('users create.tsx', me?.uuid);
  const { open: notify } = useNotification();
  const { onChange } = useFileUploadState();
  const { onChange: onChange2 } = useFileUploadState();
  const { onChange: onChange3 } = useFileUploadState();
  const { onChange: onChange4 } = useFileUploadState();
  const { onChange: onChange5 } = useFileUploadState();
  const { onChange: onChange6 } = useFileUploadState();
  const apiUrl = useApiUrl();
  const [userType, setUserType] = useState<UserType>();
  const [role, setRole] = useState<Role>();
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );
  const handleSearch = async (value: string) => {
    let res: { value: string; label: string }[] = [];
    const dadataSugs = await dadataAddress(value);
    res = dadataSugs.map((s: any) => {
      return {
        value: s.unrestricted_value,
        label: s.unrestricted_value,
        disabled: !s.data.house,
      };
    });
    setOptions(res);
  };
  const [cantEdit, setCantEdit] = useState<boolean>(false);
  const [hideIp, setHideIp] = useState<boolean>(true);
  //
  const dadataBank = (event: any) => {
    if (event.target.value.length >= 9) {
      axiosInstance
        .get(
          process.env.REACT_APP_BACKEND_URL +
            '/api/v1/dadata/bank/' +
            event.target.value,
        )
        .then((res) => {
          form.setFieldsValue({
            bankName: res.data.suggestions[0].data.name.short,
            bankCorNumber: res.data.suggestions[0].data.correspondent_account,
          });
        });
    }
  };
  const datataOrg = (inn: string) => {
    function getFounders(arrFounders: any) {
      let foundersString = '';
      arrFounders.forEach((el: any, id: number) => {
        foundersString += `${el.fio.source} ${el.share.value}%`;
        if (id !== arrFounders.length - 1) {
          foundersString += ' ,';
        }
      });
      return foundersString;
    }
    if (inn.length === 10) {
      setUserType(UserType.UL);

      axiosInstance
        .get(process.env.REACT_APP_BACKEND_URL + '/api/v1/dadata/' + inn)
        .then((res) => {
          setHideIp(true);
          form.setFieldsValue({
            type: UserType.UL,
          });
          form.setFieldsValue({
            organisationInn: inn,
          });
          form.setFieldsValue({
            managerPosition: res.data.suggestions[0].data.managers[0].post,
          });
          form.setFieldsValue({
            orgFullName: res.data.suggestions[0].data.name.full_with_opf,
          });
          form.setFieldsValue({
            orgName: res.data.suggestions[0].data.name.short_with_opf,
          });
          form.setFieldsValue({
            revenue: +res.data.suggestions[0].data.finance.income,
          });
          form.setFieldsValue({
            income:
              +res.data.suggestions[0].data.finance.income -
              res.data.suggestions[0].data.finance.expense,
          });
          form.setFieldsValue({
            infoDebt: res.data.suggestions[0].data.finance.debt,
          });
          form.setFieldsValue({
            infoFounders: JSON.stringify(res.data.suggestions[0].data.founders),
          });
          form.setFieldsValue({
            regDate: dateStandartFormat(
              res.data.suggestions[0].data.state.registration_date,
            ),
          });
          form.setFieldsValue({
            registrDate: dateStandartFormat(
              new Date(res.data.suggestions[0].data.ogrn_date),
            ),
          });
          form.setFieldsValue({
            infoTax: res.data.suggestions[0].data.finance.tax_system,
          });
          form.setFieldsValue({
            inn: res.data.suggestions[0].data.managers[0].inn,
          });
          form.setFieldsValue({
            firstName: res.data.suggestions[0].data.managers[0].fio.name,
          });

          form.setFieldsValue({
            middleName: res.data.suggestions[0].data.managers[0].fio.patronymic,
          });
          form.setFieldsValue({
            lastName: res.data.suggestions[0].data.managers[0].fio.surname,
          });
          form.setFieldsValue({
            infoActivities: res.data.suggestions[0].data.okveds[0].name,
          });
          form.setFieldsValue({ orgOgrn: res.data.suggestions[0].data.ogrn });
          form.setFieldsValue({ kpp: res.data.suggestions[0].data.kpp });
          form.setFieldsValue({ orgName: res.data.suggestions[0].value });
          form.setFieldsValue({
            orgLocation: res.data.suggestions[0].data.address.value,
          });
          form.setFieldsValue({
            ipRegDate: moment(res.data.suggestions[0].data.ogrn_date).format(
              'DD.MM.YYYY',
            ),
          });
          form.setFieldsValue({
            capital: res.data.suggestions[0].data.capital.value,
          });
          form.setFieldsValue({
            infoStructure: res.data.suggestions[0].data.management.name,
          });

          form.setFieldsValue({
            infoPercent: getFounders(res.data.suggestions[0].data.founders),
          });

          setCantEdit(true);
        });
    }
  };
  const dadataPersonal = (inn: string) => {
    if (inn.length >= 12) {
      setUserType(UserType.IP);
      setHideIp(false);

      axiosInstance
        .get(process.env.REACT_APP_BACKEND_URL + '/api/v1/dadata/' + inn)
        .then((res) => {
          form.setFieldsValue({
            type: UserType.IP,
          });
          form.setFieldsValue({
            organisationInn: inn,
          });
          form.setFieldsValue({
            managerPosition: res.data.suggestions[0].data.opf.full,
          });
          form.setFieldsValue({
            orgFullName: res.data.suggestions[0].data.name.full_with_opf,
          });
          form.setFieldsValue({
            ipRegDate: moment(res.data.suggestions[0].data.ogrn_date).format(
              'DD.MM.YYYY',
            ),
          });

          form.setFieldsValue({ orgOgrn: res.data.suggestions[0].data.ogrn });
          form.setFieldsValue({ kpp: res.data.suggestions[0].data.kpp });
          form.setFieldsValue({ orgName: res.data.suggestions[0].value });
          form.setFieldsValue({
            orgLocation: res.data.suggestions[0].data.address.value,
          });
          form.setFieldsValue({
            firstName: res.data.suggestions[0].data.fio.name,
          });
          form.setFieldsValue({
            middleName: res.data.suggestions[0].data.fio.patronymic,
          });
          form.setFieldsValue({
            lastName: res.data.suggestions[0].data.fio.surname,
          });
          form.setFieldsValue({
            infoActivities: res.data.suggestions[0].data.okveds[0].name,
          });
          form.setFieldsValue({
            registrDate: dateStandartFormat(
              res.data.suggestions[0].data.state.registration_date,
            ),
          });
          form.setFieldsValue({
            ipRegDate: dateStandartFormat(
              res.data.suggestions[0].data.state.registration_date,
            ),
          });
          form.setFieldsValue({
            inn: inn,
          });
          form.setFieldsValue({
            infoStructure: res.data.suggestions[0].data.name.full,
          });
          form.setFieldsValue({
            infoPercent: res.data.suggestions[0].data.name.full,
          });
          console.log(res.data.suggestions[0].data.state.registration_date);
        });
    }
  };

  const checkDatata = (event: any) => {
    const inn = event.target.value;

    if (inn.length === 10) {
      datataOrg(inn);
    }

    if (inn.length >= 12) {
      dadataPersonal(inn);
    }
  };

  const removeFile = async (file: any) => {
    let result;
    if (notify) {
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/media/remove',
          { file },
        );
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: '',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  let publicFileList: UploadFile<any>[] | undefined,
    publicFileList2: UploadFile<any>[] | undefined,
    publicFileList3: UploadFile<any>[] | undefined,
    publicFileList4: UploadFile<any>[] | undefined,
    publicFileList5: UploadFile<any>[] | undefined,
    publicFileList6: UploadFile<any>[] | undefined;
  const onType = (type: UserType) => {
    setUserType(type);
  };
  const onRole = (roles: Role) => {
    setRole(roles);
  };
  const innRule: FormRule = {
    type: 'string',
    min: 12,
    max: 12,
    message: 'ИНН физлица содержит 12 цифр',
  };
  const orgInnRule: FormRule = {
    type: 'string',
    min: 10,
    max: 12,
    message: 'ИНН юрлица содержит 10 цифр',
  };
  const orgOgrnRule: FormRule = {
    type: 'string',
    min: 13,
    max: 13,
    message: 'ОГРН юрлица содержит 13 цифр',
  };
  const ogrnipRule: FormRule = {
    type: 'string',
    min: 15,
    max: 15,
    message: 'ОГРНИП содержит 15 цифр',
  };

  const onInfoSubmit = async (values: IUser) => {
    let result;
    if (!me) return;

    if (notify) {
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/users/saveNewUser',
          { phone: me.phone, ...values },
        );
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: 'Убедитесь что данные заполнены правильно',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };

  return (
    <Create
      headerProps={{
        subTitle: '',
      }}
      title="Создать пользователя"
      saveButtonProps={{ ...saveButtonProps, children: 'Сохранить' }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Электронная почта"
          name="email"
          hasFeedback
          rules={[
            {
              required: false,
            },
            {
              type: 'email',
              message: 'Пожалуйста, введите Ваш email',
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* пароль */}
        <Form.Item
          name="password"
          label="Придумайте пароль"
          rules={[
            {
              required: true,
              min: 6,
              max: 20,
              message: 'Введите пароль (от 6 до 20 символов)',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Повторите ввод пароля"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Пожалуйста, повторите ввод пароля',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Введенные пароли не совпадают'),
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        {/* телефон */}
        <Form.Item
          label="Номер мобильного телефона"
          name="phone"
          hasFeedback
          // validateTrigger='onSubmit'
          rules={[
            {
              validator: (_, value) => {
                // if (value.indexOf('8') === 3) {
                //   return Promise.reject(
                //     new Error('Мобильный номер не может начинаться с 8')
                //   );
                // } else {
                //   return Promise.resolve();
                // }
                if (value[3] === '9') {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    new Error('Мобильный номер РФ должен начинаться с 9'),
                  );
                }
              },
            },
            {
              validator: (_, value) => {
                if (
                  ((+value[3] >= 1 && +value[3] <= 9) || value[3] === '0') &&
                  ((+value[4] >= 1 && +value[4] <= 9) || value[4] === '0') &&
                  ((+value[5] >= 1 && +value[5] <= 9) || value[5] === '0') &&
                  ((+value[7] >= 1 && +value[7] <= 9) || value[7] === '0') &&
                  ((+value[8] >= 1 && +value[8] <= 9) || value[8] === '0') &&
                  ((+value[9] >= 1 && +value[9] <= 9) || value[9] === '0') &&
                  ((+value[11] >= 1 && +value[11] <= 9) || value[11] === '0') &&
                  ((+value[12] >= 1 && +value[12] <= 9) || value[12] === '0') &&
                  ((+value[14] >= 1 && +value[14] <= 9) || value[14] === '0') &&
                  ((+value[15] >= 1 && +value[15] <= 9) || value[15] === '0')
                ) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    new Error('Пожалуйста, введите номер мобильного'),
                  );
                }
              },
            },
          ]}
        >
          {/*<Input />*/}
          <InputMask
            placeholder={'+7(___)-___-__-__'}
            mask="+7(999)999-99-99"
            // maskChar=' '
          >
            {(
              inputProps: JSX.IntrinsicAttributes &
                InputProps &
                React.RefAttributes<InputRef>,
            ) => <Input {...inputProps} />}
          </InputMask>
        </Form.Item>
        {/* Тип участника */}
        <Form.Item
          label="Тип участника платформы"
          name="role"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            onChange={onRole}
            options={[
              {
                label: Role.INVESTOR,
                value: Role.INVESTOR,
              },
              {
                label: Role.LENDER,
                value: Role.LENDER,
              },
              {
                label: Role.DUPLEX,
                value: Role.DUPLEX,
              },
            ]}
          />
        </Form.Item>
        {role && (
          <>
            {/* инн */}
            <Form.Item
              label="ИНН"
              name="orgInn"
              hasFeedback
              rules={[
                {
                  required: true,
                },
                orgInnRule,
              ]}
            >
              <Input onInput={checkDatata} maxLength={orgInnRule.max} />
            </Form.Item>
            <Form.Item
              label="Полное наименование"
              name="orgFullName"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input allowClear readOnly={cantEdit} />
            </Form.Item>
            <Form.Item
              label="Сокращенное наименование"
              name="orgName"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input allowClear readOnly={cantEdit} />
            </Form.Item>
            <Form.Item
              label="Организационно-правовая форма"
              name="type"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                disabled={cantEdit}
                onChange={onType}
                options={[
                  {
                    label: UserType.IP,
                    value: UserType.IP,
                  },
                  {
                    label: UserType.UL,
                    value: UserType.UL,
                  },
                ]}
              />
            </Form.Item>
            {/* ОГРН ИНН и тд */}
            <Row gutter={8}>
              <Col>
                <Form.Item
                  label="ОГРН"
                  name="orgOgrn"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input allowClear readOnly={cantEdit} />
                </Form.Item>
              </Col>
              {hideIp && (
                <>
                  <Col>
                    <Form.Item
                      label="КПП"
                      name="kpp"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input allowClear readOnly={cantEdit} />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col>
                <Form.Item
                  label="ИНН"
                  name="organisationInn"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input readOnly={cantEdit} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Дата регистрации"
                  name="registrDate"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input readOnly={cantEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Адрес регистрации (город, улица, дом)"
              name="orgLocation"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <AutoComplete
                disabled={cantEdit}
                onSearch={handleSearch}
                placeholder=""
                options={options}
              />
            </Form.Item>
            <Form.Item label="Адрес фактического местонахождения (город, улица, дом)">
              <div style={{ display: 'flex' }}>
                <Button
                  size="middle"
                  style={{ marginRight: 10 }}
                  icon={<ArrowDownOutlined />}
                  onClick={() => {
                    form.setFieldsValue({
                      orgFactLocation: form.getFieldValue('orgLocation'),
                    });
                  }}
                >
                  Совпадает
                </Button>
                <Form.Item
                  style={{ display: 'inline-block', width: '100%' }}
                  name="orgFactLocation"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <AutoComplete
                    size={'middle'}
                    onSearch={handleSearch}
                    placeholder=""
                    options={options}
                  />
                </Form.Item>
              </div>
            </Form.Item>
            {hideIp ? (
              <Row>
                <Col>
                  <Form.Item
                    label="Уставной капитал (₽)"
                    name="capital"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input readOnly={cantEdit} />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Form.Item
                label="Регистрация ИП"
                name="ipRegDate"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input readOnly={cantEdit} />
              </Form.Item>
            )}
            <Divider orientation="left" plain>
              <Text type="secondary">Банковские реквизиты</Text>
            </Divider>
            <Form.Item
              label="БИК"
              name="bik"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input onInput={dadataBank} />
            </Form.Item>
            <Form.Item
              label="Расчетный счет"
              name="bankAccNumber"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Наименование банка"
              name="bankName"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Корр. счет"
              name="bankCorNumber"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            {role === Role.LENDER && (
              <>
                <Divider orientation="left" plain>
                  <Text type="secondary">
                    Информация о лице, привлекающем инвестиции
                  </Text>
                </Divider>
                <Form.Item
                  label="Должность руководителя"
                  name="managerPosition"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Описание деятельности заемщика (будет доступно инвестору)"
                  name="infoDescription"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea showCount maxLength={3000} rows={5} />
                </Form.Item>
                <Form.Item
                  label="Сведения о лицах, имеющих право распоряжаться не менее чем 10 процентами голосов в высшем органе управления юридического лица, если таким лицом является корпорация"
                  name="infoPercent"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Сведения о структуре и персональном составе органов управления юридического лица"
                  name="infoStructure"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Основные виды деятельности лица, привлекающего инвестиции"
                  name="infoActivities"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Сведения о фактах (событиях, действиях), которые могут оказать существенное влияние на исполнение лицом, привлекающим инвестиции, обязательств перед инвесторами"
                  name="infoFacts"
                  hasFeedback
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input defaultValue={'Отсутствуют'} />
                </Form.Item>
                <Form.Item
                  label="Сведения о суммах инвестиций, привлеченных лицом, привлекающим инвестиции, в инвестиционной платформе в текущем календарном году, а также о максимальном объеме денежных средств, указанном в каждом действующем инвестиционном предложении в этой инвестиционной платформе, по достижении которого инвестиционное предложение прекращается"
                  name="infoSums"
                  hasFeedback
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input defaultValue={'0 рублей'} />
                </Form.Item>
                <Form.Item
                  label="Лицензия на осуществление деятельности (в случае лицензирования)"
                  name="licenseInfo"
                  hasFeedback
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input defaultValue={'Отсутствуют'} />
                </Form.Item>
                <Form.Item
                  label="Информация, позволяющая составить общее представление о целях привлечения инвестиций и об обстоятельствах, которые могут оказать влияние на достижение указанных целей"
                  name="infoPurpose"
                  hasFeedback
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input defaultValue={'Пополнение оборотных средств'} />
                </Form.Item>
                <Form.Item
                  label="Предупреждение о рисках, связанных с лицом, привлекающим инвестиции, и рисках, связанных с принятием инвестиционного предложения"
                  name="infoRisks"
                  hasFeedback
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input defaultValue={'Предупрежден'} />
                </Form.Item>
                <Form.Item
                  label="Сведения об экспертах, привлеченных для мониторинга и оценки деятельности Лица, привлекающего инвестиции (при наличии)"
                  name="infoExperts"
                  hasFeedback
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input defaultValue={'Отсутствуют'} />
                </Form.Item>
              </>
            )}

            {userType === UserType.UL && (
              <Divider orientation="left" plain>
                <Text type="secondary">Единоличный исполнительный орган</Text>
              </Divider>
            )}
            {userType === UserType.IP && (
              <Divider orientation="left" plain>
                <Text type="secondary">Индивидуальный предприниматель</Text>
              </Divider>
            )}
            <Form.Item
              label="Фамилия"
              name="lastName"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input readOnly={cantEdit} />
            </Form.Item>
            <Form.Item
              label="Имя"
              name="firstName"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input readOnly={cantEdit} />
            </Form.Item>
            <Form.Item
              label="Отчество"
              name="middleName"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input readOnly={cantEdit} />
            </Form.Item>
            <Form.Item
              label="ИНН"
              name="inn"
              hasFeedback
              rules={[
                {
                  required: true,
                },
                innRule,
              ]}
            >
              <Input readOnly={cantEdit} maxLength={innRule.max} />
            </Form.Item>
            <Form.Item
              label="Дата рождения"
              name="dateOfBirth"
              hasFeedback
              rules={[
                {
                  required: true,
                },
                {
                  validator: (_, value) => {
                    const date = moment(value, 'DD-MM-YYYY').toDate();
                    date.setFullYear(date.getFullYear() + 18);
                    const today = new Date();
                    if (date > today) {
                      return Promise.reject(
                        new Error('Регистрация возможна с 18 лет'),
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              {/*<DatePicker*/}
              {/*  allowClear*/}
              {/*  format={(date: Moment) => {*/}
              {/*    return moment(date).utc().format('DD.MM.YYYY');*/}
              {/*  }}*/}
              {/*  locale={locale}*/}
              {/*/>*/}
              <InputMask
                placeholder={''}
                mask="99.99.9999"
                // maskChar=' '
              >
                {(
                  inputProps: JSX.IntrinsicAttributes &
                    InputProps &
                    React.RefAttributes<InputRef>,
                ) => <Input allowClear {...inputProps} />}
              </InputMask>
            </Form.Item>
            <Form.Item
              label="Место рождения"
              name="placeOfBirth"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Адрес регистрации (город, улица, дом)"
              name="address"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <AutoComplete
                onSearch={handleSearch}
                placeholder=""
                options={options}
              />
            </Form.Item>
            <Form.Item label="Адрес фактического местонахождения (город, улица, дом)">
              <div style={{ display: 'flex' }}>
                <Button
                  size="middle"
                  style={{ marginRight: 10 }}
                  icon={<ArrowDownOutlined />}
                  onClick={() => {
                    form.setFieldsValue({
                      factAddress: form.getFieldValue('address'),
                    });
                  }}
                >
                  Совпадает
                </Button>
                <Form.Item
                  style={{ display: 'inline-block', width: '100%' }}
                  name="factAddress"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <AutoComplete
                    size={'middle'}
                    onSearch={handleSearch}
                    placeholder=""
                    options={options}
                  />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item
              label="Серия и № паспорта"
              name="passportNumber"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputMask
                placeholder={''}
                mask="серия: 9999, № 999999"
                // maskChar=' '
              >
                {(
                  inputProps: JSX.IntrinsicAttributes &
                    InputProps &
                    React.RefAttributes<InputRef>,
                ) => <Input allowClear {...inputProps} />}
              </InputMask>
            </Form.Item>
            <Form.Item
              label="Дата выдачи"
              name="issueDate"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputMask
                placeholder={''}
                mask="99.99.9999"
                // maskChar=' '
              >
                {(
                  inputProps: JSX.IntrinsicAttributes &
                    InputProps &
                    React.RefAttributes<InputRef>,
                ) => <Input allowClear {...inputProps} />}
              </InputMask>
            </Form.Item>
            <Form.Item
              label="Кем выдан"
              name="passportIssuedBy"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Код подразделения"
              name="passportCodeDepartment"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputMask
                placeholder={''}
                mask="999-999"
                // maskChar=' '
              >
                {(
                  inputProps: JSX.IntrinsicAttributes &
                    InputProps &
                    React.RefAttributes<InputRef>,
                ) => <Input allowClear {...inputProps} />}
              </InputMask>
            </Form.Item>
            {userType === UserType.IP && (
              <>
                <Form.Item
                  label="Наименование организации"
                  name="orgName"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
            <Alert
              showIcon
              message={'Необходимо приложить документы'}
              description={
                <>
                  Принимаются файлы формата png, jpg, gif и pdf, размером до 15
                  Мб. На каждый вид документа можно загрузить несколько файлов.
                  <br />
                </>
              }
              type="info"
            />
            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
              }}
            >
              <Form.Item
                name="images"
                valuePropName="fileList"
                normalize={normalizeFile}
                style={{ width: 200, marginRight: 10 }}
              >
                <Upload.Dragger
                  name="file"
                  action={`${apiUrl}/media/agentUpload`}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  }}
                  listType="picture"
                  maxCount={20}
                  multiple
                  onChange={onChange}
                  onRemove={removeFile}
                  defaultFileList={publicFileList}
                  data={() => {
                    const fileUuid = uuidV4();
                    files.push(fileUuid);
                    form?.setFieldsValue({
                      files,
                    });
                    return { fileUuid, type: DocumentType.DIRECTOR_PASSPORT };
                  }}
                >
                  <p style={{ fontSize: 13, padding: '8px 8px 0 8px' }}>
                    {DocumentType.DIRECTOR_PASSPORT}
                  </p>
                </Upload.Dragger>
              </Form.Item>
              <Form.Item
                name="images2"
                valuePropName="fileList2"
                normalize={normalizeFile}
                style={{ width: 200, marginRight: 10 }}
              >
                <Upload.Dragger
                  name="file"
                  action={`${apiUrl}/media/upload`}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  }}
                  listType="picture"
                  maxCount={20}
                  multiple
                  onChange={onChange2}
                  onRemove={removeFile}
                  defaultFileList={publicFileList2}
                  data={() => {
                    const fileUuid = uuidV4();
                    files.push(fileUuid);
                    form?.setFieldsValue({
                      files,
                    });
                    return { fileUuid, type: DocumentType.OWNER_PASSPORT };
                  }}
                >
                  <p style={{ fontSize: 13, padding: '10px 10px 0 10px' }}>
                    {DocumentType.OWNER_PASSPORT}
                  </p>
                </Upload.Dragger>
              </Form.Item>
              <Form.Item
                name="images3"
                valuePropName="fileList3"
                normalize={normalizeFile}
                style={{ width: 200, marginRight: 10 }}
              >
                <Upload.Dragger
                  name="file"
                  action={`${apiUrl}/media/upload`}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  }}
                  listType="picture"
                  maxCount={20}
                  multiple
                  onChange={onChange3}
                  onRemove={removeFile}
                  defaultFileList={publicFileList3}
                  data={() => {
                    const fileUuid = uuidV4();
                    files.push(fileUuid);
                    form?.setFieldsValue({
                      files,
                    });
                    return { fileUuid, type: DocumentType.REPORTS_1 };
                  }}
                >
                  <p style={{ fontSize: 13, padding: '10px 10px 0 10px' }}>
                    {DocumentType.REPORTS_1}
                  </p>
                </Upload.Dragger>
              </Form.Item>
              <Form.Item
                name="images4"
                valuePropName="fileList4"
                normalize={normalizeFile}
                style={{ width: 200, marginRight: 10 }}
              >
                <Upload.Dragger
                  name="file"
                  action={`${apiUrl}/media/upload`}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  }}
                  listType="picture"
                  maxCount={20}
                  multiple
                  onChange={onChange4}
                  onRemove={removeFile}
                  defaultFileList={publicFileList4}
                  data={() => {
                    const fileUuid = uuidV4();
                    files.push(fileUuid);
                    form?.setFieldsValue({
                      files,
                    });
                    return { fileUuid, type: DocumentType.REPORTS_2 };
                  }}
                >
                  <p style={{ fontSize: 13, padding: '10px 10px 0 10px' }}>
                    {DocumentType.REPORTS_2}
                  </p>
                </Upload.Dragger>
              </Form.Item>
              <Form.Item
                name="images5"
                valuePropName="fileList5"
                normalize={normalizeFile}
                style={{ width: 200, marginRight: 10 }}
              >
                <Upload.Dragger
                  name="file"
                  action={`${apiUrl}/media/upload`}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  }}
                  listType="picture"
                  maxCount={20}
                  multiple
                  onChange={onChange5}
                  onRemove={removeFile}
                  defaultFileList={publicFileList5}
                  data={() => {
                    const fileUuid = uuidV4();
                    files.push(fileUuid);
                    form?.setFieldsValue({
                      files,
                    });
                    return { fileUuid, type: DocumentType.REPORTS_3 };
                  }}
                >
                  <p style={{ fontSize: 13, padding: '10px 10px 0 10px' }}>
                    {DocumentType.REPORTS_3}
                  </p>
                </Upload.Dragger>
              </Form.Item>
              <Form.Item
                name="images6"
                valuePropName="fileList6"
                normalize={normalizeFile}
                style={{ width: 200 }}
              >
                <Upload.Dragger
                  name="file"
                  action={`${apiUrl}/media/upload`}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  }}
                  listType="picture"
                  maxCount={20}
                  multiple
                  onChange={onChange6}
                  onRemove={removeFile}
                  defaultFileList={publicFileList6}
                  data={() => {
                    const fileUuid = uuidV4();
                    files.push(fileUuid);
                    form?.setFieldsValue({
                      files,
                    });
                    return { fileUuid, type: DocumentType.REPORTS_4 };
                  }}
                >
                  <p style={{ fontSize: 13, padding: '10px 10px 0 10px' }}>
                    {DocumentType.REPORTS_4}
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </div>
            <Form.Item
              style={{ height: '0px' }}
              name="files"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input type={'hidden'} />
            </Form.Item>
            <Form.Item
              style={{ height: '0px' }}
              name="infoDebt"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input type={'hidden'} />
            </Form.Item>
            <Form.Item
              style={{ height: '0px' }}
              name="infoTax"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input type={'hidden'} />
            </Form.Item>
            <Form.Item
              style={{ height: '0px' }}
              name="infoFounders"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input type={'hidden'} />
            </Form.Item>
            <Form.Item
              style={{ height: '0px' }}
              name="regDate"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input type={'hidden'} />
            </Form.Item>
            <Form.Item
              style={{ height: '0px' }}
              name="revenue"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input type={'hidden'} />
            </Form.Item>
            <Form.Item
              style={{ height: '0px' }}
              name="income"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input type={'hidden'} />
            </Form.Item>
            <Form.Item
              label="Статус"
              name="status"
              hidden
              initialValue={UserStatus.ACCOUNT}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: UserStatus.ACCOUNT,
                    value: UserStatus.ACCOUNT,
                  },
                  // {
                  //   label: UserStatus.DECLINED,
                  //   value: UserStatus.DECLINED,
                  // },
                  // {
                  //   label: UserStatus.APPROVAL,
                  //   value: UserStatus.APPROVAL,
                  // },
                  // {
                  //   label: UserStatus.IDENTIFIED,
                  //   value: UserStatus.IDENTIFIED,
                  // },
                  // {
                  //   label: UserStatus.CLIENT,
                  //   value: UserStatus.CLIENT,
                  // },
                  // {
                  //   label: UserStatus.QUALIFIED,
                  //   value: UserStatus.QUALIFIED,
                  // },
                ]}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Create>
  );
};
