import React, { useState, useContext } from 'react';
import dayjs from 'dayjs';
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNotification,
} from '@pankod/refine-core';
import locale from 'antd/es/date-picker/locale/ru_RU';
import {
  Alert,
  Checkbox,
  Divider,
  Edit,
  Form,
  Input,
  InputNumber,
  ListButton,
  Select,
  Typography,
  Upload,
  UploadFile,
  useFileUploadState,
  useForm,
  useSelect,
  DatePicker,
  Row,
  Col,
  Descriptions,
  NumberField,
  Button,
  Spin,
  AutoComplete,
} from '@pankod/refine-antd';
import moment, { Moment } from 'moment/moment';
import ImgCrop from 'antd-img-crop';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { IOffer, IPicture, IUser } from 'interfaces';
import { normalizeFile } from '../../utility/normalize';
import { axiosInstance } from '../../App';
import { Role } from '../../enums/role';
import { OfferStatus } from '../../enums/offer.status';
import { LoanType } from '../../enums/loan.type';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import InputMask from 'react-input-mask';
import { FormRule, InputProps, InputRef } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
import { DocumentType } from '../../enums/document.type';
import dadataAddress from 'utility/dadata/address';
import { UserContext } from 'UserProvider';
const { Text } = Typography;
const privateFiles: string[] = [];
function getPayment(sum: number, period: number, rate: number) {
  const i = rate / 12 / 100;
  const koef = (i * Math.pow(1 + i, period)) / (Math.pow(1 + i, period) - 1);
  const result = sum * koef;
  return Math.round(result * 100) / 100;
}
function getPaymentPercent(
  sum: number,
  monthsCount: number,
  loan_rate: number,
) {
  const month_loan_rate = loan_rate / (100 * 12);
  const monthly = sum * month_loan_rate;
  const payment_array = [];
  for (let i = 1; i < monthsCount; i++) {
    payment_array.push([i, +monthly, 0, +monthly, +sum]);
  }
  payment_array.push([monthsCount, +monthly + sum, +sum, +monthly, 0]);
  return +payment_array[0][1];
}
export const CollateralBlock = (props: {
  record: IOffer;
  me: IUser;
  form: any;
}) => {
  const dadataPersonal = (event: any) => {
    axiosInstance
      .get(
        process.env.REACT_APP_BACKEND_URL +
          '/api/v1/dadata/' +
          event.target.value,
      )
      .then((res) => {
        props.form.setFieldsValue({
          orgOgrn: res.data.suggestions[0].data.ogrn,
        });
        props.form.setFieldsValue({ orgName: res.data.suggestions[0].value });
        props.form.setFieldsValue({
          orgLocation: res.data.suggestions[0].data.address.value,
        });
      });
  };
  const innRule: FormRule = {
    type: 'string',
    min: 12,
    max: 12,
    message: 'ИНН физлица содержит 12 цифр',
  };

  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );
  const [collateral, setCollateral] = useState<boolean>(false);

  const handleSearch = async (value: string) => {
    let res: { value: string; label: string }[] = [];
    const dadataSugs = await dadataAddress(value);
    res = dadataSugs.map((s: any) => {
      return {
        value: s.unrestricted_value,
        label: s.unrestricted_value,
        disabled: !s.data.house,
      };
    });
    setOptions(res);
  };

  return (
    <>
      <Form.Item
        label=""
        name="hasCollateral"
        valuePropName="checked"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Checkbox onClick={(e) => setCollateral(true)}>
          Имеется поручительство
        </Checkbox>
      </Form.Item>
      {collateral && (
        <>
          <Divider orientation="left" plain>
            <Text type="secondary">Информация о поручителе</Text>
          </Divider>
          <Button
            size="middle"
            style={{ float: 'right', marginBottom: '20px' }}
            icon={<ArrowDownOutlined />}
            onClick={() => {
              props.form.setFieldsValue({
                guarantorFirstName: props.me.firstName,
                guarantorMiddleName: props.me.middleName,
                guarantorLastName: props.me.lastName,

                guarantorEmail: props.me.email,
                // guarantorBik: props.me.bik,
                // guarantorBankName: props.me.bankName,
                // guarantorBankAccNumber: props.me.bankAccNumber,
                // guarantorBankCorNumber: props.me.bankCorNumber,
              });
            }}
          >
            Я поручитель
          </Button>
          <div style={{ clear: 'both' }}></div>
          <Form.Item
            label="ИНН"
            name="guarantorInn"
            rules={[
              {
                required: false,
              },
              innRule,
            ]}
          >
            <Input onInput={dadataPersonal} maxLength={innRule.max} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="guarantorEmail"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Фамилия"
            name="guarantorLastName"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Имя"
            name="guarantorFirstName"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Отчество"
            name="guarantorMiddleName"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Дата рождения"
            name="guarantorJurDateOfBirth"
            rules={[
              {
                required: false,
              },
              {
                validator: (_, value) => {
                  const date = moment(value, 'DD-MM-YYYY').toDate();
                  date.setFullYear(date.getFullYear() + 18);
                  const today = new Date();
                  if (date > today) {
                    return Promise.reject(
                      new Error('Поручительство возможно с 18 лет'),
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <InputMask placeholder={''} mask="99.99.9999">
              {(
                inputProps: JSX.IntrinsicAttributes &
                  InputProps &
                  React.RefAttributes<InputRef>,
              ) => <Input allowClear {...inputProps} />}
            </InputMask>
          </Form.Item>
          <Form.Item
            label="Место рождения"
            name="guarantorPlaceOfBirth"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Адрес регистрации (город, улица, дом)"
            name="guarantorAddress"
            hasFeedback
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  const regex = /^[0-9,./а-яА-ЯёЁ\s-]*$/;
                  if (!regex.test(value)) {
                    return Promise.reject(
                      new Error('Пожалуйста, введите только кириллицу'),
                    );
                  } else if (value.length > 2000) {
                    return Promise.reject(
                      new Error('Допустимо максимум 2000 символов'),
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <AutoComplete
              onSearch={handleSearch}
              placeholder=""
              options={options}
            />
          </Form.Item>

          <Form.Item label="Адрес проживания (город, улица, дом)">
            <div style={{ display: 'flex' }}>
              <Button
                size="middle"
                style={{ marginRight: 10 }}
                icon={<ArrowDownOutlined />}
                onClick={() => {
                  props.form.setFieldsValue({
                    guarantorFactAddress:
                      props.form.getFieldValue('guarantorAddress'),
                  });
                }}
              >
                Совпадает
              </Button>
              <Form.Item
                style={{
                  display: 'inline-block',
                  width: '100%',
                  marginBottom: 0,
                }}
                name="guarantorFactAddress"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) => {
                      const regex = /^[0-9,./а-яА-ЯёЁ\s-]*$/;
                      if (!regex.test(value)) {
                        return Promise.reject(
                          new Error('Пожалуйста, введите только кириллицу'),
                        );
                      } else if (value.length > 2000) {
                        return Promise.reject(
                          new Error('Допустимо максимум 2000 символов'),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <AutoComplete
                  size={'middle'}
                  onSearch={handleSearch}
                  placeholder=""
                  options={options}
                />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item
            label="Серия и № паспорта"
            name="guarantorPassportNumber"
            hasFeedback
            rules={[
              {
                required: false,
              },
            ]}
          >
            <InputMask
              placeholder={''}
              mask="серия: 9999, № 999999"
              // maskChar=' '
            >
              {(
                inputProps: JSX.IntrinsicAttributes &
                  InputProps &
                  React.RefAttributes<InputRef>,
              ) => <Input allowClear {...inputProps} />}
            </InputMask>
          </Form.Item>
          <Form.Item
            label="Дата выдачи"
            name="guarantorIssueDate"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <InputMask
              placeholder={''}
              mask="99.99.9999"
              // maskChar=' '
            >
              {(
                inputProps: JSX.IntrinsicAttributes &
                  InputProps &
                  React.RefAttributes<InputRef>,
              ) => <Input allowClear {...inputProps} />}
            </InputMask>
          </Form.Item>
          <Form.Item
            label="Кем выдан"
            name="guarantorPassportIssuedBy"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Код подразделения"
            name="guarantorPassportCodeDepartment"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </>
      )}
    </>
  );
};
export const OfferEdit: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const [sum, setSum] = useState<number>(0);
  const [minSum, setMinSum] = useState<number>(0);
  const [rate, setRate] = useState<number>(5);
  const [months, setMonths] = useState<number>(1);
  const [calc, setCalc] = useState<{
    monthly: number;
    toReturn: number;
    profit: number;
  }>();
  const [minCalc, setMinCalc] = useState<{
    monthly: number;
    toReturn: number;
    profit: number;
  }>();
  let record: IOffer | undefined;
  const { open: notify } = useNotification();
  // const { data: me } = useGetIdentity<IUser>();
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  console.log('edit.tsx', me?.uuid);
  const [loanType, setLoanType] = useState<LoanType>(LoanType.QLIQ_LOAN);
  const { formProps, saveButtonProps, queryResult, form } = useForm<IOffer>({
    successNotification: {
      description: 'Предложение сохранено',
      message:
        'Для публикации предложения отправьте его на согласование, нажав Просмотр',
      type: 'success',
    },
  });
  const { selectProps: userSelectProps } = useSelect<IUser>({
    resource: 'users',
    optionLabel: 'orgName',
    optionValue: 'uuid',
    filters: [
      {
        operator: 'or',
        value: [
          {
            field: 'role',
            operator: 'in',
            value: [Role.INVESTOR],
          },
          {
            operator: 'and',
            value: [
              {
                field: 'role',
                operator: 'in',
                value: [Role.LENDER],
              },
              {
                field: 'active',
                operator: 'eq',
                value: 1,
              },
              {
                field: 'uuid',
                operator: 'ne',
                value: me?.uuid,
              },
            ],
          },
        ],
      },
    ],
  });
  const { selectProps: investorSelectProps } = useSelect<IUser>({
    resource: 'users',
    optionLabel: 'orgName',
    optionValue: 'uuid',
    filters: [
      {
        operator: 'or',
        value: [
          {
            field: 'role',
            operator: 'in',
            value: [Role.INVESTOR],
          },
          {
            operator: 'and',
            value: [
              {
                field: 'role',
                operator: 'in',
                value: [Role.LENDER],
              },
              {
                field: 'active',
                operator: 'eq',
                value: 1,
              },
              {
                field: 'uuid',
                operator: 'ne',
                value: me?.uuid,
              },
            ],
          },
        ],
      },
    ],
  });
  const { onChange } = useFileUploadState();
  const { onChange: onChange2 } = useFileUploadState();
  const removeFile = async (file: any) => {
    let result;
    if (notify) {
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/media/remove',
          { file },
        );
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: '',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  let privateFileList: UploadFile<any>[] | undefined,
    privateFileList2: UploadFile<any>[] | undefined;
  let targetInvestors;
  if (queryResult?.isSuccess) {
    record = queryResult.data.data;

    form.setFieldValue('targetInvestors', targetInvestors);
    form.setFieldValue(
      'guarantorJurDateOfBirth',
      moment(record?.guarantorJurDateOfBirth).format('DD.MM.YYYY'),
    );
  }
  function changeCalc(sum: number, months: number, rate: number) {
    let monthly = getPayment(sum, months, rate);
    let toReturn = (sum * rate) / 100 + sum;
    if (loanType === LoanType.PERCENT) {
      monthly = getPaymentPercent(sum, months, rate);
      toReturn = months * monthly + sum;
    }
    const profit = toReturn - sum;
    setCalc({
      monthly: +monthly.toFixed(2),
      profit: +profit.toFixed(2),
      toReturn: +toReturn.toFixed(2),
    });
  }
  function changeMinCalc(sum: number, months: number, rate: number) {
    let monthly = getPayment(sum, months, rate);
    let toReturn = (sum * rate) / 100 + sum;
    if (loanType === LoanType.PERCENT) {
      monthly = getPaymentPercent(sum, months, rate);
      toReturn = months * monthly + sum;
    }
    const profit = toReturn - sum;
    setMinCalc({
      monthly: +monthly.toFixed(2),
      profit: +profit.toFixed(2),
      toReturn: +toReturn.toFixed(2),
    });
  }
  const onMinChange = (newMinSum: number | null) => {
    if (newMinSum) {
      setMinSum(newMinSum);
      changeMinCalc(newMinSum, months, rate);
    }
  };
  const onSumChange = (newSum: number | null) => {
    if (newSum) {
      setSum(newSum);
      changeCalc(newSum, months, rate);
    }
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const damia = (event: any) => {
    if (event.target.value.length >= 9) {
      axiosInstance
        .get(
          process.env.REACT_APP_BACKEND_URL +
            '/api/v1/dadata/damia/' +
            event.target.value,
        )
        .then((res) => {
          const maxVolume = (
            +res.data[event.target.value]['ОбеспГарант']['Сумма'] +
            +res.data[event.target.value]['ОбеспИсп']['Сумма'] +
            +res.data[event.target.value]['ОбеспУчаст']['Сумма']
          ).toFixed(2);
          const minVolume =
            +res.data[event.target.value]['ОбеспГарант']['Сумма'].toFixed(2);
          form.setFieldsValue({
            tenderName: res.data[event.target.value]['Продукт']['Название'],
            tenderPlatform: res.data[event.target.value]['ЭТП']['Наименование'],
            minVolume,
            maxVolume,
          });
          onMinChange(+minVolume);
          onSumChange(+maxVolume);
        });
    }
  };
  return record && me ? (
    <Edit
      headerProps={{
        subTitle: '',
        extra: <ListButton />,
      }}
      title="Изменение инвестиционного предложения"
      saveButtonProps={{ ...saveButtonProps, children: 'Сохранить' }}
    >
      <Form
        labelWrap
        scrollToFirstError
        {...formProps}
        {...formItemLayout}
        initialValues={{ ...record }}
      >
        <Row gutter={{ xs: 8, sm: 24, md: 24, lg: 32 }}>
          <Col className="gutter-row" md={{ span: 24 }} lg={{ span: 12 }}>
            <Divider orientation="left" plain>
              <Text type="secondary">
                Сведения об инвестиционном предложении
              </Text>
            </Divider>
            <Form.Item
              label="Тип займа"
              name="loanType"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                onChange={(value) => {
                  setLoanType(value);
                  setRate(value === LoanType.QLIQ_DEDICATED_LOAN ? 2 : 3);
                  changeCalc(
                    sum,
                    months,
                    value === LoanType.QLIQ_DEDICATED_LOAN ? 2 : 3,
                  );
                  changeMinCalc(
                    minSum,
                    months,
                    value === LoanType.QLIQ_DEDICATED_LOAN ? 2 : 3,
                  );
                  form.setFieldsValue({
                    interestRate:
                      value === LoanType.QLIQ_DEDICATED_LOAN ? 2 : 3,
                  });
                }}
                options={[
                  {
                    label: LoanType.QLIQ_LOAN,
                    value: LoanType.QLIQ_LOAN,
                  },
                  {
                    label: LoanType.QLIQ_DEDICATED_LOAN,
                    value: LoanType.QLIQ_DEDICATED_LOAN,
                  },
                ]}
              />
            </Form.Item>
            {loanType === LoanType.QLIQ_DEDICATED_LOAN && (
              <>
                <Divider orientation="left" plain>
                  <Text type="secondary">Информация об аукционе</Text>
                </Divider>
                <Form.Item
                  label="Номер аукциона"
                  name="tenderNumber"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={30}
                    rows={1}
                    onInput={damia}
                  />
                </Form.Item>
                <Form.Item
                  label="Наименование закупки"
                  name="tenderName"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input.TextArea showCount maxLength={2000} rows={1} />
                </Form.Item>
                <Form.Item
                  label="Наименование электронной площадки"
                  name="tenderPlatform"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input.TextArea showCount maxLength={100} rows={1} />
                </Form.Item>
              </>
            )}
            <Form.Item
              label="Минимальная сумма"
              name="minVolume"
              tooltip={
                'При сборе этой суммы, оферта будет исполнена и инвестиции состоятся, даже если максимальная сумма не будет собрана до истечения срока оферты'
              }
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber
                onChange={onMinChange}
                min={20000}
                step={20000}
                width={300}
                precision={2}
                addonAfter={'₽'}
                formatter={(value: any) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                }
              />
            </Form.Item>
            <Form.Item
              label="Максимальная сумма"
              name="maxVolume"
              tooltip={
                'При сборе этой суммы, оферта считается исполненной и снимается с публикации'
              }
              dependencies={['minVolume']}
              rules={[
                {
                  required: false,
                  message: 'Пожалуйста, введите сумму',
                },
                ({ getFieldValue, setFieldsValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('minVolume') > value) {
                      return Promise.reject(
                        'Должна быть не ниже минимальной суммы',
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                onChange={onSumChange}
                min={20000}
                step={20000}
                width={300}
                precision={2}
                addonAfter={'₽'}
                formatter={(value: any) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                }
              />
            </Form.Item>
            <Form.Item
              label="Срок действия предложения"
              name="deadline"
              rules={[
                {
                  required: false,
                },
                {
                  validator: (_, value) => {
                    const date = value.$d;
                    const today = new Date();
                    if (+date < +today) {
                      return Promise.reject(
                        new Error('Дата должна быть в будущем'),
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <DatePicker
                allowClear
                locale={locale}
                showTime
                format="DD.MM.YYYY HH:mm"
              />
            </Form.Item>
            <Form.Item
              label="Процентная ставка"
              name="interestRate"
              tooltip={'Ставка фиксирована и зависит от типа займа'}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber
                disabled
                width={300}
                defaultValue={loanType === LoanType.QLIQ_DEDICATED_LOAN ? 2 : 3}
                addonAfter={'%'}
              />
            </Form.Item>
            <Form.Item
              label="Период займа"
              name="offerPeriodDays"
              tooltip={
                'Период фиксирован и составляет 30 календарных дней для всех типов займов'
              }
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber
                disabled
                width={300}
                defaultValue={30}
                addonAfter={'дней'}
              />
            </Form.Item>
          </Col>
          <Col
            style={{ paddingBottom: '30px' }}
            className="gutter-row"
            md={{ span: 24 }}
            lg={{ span: 12 }}
          >
            {minCalc?.profit && minCalc?.monthly && minCalc?.toReturn && (
              <>
                <Divider orientation="left" plain>
                  <Text type="secondary">
                    Прогноз при сборе минимальной суммы
                  </Text>
                </Divider>
                <Descriptions
                  title=""
                  size="small"
                  bordered
                  column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                >
                  {/*<Descriptions.Item label="Ежемесячный платеж">*/}
                  {/*  {minCalc.monthly && (*/}
                  {/*    <NumberField value={minCalc.monthly} />*/}
                  {/*  )}{' '}*/}
                  {/*  ₽*/}
                  {/*</Descriptions.Item>*/}
                  <Descriptions.Item label={'К возвращению'}>
                    {<NumberField value={minCalc.toReturn} />} ₽
                  </Descriptions.Item>
                  <Descriptions.Item label={'Проценты'}>
                    {<NumberField value={minCalc.profit} />} ₽
                  </Descriptions.Item>
                  <Descriptions.Item label={'Гарантийный взнос'}>
                    {<NumberField value={minCalc.profit} />} ₽
                  </Descriptions.Item>
                </Descriptions>
              </>
            )}
            {calc?.profit && calc?.monthly && calc?.toReturn && (
              <>
                <Divider orientation="left" plain>
                  <Text type="secondary">
                    Прогноз при сборе максимальной суммы
                  </Text>
                </Divider>
                <Descriptions
                  title=""
                  size="small"
                  bordered
                  column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                >
                  {/*<Descriptions.Item label="Ежемесячный платеж">*/}
                  {/*  {calc.monthly && <NumberField value={calc.monthly} />} ₽*/}
                  {/*</Descriptions.Item>*/}
                  <Descriptions.Item label={'К возвращению'}>
                    {<NumberField value={calc.toReturn} />} ₽
                  </Descriptions.Item>
                  <Descriptions.Item label={'Проценты'}>
                    {<NumberField value={calc.profit} />} ₽
                  </Descriptions.Item>
                  <Descriptions.Item label={'Гарантийный взнос'}>
                    {<NumberField value={calc.profit} />} ₽
                  </Descriptions.Item>
                </Descriptions>
              </>
            )}
          </Col>
        </Row>
        {record && me && form && (
          <CollateralBlock record={record} me={me} form={form} />
        )}
        <br />
        <Divider orientation="left" plain>
          <Text type="secondary">Дополнительная информация о предложении</Text>
        </Divider>
        <Form.Item
          label="Цели использования суммы займа"
          name="infoTargetGoals"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea showCount maxLength={1000} rows={7} />
        </Form.Item>
        <Form.Item
          label="Информация, позволяющая составить общее представление о целях привлечения инвестиций и об обстоятельствах, которые могут оказать влияние на достижение указанных целей"
          name="infoGoals"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea showCount maxLength={1000} rows={7} />
        </Form.Item>
        <Form.Item
          label="Обязанность Заемщика по предоставлению обеспечения исполнения обязательств по договору Займа."
          name="infoExecuting"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea showCount maxLength={1000} rows={7} />
        </Form.Item>
        <Form.Item
          label="Прочие условия Инвестиционного предложения"
          name="infoOther"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea showCount maxLength={1000} rows={7} />
        </Form.Item>
        <Form.Item
          label="Заемщик"
          name={['lender', 'uuid']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select {...userSelectProps} disabled={me?.role !== Role.DIRECTOR} />
        </Form.Item>
        <Form.Item
          label="Инвесторы, которым доступно предложение"
          name={'targetInvestors'}
          rules={[
            {
              required: false,
            },
          ]}
          // initialValue={targetInvestors}
        >
          <Select mode="multiple" {...investorSelectProps} />
        </Form.Item>
        <Divider orientation="left" plain>
          <Text type="secondary">
            Информация о займах, размещенных на других платформах в текущем году
          </Text>
        </Divider>
        <Form.Item
          label="Сумма займов на других платформах на текущий момент"
          name="lendOtherAccount"
          tooltip={
            'Общая сумма займов, размещенных на других платформах в текущем календарном году, на момент размещения предложения'
          }
          rules={[
            {
              required: false,
            },
          ]}
        >
          <InputNumber
            onChange={onMinChange}
            min={0}
            step={20000}
            width={300}
            precision={2}
            addonAfter={'₽'}
            formatter={(value: any) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
          />
        </Form.Item>
        {me.role === Role.AGENT && (
          <>
            <Form.Item
              label="Статус"
              name="status"
              // tooltip={
              //   'Поставьте статус На рассмотрении, чтобы отправить оффер на согласование'
              // }
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                defaultValue={OfferStatus.DRAFT}
                options={[
                  {
                    label: OfferStatus.DRAFT,
                    value: OfferStatus.DRAFT,
                  },
                  // {
                  //   label: OfferStatus.APPROVAL,
                  //   value: OfferStatus.APPROVAL,
                  // },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Агентская комиссия"
              name="agentFee"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber width={300} defaultValue={3} addonAfter={'%'} />
            </Form.Item>
          </>
        )}
        {(me.role === Role.DIRECTOR || me.role === Role.UNDERWRITER) && (
          <>
            <Divider orientation="left" plain>
              <Text type="danger">
                Служебный раздел (только для сотрудников)
              </Text>
            </Divider>
            <Form.Item
              label="Статус"
              name="status"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                defaultValue={OfferStatus.DRAFT}
                disabled
                options={[
                  {
                    label: OfferStatus.DRAFT,
                    value: OfferStatus.DRAFT,
                  },
                  {
                    label: OfferStatus.APPROVAL,
                    value: OfferStatus.APPROVAL,
                  },
                ]}
              />
            </Form.Item>
            {/*<div*/}
            {/*  style={{*/}
            {/*    display: 'flex',*/}
            {/*    justifyContent: 'space-around',*/}
            {/*    flexWrap: 'wrap',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Form.Item*/}
            {/*    name="images"*/}
            {/*    valuePropName="fileList"*/}
            {/*    normalize={normalizeFile}*/}
            {/*    style={{ width: '45%' }}*/}
            {/*  >*/}
            {/*    <Upload.Dragger*/}
            {/*      name="file"*/}
            {/*      action={`${apiUrl}/media/upload`}*/}
            {/*      headers={{*/}
            {/*        Authorization: `Bearer ${localStorage.getItem('token')}`,*/}
            {/*      }}*/}
            {/*      listType="picture"*/}
            {/*      maxCount={20}*/}
            {/*      multiple*/}
            {/*      onChange={onChange}*/}
            {/*      onRemove={removeFile}*/}
            {/*      defaultFileList={privateFileList}*/}
            {/*      data={() => {*/}
            {/*        const fileUuid = uuidV4();*/}
            {/*        privateFiles.push(fileUuid);*/}
            {/*        form?.setFieldsValue({*/}
            {/*          privateFiles,*/}
            {/*        });*/}
            {/*        console.log(privateFiles);*/}
            {/*        return { fileUuid, type: DocumentType.NBKI };*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <p style={{ fontSize: 13, padding: '10px 10px 0 10px' }}>*/}
            {/*        {'Загрузить документы кредитной истории (НБКИ)'}*/}
            {/*      </p>*/}
            {/*    </Upload.Dragger>*/}
            {/*  </Form.Item>*/}
            {/*  <Form.Item*/}
            {/*    name="images2"*/}
            {/*    valuePropName="fileList2"*/}
            {/*    normalize={normalizeFile}*/}
            {/*    style={{ width: '45%' }}*/}
            {/*  >*/}
            {/*    <Upload.Dragger*/}
            {/*      name="file"*/}
            {/*      action={`${apiUrl}/media/upload`}*/}
            {/*      headers={{*/}
            {/*        Authorization: `Bearer ${localStorage.getItem('token')}`,*/}
            {/*      }}*/}
            {/*      listType="picture"*/}
            {/*      maxCount={20}*/}
            {/*      multiple*/}
            {/*      onChange={onChange2}*/}
            {/*      onRemove={removeFile}*/}
            {/*      defaultFileList={privateFileList2}*/}
            {/*      data={() => {*/}
            {/*        const fileUuid = uuidV4();*/}
            {/*        privateFiles.push(fileUuid);*/}
            {/*        form?.setFieldsValue({*/}
            {/*          privateFiles,*/}
            {/*        });*/}
            {/*        return { fileUuid, type: DocumentType.AUX };*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <p style={{ fontSize: 13, padding: '10px 10px 0 10px' }}>*/}
            {/*        {'Загрузить прочие документы'}*/}
            {/*      </p>*/}
            {/*    </Upload.Dragger>*/}
            {/*  </Form.Item>*/}
            {/*  <Form.Item*/}
            {/*    style={{ height: '0px' }}*/}
            {/*    name="privateFiles"*/}
            {/*    rules={[*/}
            {/*      {*/}
            {/*        required: false,*/}
            {/*      },*/}
            {/*    ]}*/}
            {/*  >*/}
            {/*    <Input type={'hidden'} />*/}
            {/*  </Form.Item>*/}
            {/*</div>*/}
          </>
        )}
      </Form>
    </Edit>
  ) : (
    <Spin style={{ padding: 20 }} />
  );
};
