export enum OfferStatus {
  DRAFT = 'Подготовка',
  INWORK = 'В работе',
  APPROVAL = 'На рассмотрении',
  RETURNED = 'Доработка',
  AGREEMENT = 'Документы сформированы',
  REFUSE = 'Отказ',
  REFUSE_CLIENT = 'Отказ клиента',
  SUCCESS = 'Выдан',
  PROLONG = 'Пролонгирован',
  VIOLATED = 'Просрочен',
  INCOURT = 'В суде',
  CANCELED = 'Закрыт',
}
