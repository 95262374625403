import {
  Button,
  Card,
  Col,
  DateField,
  Descriptions,
  NumberField,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  TagField,
  TextField,
  Tooltip,
  Typography,
  Upload,
} from '@pankod/refine-antd';
import { CanAccess, useNotification } from '@pankod/refine-core';
import { PaymentType } from 'enums/payment.type';
import { IOffer, IUser } from 'interfaces';
import { PlusSquareOutlined, FileAddOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { axiosInstance } from 'App';
import { UserType } from 'enums/user.type';
import { UserStatus } from 'enums/user.status';
import { Role } from 'enums/role';
import { DocumentType } from 'enums/document.type';
import { uuidV4 } from 'utility/uuidv4';
const { Text } = Typography;

const Info = function (props: { offer: IOffer; refetch: any }) {
  return props.offer ? (
    <Card size="small" title="Сведения об организации заемщика">
      <Row gutter={[16, 16]} style={{ width: '100%' }}>
        <Col sm={24} md={24} xl={12} lg={12} xxl={8}>
          <Card hoverable size="small">
            <Descriptions
              title={<Text type="secondary">Информации о компании</Text>}
              bordered
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="ИНН организации">
                <TextField value={props.offer.innOrg} />
              </Descriptions.Item>
              <Descriptions.Item label="Название организации">
                <TextField value={props.offer.nameOrg} />
              </Descriptions.Item>
              <Descriptions.Item label="Полное название организации">
                <TextField value={props.offer.fullNameOrg} />
              </Descriptions.Item>
              <Descriptions.Item label="Наличие лицензии">
                <TextField value={props.offer.licenceOrg} />
              </Descriptions.Item>
              <Descriptions.Item label="Организационно-правовая ответственность">
                <TextField value={props.offer.oop} />
              </Descriptions.Item>
              <Descriptions.Item label="ОГРН">
                <TextField value={props.offer.ogrn} />
              </Descriptions.Item>
              <Descriptions.Item label="КПП">
                <TextField value={props.offer.kppOrg} />
              </Descriptions.Item>
              <Descriptions.Item label="Юридический адрес">
                <TextField value={props.offer.addressOrg} />
              </Descriptions.Item>
              <Descriptions.Item label="Фактический адрес">
                <TextField value={props.offer.fullAddressOrg} />
              </Descriptions.Item>
              <Descriptions.Item label="Уставной капитал">
                <TextField value={props.offer.kapitalOrg} />
              </Descriptions.Item>
              <Descriptions.Item label="Должность руководителя">
                <TextField value={props.offer.directorPos} />
              </Descriptions.Item>
              <Descriptions.Item label="Руководитель">
                <TextField value={props.offer.manager} />
              </Descriptions.Item>
              <Descriptions.Item label="Дата регистрации">
                <TextField value={props.offer.regOrg} />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        <Col sm={24} md={24} xl={12} lg={12} xxl={8}>
          <Card hoverable size="small">
            <Descriptions
              title={<Text type="secondary">Информации о компании</Text>}
              bordered
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="Фамилия">
                <TextField value={props.offer.lenderLastName} />
              </Descriptions.Item>
              <Descriptions.Item label="Имя">
                <TextField value={props.offer.lenderFirstName} />
              </Descriptions.Item>
              <Descriptions.Item label="Отчество">
                <TextField value={props.offer.lenderMiddleName} />
              </Descriptions.Item>
              <Descriptions.Item label="Дата рождения">
                <TextField value={props.offer.lenderDateOfBirth} />
              </Descriptions.Item>
              <Descriptions.Item label="Место рождения">
                <TextField value={props.offer.lenderPlaceOfBirth} />
              </Descriptions.Item>
              <Descriptions.Item label="Серия и № паспорта">
                <TextField value={props.offer.lenderPassportNumber} />
              </Descriptions.Item>
              <Descriptions.Item label="Дата выдачи">
                <TextField value={props.offer.lenderPassportIssue} />
              </Descriptions.Item>
              <Descriptions.Item label="Гражданство директора">
                <TextField value={props.offer.lenderСitizenship} />
              </Descriptions.Item>
              <Descriptions.Item label="Код подразделения">
                <TextField value={props.offer.lenderPassportIssueCode} />
              </Descriptions.Item>
              <Descriptions.Item label="Кем выдан">
                <TextField value={props.offer.lenderPassportIssueBy} />
              </Descriptions.Item>
              <Descriptions.Item label="Адрес регистрации (город, улица, дом)">
                <TextField value={props.offer.lenderAddressRegistration} />
              </Descriptions.Item>
              <Descriptions.Item label="Фактический адрес">
                <TextField value={props.offer.lenderAddressFact} />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col sm={24} md={24} xl={12} lg={12} xxl={8}>
          <Card hoverable size="small">
            <Space size={16} direction="vertical" style={{ width: '100%' }}>
              <Descriptions
                title={<Text type="secondary">Банковские данные</Text>}
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                <Descriptions.Item label="БИК">
                  <TextField value={props.offer.lenderIssueBik} />
                </Descriptions.Item>
                <Descriptions.Item label="Расчетный счет">
                  <TextField value={props.offer.lenderIssueAccNumber} />
                </Descriptions.Item>
                <Descriptions.Item label="Наименование банка">
                  <TextField value={props.offer.lenderIssueBank} />
                </Descriptions.Item>
                <Descriptions.Item label="Корр. счет">
                  <TextField value={props.offer.lenderIssueCorNumber} />
                </Descriptions.Item>
              </Descriptions>
              <Descriptions
                title={<Text type="secondary">Контакты</Text>}
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                <Descriptions.Item label="Email">
                  <TextField value={props.offer.lenderEmail} />
                </Descriptions.Item>
                <Descriptions.Item label="Телефон">
                  <TextField value={props.offer.lenderPhone} />
                </Descriptions.Item>
              </Descriptions>
            </Space>
          </Card>
        </Col>
      </Row>
    </Card>
  ) : (
    <Spin style={{ padding: 20 }} />
  );
};

export default Info;
