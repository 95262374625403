import { useApiUrl, useCustom, useOne } from '@pankod/refine-core';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { IOffer } from 'interfaces';
import { Col, Row, Show } from '@pankod/refine-antd';

export const OfferPublic: React.FC = () => {
  const me = {
    uuid: 1,
    firstName: 'Иван',
  };
  const apiUrl = useApiUrl();
  const { offerId } = useParams();
  const { data, isLoading } = useOne<IOffer>({
    resource: 'public/offers',
    id: offerId!,
  });
  const record = data?.data;
  return (
    // <div className={'my-layout-container'}>
    <Show
      title={'Предложение'}
      isLoading={isLoading}
      headerProps={{
        extra: <></>,
        // subTitle: `Оферта #${record?.number}`,
      }}
    >
      {record && me && (
        <>
          1234
          {/* <h1 style={{ lineHeight: '24px', margin: '10px 0 15px 0' }}>
            {record?.name}
          </h1>
          <h2
            style={{
              fontWeight: 'normal',
              color: '#868686',
              fontSize: '14px',
              lineHeight: '18px',
              margin: '0px 0 30px 0',
            }}
          >{`Оферта №${record?.number}`}</h2>
          <Row>
            <Col xs={{ span: 24 }} xl={{ span: 16 }}>
              <PublicPicturesBlock record={record} />
            </Col>
            <Col xs={{ span: 24 }} xl={{ span: 8 }}>
              <DescriptionsBlockMain record={record} />
            </Col>
          </Row>
          <OfferDescription record={record} />
          <CollateralDescription record={record} /> */}
        </>
      )}
    </Show>
    // </div>
  );
};
