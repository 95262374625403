import { axiosInstance } from 'App';

const dadataAddress = (query: any): any => {
  return new Promise((resolve, reject) => {
    if (query.length >= 3) {
      axiosInstance
        .get(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/dadata/address/' + query,
        )
        .then((res) => {
          resolve(res.data?.suggestions);
        });
    }
  });
};

export default dadataAddress;
