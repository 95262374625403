import { IOffer, IUser, ratingApproveBlock } from '../../interfaces';
import { useNavigation, useNotification } from '@pankod/refine-core';
import React, { useState } from 'react';
import { axiosInstance } from '../../App';
import { OfferStatus } from '../../enums/offer.status';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Rate,
  Row,
  Select,
  Space,
  useSelect,
  useForm,
  Typography,
} from '@pankod/refine-antd';
import { TeamOutlined } from '@ant-design/icons';
import { NbkiRating } from 'enums/nbki.rating';
import DocumentsInOfferBlock from './documentsInOfferBlock';
import { Role } from 'enums/role';
import { UserStatus } from 'enums/user.status';
const { Text } = Typography;

const ratingTooltip = [
  'Задать рейтинг 1',
  'Задать рейтинг 2',
  'Задать рейтинг 3',
  'Задать рейтинг 4',
  'Задать рейтинг 5',
];

export function ApproveOfferBlock(props: { record: IOffer; refetch: any }) {
  const { open: notify } = useNotification();
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false);
  const { list } = useNavigation();

  const onConfirmChangeRating = async (values: ratingApproveBlock) => {
    if (notify) {
      setButtonDisabled(true);
      try {
        await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/offers/changeRating',
          {
            uuid: props.record.uuid,
            rating_leader: values.rating_leader,
            rating_founder: values.rating_founder,
          },
        );
        notify({
          description: 'Рейтинг ',
          message: 'Указанные рейтинги успешно сохранены в базе данных',
          type: 'success',
          key: '1',
          undoableTimeout: 20000,
        });
        props.refetch();
        setButtonDisabled(false);
      } catch (e) {
        setButtonDisabled(false);
        if (notify) {
          notify({
            description: 'Ошибка',
            message: 'Возникла ошибка сохранения рейтинга',
            type: 'error',
            key: '2',
            undoableTimeout: 20000,
          });
        }
      }
    }
  };

  const onApproveButton = async (values: IOffer) => {
    // console.log(values);
    if (notify) {
      setButtonDisabled(true);
      let result;
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/offers/publish',
          {
            uuid: props.record.uuid,
          },
        );
        notify({
          description: 'Успешно',
          message: 'Предложение согласовано',
          type: 'success',
          key: '1',
          undoableTimeout: 20000,
        });
        props.refetch();
        list('offers');
      } catch (error: any) {
        setButtonDisabled(false);
        if (notify) {
          notify({
            description: 'Ошибка',
            message:
              'Убедитесь что данные заполнены правильно , а так же реквизиты Заёмщика',
            type: 'error',
            key: '2',
            undoableTimeout: 20000,
          });
        }
      }
    }
  };
  const onDeclineButton = async (values: any) => {
    if (notify) {
      setButtonDisabled(true);
      let result;
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/offers/decline',
          { ...values, uuid: props.record.uuid },
        );
        notify({
          description: 'Предложение отправлено на доработку',
          message: 'и исправление',
          type: 'success',
          key: '1',
          undoableTimeout: 20000,
        });

        props.refetch();
        list('offers');
      } catch (e) {
        setButtonDisabled(false);
        notify({
          description: 'Ошибка',
          message: 'Убедитесь что данные заполнены правильно',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  const onRevokeButton = async (values: any) => {
    if (notify) {
      setButtonDisabled(true);
      let result;
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/offers/revoke',
          { ...values, uuid: props.record.uuid },
        );
        notify({
          description: 'Отказано',
          message: 'Предложение отозвано успешно! Уведомление отправлено!',
          type: 'success',
          key: '1',
          undoableTimeout: 20000,
        });
        props.refetch();
        list('offers');
      } catch (e) {
        setButtonDisabled(false);
        notify({
          description: 'Ошибка',
          message: 'Убедитесь что данные заполнены правильно',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };

  const onApproveInvestors = async (value: {
    targetInvestors: { uuid: number[] };
  }) => {
    if (notify) {
      try {
        const result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/offers/setInvestors',
          { investors: value.targetInvestors.uuid, offer: props.record.uuid },
        );
        notify({
          description: 'Выполнено',
          message: 'Инвесторы назначены успешно!',
          type: 'success',
          key: '2',
          undoableTimeout: 20000,
        });
        props.refetch();
      } catch (e) {
        notify({
          description: 'Ошибка',
          message:
            'Возникла ошибка , мы уже знаем о ней и устраним в кратчайший срок',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };

  const [form] = Form.useForm();

  return (
    <>
      <Row gutter={16}>
        <>
          <Col
            md={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ marginTop: 16, width: '100%' }}
          >
            <Card
              hoverable
              style={{ borderColor: '#7463a691', height: '100%' }}
              title="Загрузка файлов"
            >
              <DocumentsInOfferBlock
                record={props.record}
                refetch={props.refetch}
              />
            </Card>
          </Col>
          <Col
            md={{ span: 24 }}
            lg={{ span: 12 }}
            style={{ marginTop: 16, width: '100%' }}
          >
            <Card
              hoverable
              title="Согласование и публикация"
              style={{ height: '100%', borderColor: '#7463a691' }}
            >
              <Form layout="vertical" onFinish={onApproveButton}>
                <Space direction="horizontal" size={32}>
                  <Form.Item
                    label="Рейтинг Инв. предложения (1-5)"
                    name="rating"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Rate tooltips={ratingTooltip} />
                  </Form.Item>
                  <Form.Item
                    label="Комиссия платформы (1% - 10%)"
                    name="platformFee"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: 200 }}
                      min={1}
                      max={10}
                      step={0.1}
                    />
                  </Form.Item>
                </Space>
                <Form.Item
                  label="Заключение об инвестиционном предложении"
                  name="conclusion"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input.TextArea
                    autoSize
                    showCount
                    maxLength={3000}
                    rows={7}
                  />
                </Form.Item>

                <Form.Item noStyle>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={isButtonDisabled}
                  >
                    Согласовать и опубликовать
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>

          <Col
            md={{ span: 24 }}
            lg={{ span: 12 }}
            style={{ marginTop: 16, width: '100%' }}
          >
            <Card
              hoverable
              title={
                <span style={{ color: '#ff8800' }}>Вернуть на доработку</span>
              }
              style={{ height: '100%', borderColor: '#ff8800ab' }}
            >
              <Form
                layout="vertical"
                requiredMark={true}
                onFinish={onDeclineButton}
              >
                <Form.Item
                  label="Рекомендации по доработке инвестиционного предложения"
                  name="declineReason"
                  rules={[
                    {
                      required: true,
                      message: 'Необходимо заполнить',
                    },
                  ]}
                >
                  <Input.TextArea showCount maxLength={1000} rows={7} />
                </Form.Item>
                <Form.Item noStyle>
                  <Button htmlType="submit" disabled={isButtonDisabled}>
                    Отправить на доработку
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col
            md={{ span: 24 }}
            lg={{ span: 12 }}
            style={{ marginTop: 16, width: '100%' }}
          >
            <Card
              hoverable
              title={
                <span style={{ color: '#dc5f5e' }}>
                  Отказать по требованию платформы
                </span>
              }
              style={{ height: '100%', borderColor: '#dc5f5e96' }}
            >
              <Form
                layout="vertical"
                requiredMark={true}
                onFinish={onRevokeButton}
              >
                <Form.Item
                  label="Причина отказа публикации"
                  name="revokedReason"
                  rules={[
                    {
                      required: true,
                      message: 'Необходимо заполнить',
                    },
                  ]}
                >
                  <Input.TextArea showCount maxLength={1000} rows={7} />
                </Form.Item>
                <Form.Item noStyle>
                  <Button
                    type="primary"
                    danger
                    htmlType="submit"
                    disabled={isButtonDisabled}
                  >
                    Выполнить отказ
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </>
      </Row>
    </>
  );
}
