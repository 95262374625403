import { Role } from './enums/role';
import { OfferStatus } from './enums/offer.status';
import { UserStatus } from './enums/user.status';
import { InvestmentStatus } from './enums/investment.status';
import { AuthProvider, IAccessControlContext } from '@pankod/refine-core';
import { axiosInstance } from './App';
import { IRegistry } from './interfaces';
import { IUser } from './interfaces';
import { CanReturnType } from '@pankod/refine-core';
import { updateUser } from './userUpdater';

interface ResourceActions {
  [key: string]: {
    [key: string]: boolean;
  };
}
let cachedMe: IUser | null = null;
let isFetching = false;
const accessCache: { [key: string]: { can: boolean } } = {};

export const accessControlProvider: Required<IAccessControlContext> = {
  can: async ({ resource, action, params }): Promise<CanReturnType> => {
    axiosInstance.defaults.headers.common = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };

    let me;

    if (cachedMe) {
      me = cachedMe;
    } else {
      if (isFetching) {
        await new Promise((resolve) => {
          const intervalId = setInterval(() => {
            if (!isFetching) {
              clearInterval(intervalId);
              resolve(null);
            }
          }, 100);
        });
        me = cachedMe;
      } else {
        isFetching = true;
        try {
          const { data } = await axiosInstance.get(
            process.env.REACT_APP_BACKEND_URL + '/api/v1/profile/me',
          );
          cachedMe = data;
          me = data;
        } catch (error) {
          throw error;
        } finally {
          isFetching = false;
        }
      }
    }

    // ТОЛЬКО менеджер
    if (me.role === Role.MANAGER) {
      if (
        resource === 'payout-requests' ||
        resource === 'deposits' ||
        resource === 'agreements' ||
        resource === 'agents' ||
        resource === 'registry' ||
        (resource === 'registry' && action === 'download') ||
        resource === 'users' ||
        (resource === 'users' && action === 'review') ||
        resource === 'offers' ||
        resource === 'reports'
      ) {
        return Promise.resolve({ can: true });
      }
    }

    if (resource === 'settings') {
      return Promise.resolve({ can: true });
    }
    if (resource === 'profile') {
      return Promise.resolve({ can: true });
    }
    if (resource === 'messages') {
      return Promise.resolve({ can: true });
    }

    return Promise.resolve({
      can: false,
      reason: 'Нет доступа',
    });
  },
};

const loginCache: { [key: string]: any } = {};
const userCache: { [key: string]: any } = {};

export const authProvider: AuthProvider = {
  login: async ({ email, password, remember }) => {
    const cacheKey = `${email}:${password}:${remember}`;

    if (loginCache[cacheKey]) {
      return Promise.resolve(loginCache[cacheKey]);
    }

    try {
      let auth;
      if (!remember) {
        auth = await axiosInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/login`,
          { email, password, remember },
        );
      } else {
        auth = await axiosInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/smslogin`,
          { username: email, password: password.toString() },
        );
      }

      localStorage.setItem('token', auth.data.access_token);
      localStorage.setItem('role', auth.data.role);
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${auth.data.access_token}`,
      };

      const { data: me } = await axiosInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/profile/me`,
      );

      updateUser(me);

      localStorage.setItem('uuid', me.uuid);
      userCache[cacheKey] = me;

      return Promise.resolve(me);
    } catch (e) {
      return Promise.reject({
        message: 'Вход не выполнен!',
        name: 'Неверный пароль или email',
      });
    }
  },

  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('uuid');
    localStorage.clear();
    window.location.replace(process.env.REACT_APP_BACKEND_URL + '/login');
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem('token');
    if (token) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
  getPermissions: () => {
    const role = localStorage.getItem('role');
    if (role) {
      return Promise.resolve(role);
    }
    return Promise.reject();
  },
  getUserIdentity: async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return Promise.reject();
      }

      const cacheKey = `${token}`;
      if (userCache[cacheKey]) {
        return Promise.resolve(userCache[cacheKey]);
      }

      const user = await axiosInstance.get(
        process.env.REACT_APP_BACKEND_URL + '/api/v1/profile/me',
      );

      // Сохраняем данные пользователя в кэше
      userCache[cacheKey] = user.data;

      return Promise.resolve(user.data);
    } catch (e) {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('uuid');
      localStorage.clear();

      if (!window.location.href.includes('/register')) {
        window.location.replace(process.env.REACT_APP_BACKEND_URL + '/login');
      }
      return Promise.reject();
    }
  },
};
