import {
  Alert,
  AutoComplete,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputProps,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  Upload,
} from '@pankod/refine-antd';
import { useNotification } from '@pankod/refine-core';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { axiosInstance } from 'App';
import { UserType } from 'enums/user.type';
import { IUser } from 'interfaces';
import moment from 'moment';
import { InputRef } from 'rc-input';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import dadataAddress from 'utility/dadata/address';
import { dateStandartFormat } from 'utility/dateStandartFormat';
import { ArrowDownOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Text } = Typography;
function formatDate(dateString: string) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day < 10 ? '0' + day : day}.${
    month < 10 ? '0' + month : month
  }.${year}`;
}

function setPassNumber(passNumber: string) {
  return `серия: ${passNumber.split(' ')[0]}, № ${passNumber.split(' ')[1]}`;
}

export const Step1 = (props: {
  setStep: any;
  uuid: number | undefined;
  setUuid: any;
}) => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );
  const handleSearch = async (value: string) => {
    let res: { value: string; label: string }[] = [];
    const dadataSugs = await dadataAddress(value);
    res = dadataSugs.map((s: any) => {
      return {
        value: s.unrestricted_value,
        label: s.unrestricted_value,
        disabled: !s.data.house,
      };
    });
    setOptions(res);
  };

  const [form] = Form.useForm();
  const dadataBank = (event: any) => {
    if (event.target.value.length >= 9) {
      axiosInstance
        .get(
          process.env.REACT_APP_BACKEND_URL +
            '/api/v1/dadata/bank/' +
            event.target.value,
        )
        .then((res) => {
          form.setFieldsValue({
            lenderIssueBank: res.data.suggestions[0].data.name.short
              ? res.data.suggestions[0].data.name.short
              : res.data.suggestions[0].data.name.payment,
            lenderIssueCorNumber:
              res.data.suggestions[0].data.correspondent_account,
          });
        });
    }
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [hideIp, setHideIp] = useState<boolean>(true);
  const { open: notify } = useNotification();
  const [userType, setUserType] = useState<UserType>(UserType.IP);
  const [founders, setFounders] = useState<any[]>([]);
  const getDadataInn = async (event: any) => {
    const { value } = event.target;

    function findedFounders(data: any) {
      return data
        ? data.map((obj: any) => {
            console.log(obj);

            return {
              fio: obj.fio.source,
              value:
                obj.share.type === 'FRACTION'
                  ? (+obj.share.numerator * 100) / +obj.share.denominator
                  : obj.share.value,
            };
          })
        : [];
    }
    if (value.length === 10) {
      setUserType(UserType.UL);

      axiosInstance
        .get(process.env.REACT_APP_BACKEND_URL + '/api/v1/dadata/' + value)
        .then((res) => {
          setHideIp(true);
          form.setFieldsValue({
            userType: UserType.UL,
            nameOrg: res.data.suggestions[0].data.name.short_with_opf,
            fullNameOrg: res.data.suggestions[0].data.name.full_with_opf,
            licenceOrg: '',
            oop: res.data.suggestions[0].data.opf.full,
            directorPos: res.data.suggestions[0].data.managers[0].post,
            manager: res.data.suggestions[0].data.managers[0].fio.source,
            ogrn: res.data.suggestions[0].data.ogrn,
            kppOrg: res.data.suggestions[0].data.kpp,
            regOrg: dateStandartFormat(
              res.data.suggestions[0].data.state.registration_date,
            ),
            addressOrg: res.data.suggestions[0].data.address.value,
            kapitalOrg: res.data.suggestions[0].data.capital.value,
            lenderEmail: res.data.suggestions[0].data.emails
              ? res.data.suggestions[0].data.emails[0].value
              : '',
            lenderPhone: res.data.suggestions[0].data.phones
              ? res.data.suggestions[0].data.phones[0].value
              : '',
          });

          form.setFieldsValue({
            lenderFirstName: res.data.suggestions[0].data.managers[0].fio.name,
            lenderLastName:
              res.data.suggestions[0].data.managers[0].fio.surname,
            lenderMiddleName:
              res.data.suggestions[0].data.managers[0].fio.patronymic,
            lenderInn: res.data.suggestions[0].data.managers[0].inn,
          });
          setFounders(findedFounders(res.data.suggestions[0].data.founders));
          // setCantEdit(true);
        });
    } else if (value.length === 12) {
      setUserType(UserType.IP);
      axiosInstance
        .get(process.env.REACT_APP_BACKEND_URL + '/api/v1/dadata/' + value)
        .then((res) => {
          setHideIp(false);
          form.setFieldsValue({
            userType: UserType.IP,
            nameOrg: res.data.suggestions[0].data.name.short_with_opf,
            fullNameOrg: res.data.suggestions[0].data.name.full_with_opf,
            licenceOrg: '',
            oop: res.data.suggestions[0].data.opf.full,
            ogrn: res.data.suggestions[0].data.ogrn,
            regOrg: dateStandartFormat(
              res.data.suggestions[0].data.state.registration_date,
            ),
            addressOrg: res.data.suggestions[0].data.address.value,
            lenderEmail: res.data.suggestions[0].data.emails
              ? res.data.suggestions[0].data.emails[0].value
              : '',
            lenderPhone: res.data.suggestions[0].data.phones
              ? res.data.suggestions[0].data.phones[0].value
              : '',
          });

          form.setFieldsValue({
            lenderFirstName: res.data.suggestions[0].data.fio.name,
            lenderLastName: res.data.suggestions[0].data.fio.surname,
            lenderMiddleName: res.data.suggestions[0].data.fio.patronymic,
            lenderInn: value,
          });

          // setCantEdit(true);
        });
    }
  };

  const handleUpload = async (file: File | null | Blob) => {
    const formData = new FormData();
    setLoading(true);
    if (file instanceof File) {
      formData.append('image', file, file.name);
    } else if (file) {
      formData.append('image', file);
    }

    // formData.append('token', '3330064408517a29ec114c14a2be4080');
    formData.append('token', '9447574a3527cd997e51e79080de31fc');

    try {
      const response = await axios.post(
        'https://api.ocr.ads-soft.ru/recognition/',

        formData,
      );
      const data = response.data.data[0].data.results;
      for (const passportData of data) {
        switch (passportData.label) {
          case 'birth_date':
            form.setFieldsValue({
              lenderDateOfBirth: formatDate(passportData.text),
            });
            break;
          case 'birth_place':
            form.setFieldsValue({
              lenderPlaceOfBirth: passportData.text,
            });
            break;
          case 'serial_1':
            form.setFieldsValue({
              lenderPassportNumber: setPassNumber(passportData.text),
            });
            break;
          case 'issued_date':
            form.setFieldsValue({
              lenderPassportIssue: formatDate(passportData.text),
            });
            break;
          case 'issued':
            form.setFieldsValue({
              lenderPassportIssueBy: passportData.text,
            });
            break;
          case 'issued_number':
            form.setFieldsValue({
              lenderPassportIssueCode: passportData.text,
            });
            break;
          case 'name':
            form.setFieldsValue({
              lenderFirstName: passportData.text,
            });

            break;
          case 'middlename':
            form.setFieldsValue({
              lenderMiddleName: passportData.text,
            });

            break;
          case 'lastname':
            form.setFieldsValue({
              lenderLastName: passportData.text,
            });
            break;
          case 'sex':
            form.setFieldsValue({
              lenderGender: passportData.text === 'МУЖ' ? 'Мужской' : 'Женский',
            });
            break;

          default:
            break;
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onSave = async (value: any) => {
    if (notify) {
      setLoading(true);
      try {
        const response = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/offers/create',
          props.uuid
            ? { ...value, lenderFounders: founders, uuid: props.uuid }
            : { ...value, lenderFounders: founders },
        );
        setTimeout(async () => {
          notify({
            description: 'Удачно',
            message: 'Данные сохранены',
            type: 'success',
            key: '2',
            undoableTimeout: 20000,
          });

          await setLoading(false);
          await props.setUuid(response.data.uuid);
          await props.setStep(1);
        }, 2000);
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: 'Возникла проблема , сообщите сис-админу',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
        setTimeout(async () => {
          setLoading(false);
        }, 2000);
      }
    }
  };

  useEffect(() => {
    if (props.uuid) {
      (async () => {
        if (notify) {
          setLoading(true);
          try {
            const response = await axiosInstance.get(
              process.env.REACT_APP_BACKEND_URL +
                '/api/v1/offers/' +
                props.uuid,
            );
            const data = await response.data;

            if (data.innOrg.length === 10) {
              setHideIp(true);

              setUserType(UserType.UL);
              form.setFieldsValue({
                directorPos: data.directorPos,
                fullAddressOrg: data.fullAddressOrg,
                fullNameOrg: data.fullNameOrg,
                addressOrg: data.addressOrg,
                innOrg: data.innOrg,
                kapitalOrg: data.kapitalOrg,
                kppOrg: data.kppOrg,
                lenderAddressFact: data.lenderAddressFact,
                lenderAddressRegistration: data.lenderAddressRegistration,
                lenderDateOfBirth: moment(
                  data.lenderDateOfBirth,
                  'YYYY-MM-DD',
                ).format('DD.MM.YYYY'),
                lenderEmail: data.lenderEmail,
                lenderFirstName: data.lenderFirstName,
                lenderGender: data.lenderGender,
                lenderIssueAccNumber: data.lenderIssueAccNumber,
                lenderIssueBank: data.lenderIssueBank,
                lenderIssueBik: data.lenderIssueBik,
                lenderIssueCorNumber: data.lenderIssueCorNumber,
                lenderLastName: data.lenderLastName,
                lenderMiddleName: data.lenderMiddleName,
                lenderPassportIssue: moment(
                  data.lenderPassportIssue,
                  'YYYY-MM-DD',
                ).format('DD.MM.YYYY'),
                lenderPassportIssueBy: data.lenderPassportIssueBy,
                lenderPassportIssueCode: data.lenderPassportIssueCode,
                lenderPassportNumber: data.lenderPassportNumber,
                lenderPhone: data.lenderPhone,
                lenderPlaceOfBirth: data.lenderPlaceOfBirth,
                lenderSnils: data.lenderSnils,
                lenderСitizenship: data.lenderСitizenship,
                licenceOrg: data.licenceOrg,
                nameOrg: data.nameOrg,
                ogrn: data.ogrn,
                regOrg: data.regOrg,
                userType: data.userType,
                oop: data.oop,
                manager: data.manager,
                lenderInn: data.lenderInn,
              });
              setFounders(JSON.parse(data.lenderFounders));
            } else {
              setHideIp(false);
              setUserType(UserType.IP);
              form.setFieldsValue({
                fullAddressOrg: data.fullAddressOrg,
                fullNameOrg: data.fullNameOrg,
                addressOrg: data.addressOrg,
                innOrg: data.innOrg,
                lenderAddressFact: data.lenderAddressFact,
                lenderAddressRegistration: data.lenderAddressRegistration,
                lenderDateOfBirth: moment(
                  data.lenderDateOfBirth,
                  'YYYY-MM-DD',
                ).format('DD.MM.YYYY'),
                lenderEmail: data.lenderEmail,
                lenderFirstName: data.lenderFirstName,
                lenderGender: data.lenderGender,
                lenderIssueAccNumber: data.lenderIssueAccNumber,
                lenderIssueBank: data.lenderIssueBank,
                lenderIssueBik: data.lenderIssueBik,
                lenderIssueCorNumber: data.lenderIssueCorNumber,
                lenderLastName: data.lenderLastName,
                lenderMiddleName: data.lenderMiddleName,
                lenderPassportIssue: moment(
                  data.lenderPassportIssue,
                  'YYYY-MM-DD',
                ).format('DD.MM.YYYY'),
                lenderPassportIssueBy: data.lenderPassportIssueBy,
                lenderPassportIssueCode: data.lenderPassportIssueCode,
                lenderPassportNumber: data.lenderPassportNumber,
                lenderPhone: data.lenderPhone,
                lenderPlaceOfBirth: data.lenderPlaceOfBirth,
                lenderSnils: data.lenderSnils,
                lenderСitizenship: data.lenderСitizenship,
                licenceOrg: data.licenceOrg,
                nameOrg: data.nameOrg,
                ogrn: data.ogrn,
                regOrg: data.regOrg,
                userType: data.userType,
                oop: data.oop,
                lenderInn: data.lenderInn,
              });
            }
            await setLoading(false);
          } catch (e) {
            notify({
              description: 'Ошибка',
              message: 'Возникла проблема , сообщите сис-админу',
              type: 'error',
              key: '2',
              undoableTimeout: 20000,
            });
            setTimeout(async () => {
              setLoading(false);
            }, 2000);
          }
        }
      })();
    }
  }, [props.uuid]);

  return (
    // <div style={{ padding: '16px 0px' }}>
    <Spin tip="Сохранение данных..." spinning={loading}>
      <Form form={form} layout="vertical" onFinish={onSave}>
        <Card
          style={{ marginTop: '16px' }}
          size="small"
          hoverable
          title={<Text type="secondary">Заполнение информации о компании</Text>}
        >
          <Row gutter={[12, 12]}>
            <Col xs={8}>
              <Form.Item
                hasFeedback
                label="тип"
                name="userType"
                hidden
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                hasFeedback
                label="ИНН организации"
                name="innOrg"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input onChange={getDadataInn} allowClear />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                hasFeedback
                label="Название организации"
                name="nameOrg"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                hasFeedback
                label="Полное название организации"
                name="fullNameOrg"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Form.Item
                hasFeedback
                label="Наличие лицензии"
                name="licenceOrg"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                hasFeedback
                label="Организационно-правовая ответственность"
                name="oop"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col xs={6}>
              <Form.Item
                hasFeedback
                label="ОГРН"
                name="ogrn"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                hasFeedback
                label="ИНН"
                name="innOrg"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            {hideIp && (
              <Col xs={6}>
                <Form.Item
                  hasFeedback
                  label="КПП"
                  name="kppOrg"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
            <Col xs={6}>
              <Form.Item
                hasFeedback
                label="Дата регистрации"
                name="regOrg"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputMask placeholder={''} mask="99.99.9999">
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input allowClear {...inputProps} />}
                </InputMask>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                hasFeedback
                label="Юридический адрес"
                name="addressOrg"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Фактический адрес">
                <div style={{ display: 'flex' }}>
                  <Button
                    size="middle"
                    style={{ marginRight: 10 }}
                    icon={<ArrowDownOutlined />}
                    onClick={() => {
                      form.setFieldsValue({
                        fullAddressOrg: form.getFieldValue('addressOrg'),
                      });
                    }}
                  >
                    Совпадает
                  </Button>
                  <Form.Item
                    style={{ display: 'inline-block', width: '100%' }}
                    name="fullAddressOrg"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <AutoComplete
                      size={'middle'}
                      onSearch={handleSearch}
                      placeholder=""
                      options={options}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>
            {hideIp && (
              <>
                <Col xs={4}>
                  <Form.Item
                    hasFeedback
                    label="Уставной капитал"
                    name="kapitalOrg"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    hasFeedback
                    label="Должность руководителя"
                    name="directorPos"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    hasFeedback
                    label="Руководитель"
                    name="manager"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Card>
        {userType === UserType.UL && (
          <Card
            style={{ marginTop: '16px' }}
            size="small"
            hoverable
            title={
              <Text type="secondary">
                Участники (собственники) организации Заемщика
              </Text>
            }
          >
            {founders &&
              founders.map((founder, id) => {
                return (
                  <div key={id}>
                    <Divider orientation="left" style={{ margin: 0 }}>
                      <Text type="secondary">Участники {+id + 1}</Text>
                    </Divider>
                    <Row gutter={[12, 12]}>
                      <Col xs={24} md={12}>
                        <Form.Item
                          hasFeedback
                          label="ФИО"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input readOnly value={founder.fio} />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          hasFeedback
                          label="Процент в компании"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input readOnly value={founder.value} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </Card>
        )}
        <Card
          style={{ marginTop: '16px' }}
          size="small"
          hoverable
          title={<Text type="secondary">Банковские данные</Text>}
        >
          <Row gutter={[12, 12]}>
            <Col xs={12}>
              <Form.Item
                hasFeedback
                label="БИК"
                name="lenderIssueBik"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input onInput={dadataBank} />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                hasFeedback
                label="Расчетный счет"
                name="lenderIssueAccNumber"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                hasFeedback
                label="Наименование банка"
                name="lenderIssueBank"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                hasFeedback
                label="Корр. счет"
                name="lenderIssueCorNumber"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card
          style={{ marginTop: '16px' }}
          size="small"
          hoverable
          title={<Text type="secondary">Контакты</Text>}
        >
          <Row gutter={[12, 12]}>
            <Col xs={12}>
              <Form.Item
                hasFeedback
                label="Email"
                name="lenderEmail"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                hasFeedback
                label="Телефон"
                name="lenderPhone"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card
          style={{ marginTop: '16px' }}
          size="small"
          hoverable
          title={
            <Text type="secondary">Заполнение информации о руководителе</Text>
          }
        >
          <Alert
            style={{ marginBottom: 12 }}
            message="Заполнение информации"
            description={
              <Space direction="vertical">
                <Text>
                  Вы можете воспользоваться сервисом распознования изображений
                </Text>
                <Upload
                  beforeUpload={(file) => {
                    handleUpload(file);
                    return false;
                  }}
                >
                  <Button type="dashed">Загрузить фото паспорта</Button>
                </Upload>
              </Space>
            }
            type="info"
            showIcon
          />
          <Row gutter={[12, 12]}>
            <Col xs={6}>
              <Form.Item
                hasFeedback
                label="Фамилия"
                name="lenderLastName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                hasFeedback
                label="Имя"
                name="lenderFirstName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                hasFeedback
                label="Отчество"
                name="lenderMiddleName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                hasFeedback
                label="Дата рождения"
                name="lenderDateOfBirth"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) => {
                      const date = moment(value, 'DD-MM-YYYY').toDate();
                      date.setFullYear(date.getFullYear() + 18);
                      const today = new Date();
                      if (date > today) {
                        return Promise.reject(
                          new Error('Регистрация возможна с 18 лет'),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <InputMask placeholder={''} mask="99.99.9999">
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input allowClear {...inputProps} />}
                </InputMask>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            hasFeedback
            label="Место рождения"
            name="lenderPlaceOfBirth"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Row gutter={[12, 12]}>
            <Col xs={6}>
              <Form.Item
                hasFeedback
                label="Серия и № паспорта"
                name="lenderPassportNumber"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputMask
                  placeholder={''}
                  mask="серия: 9999, № 999999"
                  // maskChar=' '
                >
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input allowClear {...inputProps} />}
                </InputMask>
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                hasFeedback
                label="Дата выдачи"
                name="lenderPassportIssue"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputMask
                  placeholder={''}
                  mask="99.99.9999"
                  // maskChar=' '
                >
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input allowClear {...inputProps} />}
                </InputMask>
              </Form.Item>
            </Col>
            <Col xs={3}>
              <Form.Item
                hasFeedback
                label="Гражданство"
                name="lenderСitizenship"
                initialValue={'РФ'}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={3}>
              <Form.Item
                hasFeedback
                label="Пол"
                name="lenderGender"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  options={[{ value: 'Мужской' }, { value: 'Женский' }]}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                hasFeedback
                label="Код подразделения"
                name="lenderPassportIssueCode"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            hasFeedback
            label="Кем выдан"
            name="lenderPassportIssueBy"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            hasFeedback
            label="ИНН"
            name="lenderInn"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Адрес регистрации (город, улица, дом)"
            name="lenderAddressRegistration"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AutoComplete
              onSearch={handleSearch}
              placeholder=""
              options={options}
            />
          </Form.Item>
          <Form.Item label="Фактический адрес">
            <div style={{ display: 'flex' }}>
              <Button
                size="middle"
                style={{ marginRight: 10 }}
                icon={<ArrowDownOutlined />}
                onClick={() => {
                  form.setFieldsValue({
                    lenderAddressFact: form.getFieldValue(
                      'lenderAddressRegistration',
                    ),
                  });
                }}
              >
                Совпадает
              </Button>
              <Form.Item
                style={{ display: 'inline-block', width: '100%' }}
                name="lenderAddressFact"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message:
                      'Пожалуйста, введите фактический адрес регистрации',
                  },
                ]}
              >
                <AutoComplete
                  size={'middle'}
                  onSearch={handleSearch}
                  placeholder=""
                  options={options}
                />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item>
            <Button
              style={{ marginLeft: 'auto', display: 'flex' }}
              type="primary"
              htmlType="submit"
            >
              Сохранить и продолжить
            </Button>
          </Form.Item>
        </Card>
      </Form>
    </Spin>
    // </div>
  );
};
