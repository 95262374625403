import React, { useEffect, useState } from 'react';
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useNavigation,
  useNotification,
} from '@pankod/refine-core';
import {
  Alert,
  AutoComplete,
  Button,
  Checkbox,
  Divider,
  Edit,
  Form,
  Input,
  ListButton,
  RcFile,
  Select,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  useFileUploadState,
  useForm,
} from '@pankod/refine-antd';
import InputMask from 'react-input-mask';
import 'react-mde/lib/styles/css/react-mde-all.css';

import { IPicture, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import { Role } from '../../enums/role';
import { FormRule, InputProps, InputRef } from 'antd';
import { axiosInstance } from '../../App';
import { UserType } from '../../enums/user.type';
import moment from 'moment';
import ImgCrop from 'antd-img-crop';
import { DocumentType } from '../../enums/document.type';
import { NbkiRating } from '../../enums/nbki.rating';
import { normalizeFile } from '../../utility/normalize';
import dadataAddress from 'utility/dadata/address';
import { uuidV4 } from 'utility/uuidv4';
const { Text } = Typography;
const files: string[] = [];
function ShowAvatar(props: { avatar: any }) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const apiUrl = useApiUrl();
  useEffect(() => {
    if (props.avatar) {
      setFileList([
        {
          uid: props.avatar.origin,
          name: props.avatar.type,
          status: 'done',
          url: process.env.REACT_APP_BACKEND_URL + props.avatar.path,
        },
      ]);
    }
  }, []);
  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const { open: notify } = useNotification();
  const removeFile = async (file: any) => {
    let result;
    if (notify) {
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/media/remove-avatar',
          { file },
        );
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: '',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  return (
    <>
      <Divider orientation="left" plain>
        <Text type="secondary">Аватар / фото</Text>
      </Divider>
      <Alert
        style={{ marginBottom: 24 }}
        showIcon
        message={
          'Принимаются файлы формата png, jpg, и gif, размером до 15 Мб.'
        }
        type="info"
      />
      <ImgCrop>
        <Upload
          action={`${apiUrl}/media/upload`}
          headers={{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }}
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          onRemove={removeFile}
          data={() => {
            const fileUuid = uuidV4();
            return { fileUuid, type: DocumentType.AVATAR };
          }}
        >
          {fileList.length < 1 && '+ Загрузить'}
        </Upload>
      </ImgCrop>
    </>
  );
}

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const { data: me, refetch } = useGetIdentity<IUser>();
  const { open: notify } = useNotification();
  const { onChange: onChangeAux } = useFileUploadState();
  const { onChange: onChangeNbki } = useFileUploadState();
  const { list } = useNavigation();
  const [userType, setUserType] = useState<UserType>();
  const [role, setRole] = useState<Role>();
  const sendCode = async (values: any) => {
    if (notify) {
      setRegButton(true);
      let code;
      try {
        code = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/profile/send/code',
          {
            phone: phone ? phone : me?.phone,
          },
        );
        setTimeout(() => setSendDisabled(false), 30000);
        setSaveDisabled(false);
        notify({
          description: 'SMS с кодом отправлено',
          message:
            process.env.REACT_APP_PROD === '1'
              ? 'Код отправлен на указанный номер мобильного телефона'
              : 'Тестовый режим. Код: ' + code.data,
          type: 'success',
          key: '1',
          undoableTimeout: 20000,
        });
      } catch (e) {
        setRegButton(false);
        notify({
          description: 'Ошибка отправки кода',
          message:
            'Убедитесь что телефон введен правильно, возможно такой телефон уже зарегистрирован на платформе',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  const [phone, setPhone] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [regButton, setRegButton] = useState<boolean>(false);
  const [currentCode, setCurrentCode] = useState<boolean>(false);
  const [sendDisabled, setSendDisabled] = useState<boolean>(false);
  const [reason, setReason] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const { onChange } = useFileUploadState();
  const { onChange: onChange2 } = useFileUploadState();
  const { onChange: onChange3 } = useFileUploadState();
  const { onChange: onChange4 } = useFileUploadState();
  const { onChange: onChange5 } = useFileUploadState();
  const { onChange: onChange6 } = useFileUploadState();
  const {
    formProps,
    saveButtonProps,
    queryResult,
    form: formProfile,
  } = useForm<IUser>({
    redirect: false,
    onMutationSuccess: async (data) => {
      setSaveDisabled(true);
      list('offers');
    },
    successNotification: {
      description: 'Данные успешно обновлены',
      message: 'Используйте ваш пароль для следующего входа в систему',
      type: 'success',
      key: '1',
      undoableTimeout: 20000,
    },
  });
  let publicFileList: UploadFile<any>[] | undefined,
    publicFileList2: UploadFile<any>[] | undefined,
    publicFileList3: UploadFile<any>[] | undefined,
    publicFileList4: UploadFile<any>[] | undefined,
    publicFileList5: UploadFile<any>[] | undefined,
    publicFileList6: UploadFile<any>[] | undefined;
  let nbkiFileList: UploadFile<any>[] | undefined,
    auxFileList: UploadFile<any>[] | undefined;
  let record: any;
  if (queryResult?.isSuccess) {
    record = queryResult.data.data;
    publicFileList = queryResult.data.data.pictures
      ?.filter((p) => p.type === DocumentType.DIRECTOR_PASSPORT)
      .map((picture: IPicture) => {
        return {
          uid: picture.id,
          name: picture.path,
          status: 'done',
          url: picture.path,
        };
      });
    publicFileList2 = queryResult.data.data.pictures
      ?.filter((p) => p.type === DocumentType.OWNER_PASSPORT)
      .map((picture: IPicture) => {
        return {
          uid: picture.id,
          name: picture.path,
          status: 'done',
          url: picture.path,
        };
      });
    publicFileList3 = queryResult.data.data.pictures
      ?.filter((p) => p.type === DocumentType.REPORTS_1)
      .map((picture: IPicture) => {
        return {
          uid: picture.id,
          name: picture.path,
          status: 'done',
          url: picture.path,
        };
      });
    publicFileList4 = queryResult.data.data.pictures
      ?.filter((p) => p.type === DocumentType.REPORTS_2)
      .map((picture: IPicture) => {
        return {
          uid: picture.id,
          name: picture.path,
          status: 'done',
          url: picture.path,
        };
      });
    publicFileList5 = queryResult.data.data.pictures
      ?.filter((p) => p.type === DocumentType.REPORTS_3)
      .map((picture: IPicture) => {
        return {
          uid: picture.id,
          name: picture.path,
          status: 'done',
          url: picture.path,
        };
      });
    publicFileList6 = queryResult.data.data.pictures
      ?.filter((p) => p.type === DocumentType.REPORTS_4)
      .map((picture: IPicture) => {
        return {
          uid: picture.id,
          name: picture.path,
          status: 'done',
          url: picture.path,
        };
      });
    nbkiFileList = queryResult.data.data.pictures
      ?.filter((p) => p.type === DocumentType.NBKI)
      .map((picture: IPicture) => {
        return {
          uid: picture.id,
          name: picture.path,
          status: 'done',
          url: picture.path,
        };
      });
    auxFileList = queryResult.data.data.pictures
      ?.filter((p) => p.type === DocumentType.AUX)
      .map((picture: IPicture) => {
        return {
          uid: picture.id,
          name: picture.path,
          status: 'done',
          url: picture.path,
        };
      });
  }
  useEffect(() => {
    setUserType(record?.type);
  }, []);
  const removeFile = async (file: any) => {
    let result;
    if (notify) {
      try {
        result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/media/remove',
          { file },
        );
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: '',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  const {
    formProps: props,
    saveButtonProps: saveProps,
    form,
  } = useForm<IUser>({
    successNotification: {
      description: 'Пользователь сохранен',
      message: 'Просматривайте пользователей в разделе Пользователи',
      type: 'success',
    },
  });
  const onPhone = (event: any) => {
    setPhone(event.target.value);
  };
  const onPass = (event: any) => {
    setPassword(event.target.value);
  };
  let formValues;
  if (me) {
    formValues = {
      firstName: me.firstName,
      middleName: me.middleName,
      lastName: me.lastName,

      issueDate: '',
      dateOfBirth: '',
      code: '',
      phone: me?.phone,
      editMyself: true,
    };
  }
  const dadataBank = (event: any) => {
    if (event.target.value.length >= 9) {
      axiosInstance
        .get(
          process.env.REACT_APP_BACKEND_URL +
            '/api/v1/dadata/bank/' +
            event.target.value,
        )
        .then((res) => {
          form.setFieldsValue({
            bankName: res.data.suggestions[0].data.name.short
              ? res.data.suggestions[0].data.name.short
              : res.data.suggestions[0].data.name.payment,
            bankCorNumber: res.data.suggestions[0].data.correspondent_account,
          });
          formProfile.setFieldsValue({
            bankName: res.data.suggestions[0].data.name.short
              ? res.data.suggestions[0].data.name.short
              : res.data.suggestions[0].data.name.payment,
            bankCorNumber: res.data.suggestions[0].data.correspondent_account,
          });
        });
    }
  };
  const dadataOrg = (event: any) => {
    if (event.target.value.length >= 10) {
      axiosInstance
        .get(
          process.env.REACT_APP_BACKEND_URL +
            '/api/v1/dadata/' +
            event.target.value,
        )
        .then((res) => {
          form.setFieldsValue({
            revenue: +res.data.suggestions[0].data.finance.income,
          });
          form.setFieldsValue({
            income:
              +res.data.suggestions[0].data.finance.income -
              res.data.suggestions[0].data.finance.expense,
          });
          form.setFieldsValue({
            infoDebt: res.data.suggestions[0].data.finance.debt,
          });
          form.setFieldsValue({
            infoFounders: JSON.stringify(res.data.suggestions[0].data.founders),
          });
          form.setFieldsValue({
            regDate: new Date(
              res.data.suggestions[0].data.state.registration_date,
            ),
          });
          form.setFieldsValue({
            infoTax: res.data.suggestions[0].data.finance.tax_system,
          });
          form.setFieldsValue({
            inn: res.data.suggestions[0].data.managers[0].inn,
          });
          form.setFieldsValue({
            firstName: res.data.suggestions[0].data.managers[0].fio.name,
          });
          form.setFieldsValue({
            middleName: res.data.suggestions[0].data.managers[0].fio.patronymic,
          });
          form.setFieldsValue({
            lastName: res.data.suggestions[0].data.managers[0].fio.surname,
          });
          form.setFieldsValue({
            infoActivities: res.data.suggestions[0].data.okveds[0].name,
          });
          form.setFieldsValue({ orgOgrn: res.data.suggestions[0].data.ogrn });
          form.setFieldsValue({ kpp: res.data.suggestions[0].data.kpp });
          form.setFieldsValue({ orgName: res.data.suggestions[0].value });
          form.setFieldsValue({
            orgLocation: res.data.suggestions[0].data.address.value,
          });
          form.setFieldsValue({
            ipRegDate: moment(res.data.suggestions[0].data.ogrn_date).format(
              'DD.MM.YYYY',
            ),
          });
          formProfile.setFieldsValue({
            revenue: +res.data.suggestions[0].data.finance.income,
          });
          formProfile.setFieldsValue({
            income:
              +res.data.suggestions[0].data.finance.income -
              res.data.suggestions[0].data.finance.expense,
          });
          formProfile.setFieldsValue({
            infoDebt: res.data.suggestions[0].data.finance.debt,
          });
          formProfile.setFieldsValue({
            infoFounders: JSON.stringify(res.data.suggestions[0].data.founders),
          });
          formProfile.setFieldsValue({
            regDate: new Date(
              res.data.suggestions[0].data.state.registration_date,
            ),
          });
          formProfile.setFieldsValue({
            infoTax: res.data.suggestions[0].data.finance.tax_system,
          });
          formProfile.setFieldsValue({
            inn: res.data.suggestions[0].data.managers[0].inn,
          });
          formProfile.setFieldsValue({
            firstName: res.data.suggestions[0].data.managers[0].fio.name,
          });
          formProfile.setFieldsValue({
            middleName: res.data.suggestions[0].data.managers[0].fio.patronymic,
          });
          formProfile.setFieldsValue({
            lastName: res.data.suggestions[0].data.managers[0].fio.surname,
          });
          formProfile.setFieldsValue({
            infoActivities: res.data.suggestions[0].data.okveds[0].name,
          });
          formProfile.setFieldsValue({
            orgOgrn: res.data.suggestions[0].data.ogrn,
          });
          formProfile.setFieldsValue({ kpp: res.data.suggestions[0].data.kpp });
          formProfile.setFieldsValue({
            orgName: res.data.suggestions[0].value,
          });
          formProfile.setFieldsValue({
            orgLocation: res.data.suggestions[0].data.address.value,
          });
          formProfile.setFieldsValue({
            ipRegDate: moment(res.data.suggestions[0].data.ogrn_date).format(
              'DD.MM.YYYY',
            ),
          });
        });
    }
  };
  const dadataPersonal = (event: any) => {
    if (event.target.value.length >= 12 && userType === UserType.IP) {
      axiosInstance
        .get(
          process.env.REACT_APP_BACKEND_URL +
            '/api/v1/dadata/' +
            event.target.value,
        )
        .then((res) => {
          form.setFieldsValue({ orgOgrn: res.data.suggestions[0].data.ogrn });
          form.setFieldsValue({
            ipRegDate: moment(res.data.suggestions[0].data.ogrn_date).format(
              'DD.MM.YYYY',
            ),
          });
          form.setFieldsValue({ orgName: res.data.suggestions[0].value });
          form.setFieldsValue({
            orgLocation: res.data.suggestions[0].data.address.value,
          });
          form.setFieldsValue({
            firstName: res.data.suggestions[0].data.fio.name,
          });
          form.setFieldsValue({
            middleName: res.data.suggestions[0].data.fio.patronymic,
          });
          form.setFieldsValue({
            lastName: res.data.suggestions[0].data.fio.surname,
          });
          form.setFieldsValue({
            infoActivities: res.data.suggestions[0].data.okveds[0].name,
          });
          formProfile.setFieldsValue({
            orgOgrn: res.data.suggestions[0].data.ogrn,
          });
          formProfile.setFieldsValue({
            ipRegDate: moment(res.data.suggestions[0].data.ogrn_date).format(
              'DD.MM.YYYY',
            ),
          });
          formProfile.setFieldsValue({
            orgName: res.data.suggestions[0].value,
          });
          formProfile.setFieldsValue({
            orgLocation: res.data.suggestions[0].data.address.value,
          });
          formProfile.setFieldsValue({
            firstName: res.data.suggestions[0].data.fio.name,
          });
          formProfile.setFieldsValue({
            middleName: res.data.suggestions[0].data.fio.patronymic,
          });
          formProfile.setFieldsValue({
            lastName: res.data.suggestions[0].data.fio.surname,
          });
          formProfile.setFieldsValue({
            infoActivities: res.data.suggestions[0].data.okveds[0].name,
          });
        });
    }
  };

  let formData, dirInitValues, avatar, isReady;
  if (queryResult?.isSuccess) {
    formData = queryResult.data.data;
    avatar = formData?.pictures?.find((p) => p.type === DocumentType.AVATAR);
    isReady = true;
    dirInitValues = {
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,

      issueDate: '',
      dateOfBirth: '',
    };
  }
  const innRule: FormRule = {
    type: 'string',
    min: 12,
    max: 12,
    message: 'ИНН физлица содержит 12 цифр',
  };
  const orgInnRule: FormRule = {
    type: 'string',
    min: 10,
    max: 10,
    message: 'ИНН юрлица содержит 10 цифр',
  };
  const orgOgrnRule: FormRule = {
    type: 'string',
    min: 13,
    max: 13,
    message: 'ОГРН юрлица содержит 13 цифр',
  };
  const ogrnipRule: FormRule = {
    type: 'string',
    min: 15,
    max: 15,
    message: 'ОГРНИП содержит 15 цифр',
  };
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );
  const handleSearch = async (value: string) => {
    let res: { value: string; label: string }[] = [];
    const dadataSugs = await dadataAddress(value);
    res = dadataSugs.map((s: any) => {
      return {
        value: s.unrestricted_value,
        label: s.unrestricted_value,
        disabled: !s.data.house,
      };
    });
    setOptions(res);
  };

  const userStatus =
    record?.role === Role.AGENT
      ? [
          {
            label: UserStatus.DECLINED,
            value: UserStatus.DECLINED,
          },
          {
            label: UserStatus.ON_SIGN,
            value: UserStatus.ON_SIGN,
          },
        ]
      : [
          {
            label: UserStatus.DECLINED,
            value: UserStatus.DECLINED,
          },
          {
            label: UserStatus.CLIENT,
            value: UserStatus.CLIENT,
          },
        ];

  return (
    <>
      {me?.role === Role.SDL && (
        <Edit
          headerProps={{
            subTitle: '',
            breadcrumb: <></>,
            extra: <ListButton />,
          }}
          title="Править пользователя"
          saveButtonProps={{ ...saveProps, children: 'Сохранить' }}
        >
          <Form {...props} layout="vertical">
            <Form.Item
              label="Статус"
              name="status"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                onChange={(value, b) => {
                  if (value === UserStatus.DECLINED) {
                    setReason(true);
                  } else {
                    setReason(false);
                  }
                }}
                options={userStatus}
              />
            </Form.Item>
            {reason && (
              <Form.Item
                label="Причина отклонения идентификации"
                name="declineReason"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input.TextArea showCount maxLength={3000} rows={10} />
              </Form.Item>
            )}
            <Form.Item
              label=""
              name="terrorist"
              valuePropName="checked"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Checkbox>
                Заморожен в связи с наличием в списках террористов и
                экстремистов
              </Checkbox>
            </Form.Item>
          </Form>
        </Edit>
      )}
      {(me?.role === Role.DIRECTOR ||
        me?.role === Role.UNDERWRITER ||
        me?.role === Role.AGENT) &&
        formData &&
        me.uuid !== record?.uuid && (
          <Edit
            headerProps={{
              subTitle: '',
              breadcrumb: <></>,
              extra: <ListButton />,
            }}
            title="Править пользователя"
            saveButtonProps={{ ...saveProps, children: 'Сохранить' }}
          >
            <Form {...props} layout="vertical" initialValues={dirInitValues}>
              <Form.Item
                label="Статус"
                name="status"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select
                  onChange={(value, b) => {
                    if (value === UserStatus.DECLINED) {
                      setReason(true);
                    } else {
                      setReason(false);
                    }
                  }}
                  options={[
                    {
                      label: UserStatus.ACCOUNT,
                      value: UserStatus.ACCOUNT,
                    },
                    {
                      label: UserStatus.DECLINED,
                      value: UserStatus.DECLINED,
                    },
                    {
                      label: UserStatus.APPROVAL,
                      value: UserStatus.APPROVAL,
                    },
                    {
                      label: UserStatus.IDENTIFIED,
                      value: UserStatus.IDENTIFIED,
                    },
                    {
                      label: UserStatus.CLIENT,
                      value: UserStatus.CLIENT,
                    },
                    {
                      label: UserStatus.QUALIFIED,
                      value: UserStatus.QUALIFIED,
                    },
                  ]}
                />
              </Form.Item>
              {(me?.role === Role.DIRECTOR ||
                me?.role === Role.UNDERWRITER) && (
                <>
                  <Alert
                    showIcon
                    message={'Загрузить документы НБКИ'}
                    description={
                      <>
                        Принимаются файлы формата png, jpg, gif и pdf, размером
                        до 15 Мб. На каждый вид документа можно загрузить
                        несколько файлов.
                        <br />
                      </>
                    }
                    type="info"
                  />
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Form.Item
                      name="imagesNbki"
                      valuePropName="fileListNbki"
                      normalize={normalizeFile}
                      style={{ width: 200, marginRight: 10 }}
                    >
                      <Upload.Dragger
                        name="file"
                        action={`${apiUrl}/media/upload`}
                        headers={{
                          Authorization: `Bearer ${localStorage.getItem(
                            'token',
                          )}`,
                        }}
                        listType="picture"
                        maxCount={20}
                        multiple
                        onChange={onChangeNbki}
                        onRemove={removeFile}
                        defaultFileList={nbkiFileList}
                        data={() => {
                          const fileUuid = uuidV4();
                          files.push(fileUuid);
                          form?.setFieldsValue({
                            files,
                          });
                          return { fileUuid, type: DocumentType.NBKI };
                        }}
                      >
                        <p
                          style={{ fontSize: 13, padding: '10px 10px 0 10px' }}
                        >
                          {DocumentType.NBKI}
                        </p>
                      </Upload.Dragger>
                    </Form.Item>
                    <Form.Item
                      name="imagesAux"
                      valuePropName="fileListAux"
                      normalize={normalizeFile}
                      style={{ width: 200, marginRight: 10 }}
                    >
                      <Upload.Dragger
                        name="file"
                        action={`${apiUrl}/media/upload`}
                        headers={{
                          Authorization: `Bearer ${localStorage.getItem(
                            'token',
                          )}`,
                        }}
                        listType="picture"
                        maxCount={20}
                        multiple
                        onChange={onChangeAux}
                        onRemove={removeFile}
                        defaultFileList={auxFileList}
                        data={() => {
                          const fileUuid = uuidV4();
                          files.push(fileUuid);
                          form?.setFieldsValue({
                            files,
                          });
                          return { fileUuid, type: DocumentType.AUX };
                        }}
                      >
                        <p
                          style={{ fontSize: 13, padding: '10px 10px 0 10px' }}
                        >
                          {DocumentType.AUX}
                        </p>
                      </Upload.Dragger>
                    </Form.Item>
                  </div>
                  <Form.Item
                    label="Рейтинг НБКИ директора"
                    name="directorNbkiRating"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Select
                      options={[
                        {
                          label: NbkiRating.POSITIVE,
                          value: NbkiRating.POSITIVE,
                        },
                        {
                          label: NbkiRating.MODERATE,
                          value: NbkiRating.MODERATE,
                        },
                        {
                          label: NbkiRating.NEGATIVE,
                          value: NbkiRating.NEGATIVE,
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Рейтинг НБКИ учредителя"
                    name="ownerNbkiRating"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Select
                      options={[
                        {
                          label: NbkiRating.POSITIVE,
                          value: NbkiRating.POSITIVE,
                        },
                        {
                          label: NbkiRating.MODERATE,
                          value: NbkiRating.MODERATE,
                        },
                        {
                          label: NbkiRating.NEGATIVE,
                          value: NbkiRating.NEGATIVE,
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label=""
                    name="terrorist"
                    valuePropName="checked"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Checkbox>
                      Заморожен в связи с наличием в списках террористов и
                      экстремистов
                    </Checkbox>
                  </Form.Item>
                </>
              )}
              {reason && (
                <Form.Item
                  label="Причина отклонения идентификации"
                  name="declineReason"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input.TextArea showCount maxLength={3000} rows={10} />
                </Form.Item>
              )}
              <Form.Item
                label="Тип участника платформы"
                name="role"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select
                  onChange={(role: Role) => {
                    setRole(role);
                  }}
                  options={[
                    {
                      label: Role.INVESTOR,
                      value: Role.INVESTOR,
                    },
                    {
                      label: Role.LENDER,
                      value: Role.LENDER,
                    },
                    {
                      label: Role.DUPLEX,
                      value: Role.DUPLEX,
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Организационно-правовая форма"
                name="type"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select
                  onChange={(type: UserType) => {
                    setUserType(type);
                  }}
                  options={[
                    {
                      label: UserType.IP,
                      value: UserType.IP,
                    },
                    {
                      label: UserType.UL,
                      value: UserType.UL,
                    },
                  ]}
                />
              </Form.Item>
              {formData?.role === Role.LENDER && (
                <>
                  <Divider orientation="left" plain>
                    <Text type="secondary">
                      Информация о лице, привлекающем инвестиции
                    </Text>
                  </Divider>
                  <Form.Item
                    label="Описание деятельности заемщика (будет доступно инвестору)"
                    name="infoDescription"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input.TextArea showCount maxLength={3000} rows={5} />
                  </Form.Item>
                  <Form.Item
                    label="Сведения о лицах, имеющих право распоряжаться не менее чем 10 процентами голосов в высшем органе управления юридического лица, если таким лицом является корпорация"
                    name="infoPercent"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Сведения о структуре и персональном составе органов управления юридического лица"
                    name="infoStructure"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Основные виды деятельности лица, привлекающего инвестиции"
                    name="infoActivities"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Сведения о фактах (событиях, действиях), которые могут оказать существенное влияние на исполнение лицом, привлекающим инвестиции, обязательств перед инвесторами"
                    name="infoFacts"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input defaultValue={'Отсутствуют'} />
                  </Form.Item>
                  <Form.Item
                    label="Сведения о суммах инвестиций, привлеченных лицом, привлекающим инвестиции, в инвестиционной платформе в текущем календарном году, а также о максимальном объеме денежных средств, указанном в каждом действующем инвестиционном предложении в этой инвестиционной платформе, по достижении которого инвестиционное предложение прекращается"
                    name="infoSums"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input defaultValue={'0 рублей'} />
                  </Form.Item>
                  <Form.Item
                    label="Лицензия на осуществление деятельности (в случае лицензирования)"
                    name="licenseInfo"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input defaultValue={'Отсутствуют'} />
                  </Form.Item>
                  <Form.Item
                    label="Информация, позволяющая составить общее представление о целях привлечения инвестиций и об обстоятельствах, которые могут оказать влияние на достижение указанных целей"
                    name="infoPurpose"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input defaultValue={'Пополнение оборотных средств'} />
                  </Form.Item>
                  <Form.Item
                    label="Предупреждение о рисках, связанных с лицом, привлекающим инвестиции, и рисках, связанных с принятием инвестиционного предложения"
                    name="infoRisks"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input defaultValue={'Предупрежден'} />
                  </Form.Item>
                  <Form.Item
                    label="Сведения об экспертах, привлеченных для мониторинга и оценки деятельности Лица, привлекающего инвестиции (при наличии)"
                    name="infoExperts"
                    hasFeedback
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input defaultValue={'Отсутствуют'} />
                  </Form.Item>
                </>
              )}

              {userType === UserType.UL && (
                <Divider orientation="left" plain>
                  <Text type="secondary">Единоличный исполнительный орган</Text>
                </Divider>
              )}
              {userType === UserType.IP && (
                <Divider orientation="left" plain>
                  <Text type="secondary">Индивидуальный предприниматель</Text>
                </Divider>
              )}
              <Form.Item
                label="Фамилия"
                name="lastName"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Имя"
                name="firstName"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Отчество"
                name="middleName"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="ИНН"
                name="inn"
                rules={[
                  {
                    required: false,
                  },
                  innRule,
                ]}
              >
                <Input onInput={dadataPersonal} maxLength={innRule.max} />
              </Form.Item>
              <Form.Item
                label="Дата рождения"
                name="dateOfBirth"
                rules={[
                  {
                    required: false,
                  },
                  {
                    validator: (_, value) => {
                      const date = moment(value, 'DD-MM-YYYY').toDate();
                      date.setFullYear(date.getFullYear() + 18);
                      const today = new Date();
                      if (date > today) {
                        return Promise.reject(
                          new Error('Регистрация возможна с 18 лет'),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <InputMask placeholder={''} mask="99.99.9999">
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input allowClear {...inputProps} />}
                </InputMask>
              </Form.Item>
              <Form.Item
                label="Место рождения"
                name="placeOfBirth"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Адрес регистрации (город, улица, дом)"
                name="address"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <AutoComplete
                  onSearch={handleSearch}
                  placeholder=""
                  options={options}
                />
              </Form.Item>
              <Form.Item
                label="Адрес фактического местонахождения (город, улица, дом)"
                name="factAddress"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <AutoComplete
                  onSearch={handleSearch}
                  placeholder=""
                  options={options}
                />
              </Form.Item>
              <Form.Item
                label="Серия и № паспорта"
                name="passportNumber"
                hasFeedback
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <InputMask
                  placeholder={''}
                  mask="серия: 9999, № 999999"
                  // maskChar=' '
                >
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input allowClear {...inputProps} />}
                </InputMask>
              </Form.Item>
              <Form.Item
                label="Дата выдачи"
                name="issueDate"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <InputMask
                  placeholder={''}
                  mask="99.99.9999"
                  // maskChar=' '
                >
                  {(
                    inputProps: JSX.IntrinsicAttributes &
                      InputProps &
                      React.RefAttributes<InputRef>,
                  ) => <Input allowClear {...inputProps} />}
                </InputMask>
              </Form.Item>
              <Form.Item
                label="Кем выдан"
                name="passportIssuedBy"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Код подразделения"
                name="passportCodeDepartment"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {userType === UserType.UL && (
                <>
                  <Divider orientation="left" plain>
                    <Text type="secondary">Информация об организации</Text>
                  </Divider>
                  <Form.Item
                    label="ИНН юридического лица"
                    name="orgInn"
                    rules={[
                      {
                        required: false,
                      },
                      orgInnRule,
                    ]}
                  >
                    <Input onInput={dadataOrg} maxLength={orgInnRule.max} />
                  </Form.Item>
                  <Form.Item
                    label="КПП"
                    name="kpp"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="ОГРН юридического лица"
                    name="orgOgrn"
                    rules={[
                      {
                        required: false,
                      },
                      orgOgrnRule,
                    ]}
                  >
                    <Input maxLength={orgOgrnRule.max} />
                  </Form.Item>
                  <Form.Item
                    label="Наименование юридического лица"
                    name="orgName"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Месторасположение организации"
                    name="orgLocation"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <AutoComplete
                      onSearch={handleSearch}
                      placeholder=""
                      options={options}
                    />
                  </Form.Item>
                </>
              )}
              {userType === UserType.IP && (
                <>
                  <Divider orientation="left" plain>
                    <Text type="secondary">
                      Информация об индивидуальном предпринимателе
                    </Text>
                  </Divider>
                  <Form.Item
                    label="ОГРНИП"
                    name="orgOgrn"
                    rules={[
                      {
                        required: false,
                      },
                      ogrnipRule,
                    ]}
                  >
                    <Input maxLength={ogrnipRule.max} />
                  </Form.Item>
                  <Form.Item
                    label="Дата регистрации ИП"
                    name="ipRegDate"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <InputMask placeholder={''} mask="99.99.9999">
                      {(
                        inputProps: JSX.IntrinsicAttributes &
                          InputProps &
                          React.RefAttributes<InputRef>,
                      ) => <Input allowClear {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                  <Form.Item
                    label="Наименование организации"
                    name="orgName"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Месторасположение организации"
                    name="orgLocation"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <AutoComplete onSearch={handleSearch} options={options} />
                  </Form.Item>
                </>
              )}
              <Divider orientation="left" plain>
                <Text type="secondary">Банковские реквизиты</Text>
              </Divider>
              <Form.Item
                label="БИК"
                name="bik"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input onInput={dadataBank} />
              </Form.Item>
              <Form.Item
                label="Расчетный счет"
                name="bankAccNumber"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Наименование банка"
                name="bankName"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Корр. счет"
                name="bankCorNumber"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Alert
                showIcon
                message={'Необходимо приложить документы'}
                description={
                  <>
                    Принимаются файлы формата png, jpg, gif и pdf, размером до
                    15 Мб. На каждый вид документа можно загрузить несколько
                    файлов.
                    <br />
                  </>
                }
                type="info"
              />
              <br />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  flexWrap: 'wrap',
                }}
              >
                <Form.Item
                  name="images"
                  valuePropName="fileList"
                  normalize={normalizeFile}
                  style={{ width: 200, marginRight: 10 }}
                >
                  <Upload.Dragger
                    name="file"
                    action={`${apiUrl}/media/upload`}
                    headers={{
                      Authorization: `Bearer ${localStorage.getItem('token')}`,
                    }}
                    listType="picture"
                    maxCount={20}
                    multiple
                    onChange={onChange}
                    onRemove={removeFile}
                    defaultFileList={publicFileList}
                    data={() => {
                      const fileUuid = uuidV4();
                      files.push(fileUuid);
                      form?.setFieldsValue({
                        files,
                      });
                      return { fileUuid, type: DocumentType.DIRECTOR_PASSPORT };
                    }}
                  >
                    <p style={{ fontSize: 13, padding: '10px 10px 0 10px' }}>
                      {DocumentType.DIRECTOR_PASSPORT}
                    </p>
                  </Upload.Dragger>
                </Form.Item>
                <Form.Item
                  name="images2"
                  valuePropName="fileList2"
                  normalize={normalizeFile}
                  style={{ width: 200, marginRight: 10 }}
                >
                  <Upload.Dragger
                    name="file"
                    action={`${apiUrl}/media/upload`}
                    headers={{
                      Authorization: `Bearer ${localStorage.getItem('token')}`,
                    }}
                    listType="picture"
                    maxCount={20}
                    multiple
                    onChange={onChange2}
                    onRemove={removeFile}
                    defaultFileList={publicFileList2}
                    data={() => {
                      const fileUuid = uuidV4();
                      files.push(fileUuid);
                      form?.setFieldsValue({
                        files,
                      });
                      return { fileUuid, type: DocumentType.OWNER_PASSPORT };
                    }}
                  >
                    <p style={{ fontSize: 13, padding: '10px 10px 0 10px' }}>
                      {DocumentType.OWNER_PASSPORT}
                    </p>
                  </Upload.Dragger>
                </Form.Item>
                <Form.Item
                  name="images3"
                  valuePropName="fileList3"
                  normalize={normalizeFile}
                  style={{ width: 200, marginRight: 10 }}
                >
                  <Upload.Dragger
                    name="file"
                    action={`${apiUrl}/media/upload`}
                    headers={{
                      Authorization: `Bearer ${localStorage.getItem('token')}`,
                    }}
                    listType="picture"
                    maxCount={20}
                    multiple
                    onChange={onChange3}
                    onRemove={removeFile}
                    defaultFileList={publicFileList3}
                    data={() => {
                      const fileUuid = uuidV4();
                      files.push(fileUuid);
                      form?.setFieldsValue({
                        files,
                      });
                      return { fileUuid, type: DocumentType.REPORTS_1 };
                    }}
                  >
                    <p style={{ fontSize: 13, padding: '10px 10px 0 10px' }}>
                      {DocumentType.REPORTS_1}
                    </p>
                  </Upload.Dragger>
                </Form.Item>
                <Form.Item
                  name="images4"
                  valuePropName="fileList4"
                  normalize={normalizeFile}
                  style={{ width: 200, marginRight: 10 }}
                >
                  <Upload.Dragger
                    name="file"
                    action={`${apiUrl}/media/upload`}
                    headers={{
                      Authorization: `Bearer ${localStorage.getItem('token')}`,
                    }}
                    listType="picture"
                    maxCount={20}
                    multiple
                    onChange={onChange4}
                    onRemove={removeFile}
                    defaultFileList={publicFileList4}
                    data={() => {
                      const fileUuid = uuidV4();
                      files.push(fileUuid);
                      form?.setFieldsValue({
                        files,
                      });
                      return { fileUuid, type: DocumentType.REPORTS_2 };
                    }}
                  >
                    <p style={{ fontSize: 13, padding: '10px 10px 0 10px' }}>
                      {DocumentType.REPORTS_2}
                    </p>
                  </Upload.Dragger>
                </Form.Item>
                <Form.Item
                  name="images5"
                  valuePropName="fileList5"
                  normalize={normalizeFile}
                  style={{ width: 200, marginRight: 10 }}
                >
                  <Upload.Dragger
                    name="file"
                    action={`${apiUrl}/media/upload`}
                    headers={{
                      Authorization: `Bearer ${localStorage.getItem('token')}`,
                    }}
                    listType="picture"
                    maxCount={20}
                    multiple
                    onChange={onChange5}
                    onRemove={removeFile}
                    defaultFileList={publicFileList5}
                    data={() => {
                      const fileUuid = uuidV4();
                      files.push(fileUuid);
                      form?.setFieldsValue({
                        files,
                      });
                      return { fileUuid, type: DocumentType.REPORTS_3 };
                    }}
                  >
                    <p style={{ fontSize: 13, padding: '10px 10px 0 10px' }}>
                      {DocumentType.REPORTS_3}
                    </p>
                  </Upload.Dragger>
                </Form.Item>
                <Form.Item
                  name="images6"
                  valuePropName="fileList6"
                  normalize={normalizeFile}
                  style={{ width: 200 }}
                >
                  <Upload.Dragger
                    name="file"
                    action={`${apiUrl}/media/upload`}
                    headers={{
                      Authorization: `Bearer ${localStorage.getItem('token')}`,
                    }}
                    listType="picture"
                    maxCount={20}
                    multiple
                    onChange={onChange6}
                    onRemove={removeFile}
                    defaultFileList={publicFileList6}
                    data={() => {
                      const fileUuid = uuidV4();
                      files.push(fileUuid);
                      form?.setFieldsValue({
                        files,
                      });
                      return { fileUuid, type: DocumentType.REPORTS_4 };
                    }}
                  >
                    <p style={{ fontSize: 13, padding: '10px 10px 0 10px' }}>
                      {DocumentType.REPORTS_4}
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </div>
              <Form.Item
                style={{ height: '0px' }}
                name="files"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input type={'hidden'} />
              </Form.Item>
              <Form.Item
                style={{ height: '0px' }}
                name="infoDebt"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input type={'hidden'} />
              </Form.Item>
              <Form.Item
                style={{ height: '0px' }}
                name="infoTax"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input type={'hidden'} />
              </Form.Item>
              <Form.Item
                style={{ height: '0px' }}
                name="infoFounders"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input type={'hidden'} />
              </Form.Item>
              <Form.Item
                style={{ height: '0px' }}
                name="regDate"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input type={'hidden'} />
              </Form.Item>
              <Form.Item
                style={{ height: '0px' }}
                name="revenue"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input type={'hidden'} />
              </Form.Item>
              <Form.Item
                style={{ height: '0px' }}
                name="income"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input type={'hidden'} />
              </Form.Item>
            </Form>
          </Edit>
        )}
      {me?.uuid === record?.uuid && me && (
        <Edit
          headerProps={{
            subTitle: '',
            extra: <></>,
            breadcrumb: <></>,
          }}
          title="Редактировать профиль"
          saveButtonProps={{
            ...saveButtonProps,
            disabled: saveDisabled,
            children: 'Сохранить',
          }}
        >
          {isReady && <ShowAvatar avatar={avatar} />}
          <Form {...formProps} layout="vertical" initialValues={formValues}>
            <Form.Item
              label="ИНН"
              name="inn"
              rules={[
                {
                  required: false,
                },
                innRule,
              ]}
            >
              <Input onInput={dadataPersonal} maxLength={innRule.max} />
            </Form.Item>
            <Form.Item
              label="Фамилия"
              name="lastName"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Имя"
              name="firstName"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Отчество"
              name="middleName"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Дата рождения"
              name="dateOfBirth"
              rules={[
                {
                  required: false,
                },
                {
                  validator: (_, value) => {
                    const date = moment(value, 'DD-MM-YYYY').toDate();
                    date.setFullYear(date.getFullYear() + 18);
                    const today = new Date();
                    if (date > today) {
                      return Promise.reject(
                        new Error('Регистрация возможна с 18 лет'),
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <InputMask placeholder={''} mask="99.99.9999">
                {(
                  inputProps: JSX.IntrinsicAttributes &
                    InputProps &
                    React.RefAttributes<InputRef>,
                ) => <Input allowClear {...inputProps} />}
              </InputMask>
            </Form.Item>
            <Form.Item
              label="Место рождения"
              name="placeOfBirth"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Адрес регистрации (город, улица, дом)"
              name="address"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <AutoComplete onSearch={handleSearch} options={options} />
            </Form.Item>
            <Form.Item
              label="Адрес фактического местонахождения (город, улица, дом)"
              name="factAddress"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <AutoComplete onSearch={handleSearch} options={options} />
            </Form.Item>
            <Form.Item
              label="Серия и № паспорта"
              name="passportNumber"
              hasFeedback
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputMask
                placeholder={''}
                mask="серия: 9999, № 999999"
                // maskChar=' '
              >
                {(
                  inputProps: JSX.IntrinsicAttributes &
                    InputProps &
                    React.RefAttributes<InputRef>,
                ) => <Input allowClear {...inputProps} />}
              </InputMask>
            </Form.Item>
            <Form.Item
              label="Дата выдачи"
              name="issueDate"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputMask
                placeholder={''}
                mask="99.99.9999"
                // maskChar=' '
              >
                {(
                  inputProps: JSX.IntrinsicAttributes &
                    InputProps &
                    React.RefAttributes<InputRef>,
                ) => <Input allowClear {...inputProps} />}
              </InputMask>
            </Form.Item>
            <Form.Item
              label="Кем выдан"
              name="passportIssuedBy"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Код подразделения"
              name="passportCodeDepartment"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Divider orientation="left" plain>
              <Text type="secondary">Банковские реквизиты</Text>
            </Divider>
            <Form.Item
              label="БИК"
              name="bik"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input onInput={dadataBank} />
            </Form.Item>
            <Form.Item
              label="Расчетный счет"
              name="bankAccNumber"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Наименование банка"
              name="bankName"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Корр. счет"
              name="bankCorNumber"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ height: '0px' }}
              name="infoDebt"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input type={'hidden'} />
            </Form.Item>
            <Form.Item
              style={{ height: '0px' }}
              name="infoTax"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input type={'hidden'} />
            </Form.Item>
            <Form.Item
              style={{ height: '0px' }}
              name="infoFounders"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input type={'hidden'} />
            </Form.Item>
            <Form.Item
              style={{ height: '0px' }}
              name="regDate"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input type={'hidden'} />
            </Form.Item>
            <Form.Item
              style={{ height: '0px' }}
              name="revenue"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input type={'hidden'} />
            </Form.Item>
            <Form.Item
              style={{ height: '0px' }}
              name="income"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input type={'hidden'} />
            </Form.Item>
            <Alert
              showIcon
              description={
                'Вы можете изменить Ваш пароль и/или телефон. Изменения необходимо подтвердить вводом SMS-кода, отправленного на указанный телефон.'
              }
              type="info"
            />
            <br />
            <Form.Item
              label="Сменить пароль"
              tooltip="Если вы не хотите менять пароль, оставьте это поле пустым"
              name="password"
              rules={[
                {
                  min: 6,
                  max: 20,
                  message: 'Введите пароль (от 6 до 20 символов)',
                },
              ]}
              hasFeedback
            >
              <Input.Password onChange={onPass} />
            </Form.Item>
            <Form.Item
              label="Сменить телефон"
              tooltip="На этот телефон будет отправлено SMS с кодом подтверждения"
              name="phone"
              rules={[
                {
                  required: false,
                  type: 'string',
                  // len: 16,
                  // min: 16,
                  // max: 16,
                },
              ]}
            >
              <InputMask
                placeholder={'+7(___)-___-__-__'}
                mask="+7(999)999-99-99"
                // maskChar=' '
                onChange={onPhone}
              >
                {(
                  inputProps: JSX.IntrinsicAttributes &
                    InputProps &
                    React.RefAttributes<InputRef>,
                ) => <Input {...inputProps} />}
              </InputMask>
            </Form.Item>
            <Form.Item
              style={{ height: '0px' }}
              name="editMyself"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input type={'hidden'} />
            </Form.Item>
            {!saveDisabled && (
              <Form.Item
                label="Код из SMS"
                name="code"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
          </Form>
          <Form
            layout="vertical"
            requiredMark={false}
            onFinish={sendCode}
            style={{ padding: '30px 0 0 0' }}
          >
            <Form.Item noStyle>
              {!currentCode && (
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  disabled={regButton}
                >
                  Подтвердить
                </Button>
              )}
            </Form.Item>
          </Form>
        </Edit>
      )}
    </>
  );
};
