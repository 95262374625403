export enum DocumentType {
  DIRECTOR_PASSPORT = 'Паспорт руководителя',
  OWNER_PASSPORT = 'Паспорт учредителя',
  AGENT_BANK = 'Реквизиты',
  REPORTS_1 = 'Отчетность по форме 1 и форме 2 за отчетный год (с отметками ИФНС)',
  REPORTS_2 = 'Декларация на Прибыль (УСН) за отчетный год (с отметками ИФНС)',
  REPORTS_3 = 'Отчетность по форме 1 и форме 2 последний отчетный период (Заверенная Компанией)',
  REPORTS_4 = 'Декларация на Прибыль за последний отчетный период с отметками ИФНС (При наличии ОСН)',
  AVATAR = 'Аватар',
  NBKI = 'Кредитная история',
  AUX = 'Прочие документы',
  PAY_DOCS = 'Комиссия платформы',
  SIGN = 'Проверка подписи',
  OTHER = 'Другие документы',
}
