export enum Role {
  ADMIN = 'Администратор',
  DIRECTOR = 'Директор',
  SDL = 'СДЛ',
  INVESTOR = 'Инвестор',
  LENDER = 'Заемщик',
  DUPLEX = 'Участник',
  VIEWER = 'Просмотр',
  ECONOMIST = 'Экономист',
  UNDERWRITER = 'Андеррайтер',
  AGENT = 'Агент',
  MANAGER = 'Менеджер',
}
