import {
  Button,
  Card,
  Col,
  DatePicker,
  EmailField,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  TagField,
  TextField,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import { UserStatus } from 'enums/user.status';
import { IUser } from 'interfaces';
import { SolutionOutlined } from '@ant-design/icons';

import { CSSProperties, useEffect, useState } from 'react';
import { useNavigation, useNotification } from '@pankod/refine-core';
import { axiosInstance } from 'App';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const { Text } = Typography;
const { RangePicker } = DatePicker;
const dateFormat = 'DD.MM.YYYY';

export const AgentReport = (props: {
  loading: boolean;
  setLoading: any;
  refetch: any;
  agentUuid: number;
  lastNo: number;
}) => {
  const [form] = Form.useForm();
  const { open: notify } = useNotification();

  const handleCreateReport = async (value: any) => {
    if (notify) {
      const dateFrom = dayjs(value.period[0]).format('YYYY-MM-DD');
      const dateTo = dayjs(value.period[1]).format('YYYY-MM-DD');
      const reportNo = value.reportNo;
      props.setLoading(true);
      try {
        const response = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/report/createAgent',
          {
            dateFrom,
            dateTo,
            reportNo,
            agentUuid: props.agentUuid,
          },
        );
        props.refetch();
        await props.setLoading(false);
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: 'Возникла проблема , сообщите сис-админу',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
        setTimeout(async () => {
          props.setLoading(false);
        }, 2000);
      }
    }
    return true;
  };

  return (
    <Card title="Формирование отчетов" hoverable>
      <Form form={form} onFinish={handleCreateReport} layout="vertical">
        <Row gutter={[12, 12]}>
          <Col>
            <Form.Item
              label="Период"
              name="period"
              initialValue={[
                dayjs(dayjs().startOf('month'), dateFormat),
                dayjs(dayjs().endOf('month'), dateFormat),
              ]}
            >
              <RangePicker format={dateFormat} />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              initialValue={props.lastNo ? props.lastNo : 0}
              label="Номер"
              name="reportNo"
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Сформировать отчет
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
