import { Refine } from '@pankod/refine-core';
import { ReadyPage } from '@pankod/refine-antd';
import {
  BookOutlined,
  UserOutlined,
  BarChartOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { useEffect, useContext } from 'react';
import '@pankod/refine-antd/dist/reset.css';
import routerProvider from '@pankod/refine-react-router-v6';
import nestjsxCrudDataProvider from '@pankod/refine-nestjsx-crud';
import { UserList, UserCreate, UserEdit, UserShow } from 'pages/users';
import { OfferList, OfferCreate, OfferEdit } from 'pages/offers';
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from 'components/layout';
import { Login } from 'pages/login';
import { Recover } from 'pages/recover';
import { notificationProvider } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';
import { InvestmentEdit, InvestmentList } from './pages/investments';
import { AgreementsList } from './pages/agreements/list';
import { AgreementCreate } from './pages/agreements/create';
import { AgreementShow } from './pages/agreements/show';
import { accessControlProvider, authProvider } from './access.control.provider';
import axios from 'axios';
import { ErrorPage } from './errorPage';
import { OfferPublic } from './pages/offers/showPublic';
import { liveProvider, Ably } from '@pankod/refine-ably';
import { OfferShow } from 'pages/offers/offerShow';
import { UserProvider, UserContext } from 'UserProvider';
import { ProfileUserShow } from 'pages/profile/show';
import { useMemo } from 'react';
import { AgentCreate, AgentList, AgentShow } from 'pages/agents';
import { ReportList } from 'pages/reports';
const ablyClient = new Ably.Realtime(
  'QlDA9A.w2U1DA:MWmdo1fplyf2q0UkG5cRzSYAGNYdGCqAv50Khz9Ccl8',
);

export const axiosInstance = axios.create();
function App() {
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (userContext) {
      window.setUserFromOutside = userContext.setUserFromOutside;
      console.log('setUserFromOutside has been set in window');
    }
  }, [userContext]);

  const { t, i18n } = useTranslation();
  const i18nProvider = {
    translate: (key: string, params: Record<string, unknown>) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const { RouterComponent } = routerProvider;
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  let dataProvider: any;
  if (API_URL) {
    dataProvider = nestjsxCrudDataProvider(API_URL + '/api/v1', axiosInstance);
  } else {
    dataProvider = nestjsxCrudDataProvider('http://localhost:3001');
  }

  const CustomRouterComponent = () => <RouterComponent />;

  const MemoizedRefine = useMemo(
    () => (
      <UserProvider>
        <Refine
          accessControlProvider={accessControlProvider}
          notificationProvider={notificationProvider}
          liveProvider={liveProvider(ablyClient)}
          options={{ liveMode: 'auto' }}
          ReadyPage={ReadyPage}
          catchAll={<ErrorPage />}
          routerProvider={{
            ...routerProvider,
            RouterComponent: CustomRouterComponent,
            routes: [
              {
                element: <OfferPublic />,
                path: '/public/offers/:offerId',
                layout: false,
              },
              {
                element: <Recover />,
                path: '/recover',
                layout: true,
              },
            ],
          }}
          dataProvider={dataProvider}
          authProvider={authProvider}
          LoginPage={Login}
          i18nProvider={i18nProvider}
          resources={[
            {
              name: 'offers',
              options: {
                label: 'База заявок',
              },
              list: OfferList,
              create: OfferCreate,
              edit: OfferEdit,
              show: OfferShow,
              icon: <BookOutlined />,
            },
            {
              name: 'agents',
              options: {
                label: 'База Агентов',
              },
              list: AgentList,
              create: AgentCreate,
              // edit: OfferEdit,
              show: AgentShow,
              icon: <SolutionOutlined />,
            },

            {
              name: 'reports',
              options: {
                label: 'Отчеты',
              },
              list: ReportList,
              icon: <BarChartOutlined />,
            },
            {
              name: 'users',
              options: {
                label: 'Пользователи',
              },
              list: UserList,
              create: UserCreate,
              edit: UserEdit,
              show: UserShow,
              icon: <UserOutlined />,
            },
            {
              name: 'agreements',
              options: {
                label: 'Документы',
              },
              show: AgreementShow,
              icon: <UserOutlined />,
            },

            {
              name: 'profile',
              options: {
                label: 'Профиль',
              },
              list: ProfileUserShow,
              icon: <UserOutlined />,
            },
          ]}
          Title={Title}
          Header={Header}
          Sider={Sider}
          Footer={Footer}
          Layout={Layout}
          OffLayoutArea={OffLayoutArea}
        />
      </UserProvider>
    ),
    [],
  );

  return MemoizedRefine;
}

export default App;
