import {
  Alert,
  Button,
  Form,
  Modal,
  Input,
  Select,
  Space,
  Typography,
  DateField,
  DatePicker,
  InputProps,
} from '@pankod/refine-antd';
import { useNotification } from '@pankod/refine-core';
import { axiosInstance } from 'App';
import { OfferStatus } from 'enums/offer.status';
import { IMessage, IOffer } from 'interfaces';
import { MessageShow } from 'pages/messages/show';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { InputRef } from 'rc-input';
import moment, { Moment } from 'moment';

const { Text } = Typography;
const { TextArea } = Input;

export const ModalStatuses = (props: {
  open: boolean;
  cancel: any;
  refetch: any;
  uuid: number | null;
  status: OfferStatus;
  dateFactIssue: Date | undefined;
}) => {
  const { open: notify } = useNotification();

  const [form] = Form.useForm();
  const onSave = async (value: any) => {
    console.log('value : ', value);
    // let result;
    if (notify) {
      try {
        await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL + '/api/v1/offers/setStatus',
          {
            uuid: props.uuid,
            ...value,
          },
        );
        notify({
          description: 'Выполнено',
          message: 'Статус был установлен успешно',
          type: 'success',
          key: '2',
          undoableTimeout: 20000,
        });
        props.refetch();
        props.cancel();
      } catch (e) {
        notify({
          description: 'Ошибка',
          message: 'Статус не был установлен',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  const [showDateIssue, setShowDateIssue] = useState<boolean>(false);
  const [showDateClose, setShowDateClose] = useState<boolean>(false);

  return (
    <Modal
      title="Редактирование статусов заявки"
      open={props.open}
      onCancel={props.cancel}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onSave}>
        <Form.Item
          hasFeedback
          label="Статус"
          name="statuses"
          initialValue={props.status}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            onChange={(value) => {
              setShowDateIssue(false);
              setShowDateClose(false);
              switch (value) {
                case OfferStatus.SUCCESS:
                  setShowDateIssue(true);
                  break;
                case OfferStatus.CANCELED:
                  setShowDateClose(true);
                  break;
                default:
              }
            }}
            options={[
              { label: OfferStatus.REFUSE, value: OfferStatus.REFUSE },
              {
                label: OfferStatus.REFUSE_CLIENT,
                value: OfferStatus.REFUSE_CLIENT,
              },
              { label: OfferStatus.SUCCESS, value: OfferStatus.SUCCESS },
              { label: OfferStatus.VIOLATED, value: OfferStatus.VIOLATED },
              { label: OfferStatus.INCOURT, value: OfferStatus.INCOURT },
              { label: OfferStatus.CANCELED, value: OfferStatus.CANCELED },
            ]}
          />
        </Form.Item>
        {showDateIssue && (
          <Form.Item
            hasFeedback
            label="Дата выдачи"
            name="dateFactIssue"
            rules={[
              {
                required: true,
                message: 'Пожалуйста введите корректную дату',
              },
            ]}
          >
            <DatePicker style={{ width: '100%' }} format="DD.MM.YYYY" />
          </Form.Item>
        )}
        {showDateClose && (
          <Form.Item
            hasFeedback
            label="Дата закрытия"
            name="dateFactClose"
            rules={[
              {
                required: true,
                message: 'Пожалуйста введите корректную дату',
              },
            ]}
          >
            <DatePicker style={{ width: '100%' }} format="DD.MM.YYYY" />
          </Form.Item>
        )}
        <Form.Item label="Комментарий" name="comment">
          <TextArea />
        </Form.Item>
        <Form.Item>
          <Button
            style={{ float: 'right', width: '100%' }}
            type="primary"
            htmlType="submit"
          >
            Сохранить
          </Button>
        </Form.Item>
      </Form>
      {/* <Text type="secondary">{data?.topic}</Text>
        <Alert type="info" showIcon message={<Text>{data?.text}</Text>} /> */}
    </Modal>
  );
};
