export enum AgreementType {
  AGENT_JOIN_OOO = 'Агентский договор для ООО',
  AGENT_JOIN_IP = 'Агентский договор для ИП',
  TICKET = 'Счет',
  ADDITIONAL = 'Соглашение сторон',
  BLANK_LENDER = 'Анкета заемщика',
  BLANK_GUARANTOR_FIZ = 'Анкета поручительства физ.лиц',
  BLANK_GUARANTOR_JUR = 'Анкета поручительства юр.лиц',
  OFFER = 'Договор займа',
  OFFER_GUARANTOR_FIZ = 'Договор поручительства физ. лица',
  OFFER_GUARANTOR_JUR = 'Договор поручительства юр. лица',
  PETITION = 'Претензинное письмо',
  REFERENCE = 'Справка',
  AKT_REPORT = 'Акт-отчет',
  AKT_WORK = 'Акт выполненных работ',
  OTHER = 'Другие документы',
}
