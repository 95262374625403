import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  CollapseProps,
  Empty,
  Form,
  Row,
  Select,
  Space,
} from '@pankod/refine-antd';
import { CanAccess, useNotification } from '@pankod/refine-core';
import { axiosInstance } from 'App';
import { DedicatedLoan } from 'enums/dedicated.loan.type';
import { ProlongStatus } from 'enums/prolong.type';
import { IOffer, IProlong, IUser } from 'interfaces';
import moment from 'moment';
import React, { useState } from 'react';

export default function Prolong(props: { record: IOffer; refetch: any }) {
  const { open: notify } = useNotification();

  const [loadingBtn, setLoadinBtn] = useState<boolean>(false);
  const showAlert = props.record.prolongs?.filter(
    (prolong) => prolong.status === ProlongStatus.WAIT,
  );

  const confirmProlong = async (uuid: number) => {
    setLoadinBtn(true);

    if (notify) {
      if (!props.record) return;
      const prolong = props.record.prolongs?.find((el) => el.uuid === uuid);
      console.log(prolong);
      const days = props.record.prolongs?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.reqDays,
        30,
      );
      console.log(days);
      const date = moment(props.record.dateFactIssue).add(days, 'days');
      try {
        {
          const response = await axiosInstance.post(
            process.env.REACT_APP_BACKEND_URL +
              '/api/v1/offers/prolong/confirm',
            { uuid, date },
          );
          notify({
            description: 'Удачно',
            message: 'Данные сохранены',
            type: 'success',
            key: '2',
            undoableTimeout: 20000,
          });
          props.refetch();
          setLoadinBtn(false);
        }
      } catch (error) {
        console.log(error);
        notify({
          description: 'Ошибка',
          message: 'Возникла проблема , сообщите сис-админу',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
        setTimeout(async () => {
          setLoadinBtn(false);
        }, 2000);
      }
    }
  };

  const items: CollapseProps['items'] = props.record.prolongs?.map(
    (item: IProlong, id) => ({
      key: item.uuid,
      label: `Пролонгация ${id + 1}`,
      children: (
        <div>
          <Space direction="vertical" size={12} style={{ width: '100%' }}>
            {item.status === ProlongStatus.WAIT ? (
              <Alert
                message={<div>Данная пролонгация ожидает подтверждения</div>}
                type="info"
                showIcon
                style={{ fontSize: 14, marginBottom: 12 }}
              />
            ) : (
              <Alert
                message={<div>Пролонгация оплачена</div>}
                type="success"
                showIcon
                style={{ fontSize: 14, marginBottom: 12 }}
              />
            )}
            <Row gutter={[12, 12]}>
              {item.agreements.map((agreement) => {
                return (
                  <Col>
                    <Card
                      hoverable
                      title={agreement.type}
                      size="small"
                      bodyStyle={{ padding: 0 }}
                      style={{ maxWidth: '280px' }}
                      onClick={() => {
                        window.open(
                          process.env.REACT_APP_BACKEND_URL + agreement.pdf,
                          '_blank',
                        );
                      }}
                    >
                      <iframe
                        src={process.env.REACT_APP_BACKEND_URL + agreement.pdf}
                        width="100%"
                        style={{ border: 'none', height: '320px' }}
                        title="PDF Viewer"
                      />
                    </Card>
                  </Col>
                );
              })}
            </Row>
            {item.status === ProlongStatus.WAIT && (
              <Button
                onClick={() => {
                  confirmProlong(item.uuid);
                }}
                type="primary"
              >
                Подтвердить оплату
              </Button>
            )}
          </Space>
        </div>
      ),
    }),
  );

  return (
    <CanAccess resource="offers" action="review">
      <Card hoverable>
        {showAlert?.length ? (
          <Alert
            message={<div>На данном этапе ожидает оплаты </div>}
            type="warning"
            showIcon
            style={{ fontSize: 14, marginBottom: 12 }}
          />
        ) : (
          ''
        )}
        {props.record.prolongs && (
          <Collapse items={items} defaultActiveKey={['1']} />
        )}
      </Card>
    </CanAccess>
  );
}
