import {
  Avatar,
  Button,
  AntdList as List,
  Space,
  Spin,
} from '@pankod/refine-antd';
import { BookOutlined } from '@ant-design/icons';
import { IAgent, IAgreement, IUser } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { useNotification } from '@pankod/refine-core';
import { axiosInstance } from 'App';

export const PersonalDocuments = (props: {
  user: IAgent | IUser;
  me?: IUser;
  refetch?: any;
}) => {
  const [data, setData] = useState<IAgreement[]>([]);
  const { open: notify } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const reloadDocs = async () => {
    setLoading(true);
    if (notify) {
      try {
        const res = await axiosInstance.get(
          process.env.REACT_APP_BACKEND_URL +
            '/api/v1/agents/getDocs/' +
            props.user.uuid,
        );
        if (res && res.data) setData(res.data);
        setLoading(false);
      } catch (error) {
        console.log('error : ', error);
      }
    }
  };

  useEffect(() => {
    reloadDocs();
  }, []);

  const createOferta = async () => {
    setLoading(true);

    if (notify) {
      try {
        const res = await axiosInstance.get(
          process.env.REACT_APP_BACKEND_URL +
            '/api/v1/agents/createDocd/' +
            props.user.uuid,
        );
        reloadDocs();
        setLoading(false);
      } catch (error) {
        notify({
          description: 'Ошибка',
          message: '',
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        {data && data.length > 0 ? (
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      size={32}
                      shape="square"
                      style={{ backgroundColor: '#1677ff' }}
                      children={<BookOutlined />}
                    />
                  }
                  title={
                    <Space size={4}>
                      <Button
                        size="small"
                        type="primary"
                        href={`/agreements/show/${item.uuid}`}
                      >
                        Открыть документ
                      </Button>
                    </Space>
                  }
                  description={item.type}
                />
              </List.Item>
            )}
          />
        ) : (
          <Button
            type="primary"
            style={{ width: '100%' }}
            onClick={createOferta}
          >
            Сформировать агентский договор
          </Button>
        )}
      </Spin>
    </>
  );
};
