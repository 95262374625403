import { IOffer, IPayment } from '../../interfaces';
import React from 'react';
import { OfferStatus } from '../../enums/offer.status';
import {
  DateField,
  Descriptions,
  Divider,
  NumberField,
  Progress,
  Rate,
  Statistic,
  TagField,
  TextField,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import { Badge } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { LoanType } from '../../enums/loan.type';
import moment from 'moment';
import { useList } from '@pankod/refine-core';
import addDaysToDate from 'utility/addDaysToDate';

const { Text, Title } = Typography;
export function DescriptionsBlockMain(props: { record: IOffer }) {
  const { data } = useList<IPayment>({
    resource: 'payments',
    config: {
      hasPagination: true,
      pagination: {
        current: 1,
        pageSize: 100,
      },
      filters: [
        {
          field: 'offer.uuid',
          operator: 'eq',
          value: props.record.uuid,
        },
      ],
    },
  });
  let tagStyle: React.CSSProperties = {
    wordBreak: 'break-word',
    border: '1px solid #d2d2d2',
    color: '#535353',
    borderColor: '#d2d2d2',
    backgroundColor: '#f7f7f7',
    padding: '2px 6px 2px 6px',
    fontSize: '12px',
    borderRadius: '2px',
    width: 'fit-content',
    display: 'inline',
  };
  let badgeStatus: 'success' | 'processing' | 'error' | 'default' | 'warning' =
    'default';
  switch (props.record?.status) {
    case OfferStatus.APPROVAL:
      badgeStatus = 'processing';
      tagStyle = {
        ...tagStyle,
        color: '#6a6047',
        borderColor: '#c9c897',
        border: '1px solid #9bc997',
        backgroundColor: '#fff8df',
      };
      break;

    case OfferStatus.RETURNED:
      badgeStatus = 'error';
      tagStyle = {
        ...tagStyle,
        color: '#8e6161',
        borderColor: '#ddbebe',
        border: '1px solid #ddbebe',
        backgroundColor: '#fff4f1',
      };
      break;
    case OfferStatus.AGREEMENT:
      badgeStatus = 'default';
      tagStyle = {
        ...tagStyle,
        backgroundColor: '#f0f3ff',
      };
      break;
  }
  return (
    <>
      <Descriptions
        size="small"
        title=""
        bordered
        labelStyle={{ width: '150px' }}
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="Предложение">
          {props.record?.offerNumber ? props.record?.offerNumber : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Статус" span={2}>
          <Badge
            status={badgeStatus}
            text={<div style={tagStyle}>{props.record?.status}</div>}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Дата создания предложения">
          {moment(props.record?.createdAt).format('DD.MM.YYYY HH:mm')}
        </Descriptions.Item>
        <Descriptions.Item label="Заемщик">
          {props.record?.nameOrg}
        </Descriptions.Item>
        {[
          OfferStatus.DRAFT,
          OfferStatus.APPROVAL,
          OfferStatus.RETURNED,
        ].includes(props.record.status) ? (
          <Descriptions.Item label="Срок закрытия предложения">
            {moment().format('DD.MM.YYYY HH:mm')}
          </Descriptions.Item>
        ) : (
          <Descriptions.Item label="Дата закрытия займа">
            {moment().format('DD.MM.YYYY HH:mm')}
          </Descriptions.Item>
        )}
      </Descriptions>
      <br />
    </>
  );
}
export function DescriptionsBlockAbout(props: { record: IOffer }) {
  const { data } = useList<IPayment>({
    resource: 'payments',
    config: {
      hasPagination: true,
      pagination: {
        current: 1,
        pageSize: 100,
      },
      filters: [
        {
          field: 'offer.uuid',
          operator: 'eq',
          value: props.record.uuid,
        },
      ],
    },
  });
  let tagStyle: React.CSSProperties = {
    wordBreak: 'break-word',
    border: '1px solid #d2d2d2',
    color: '#535353',
    borderColor: '#d2d2d2',
    backgroundColor: '#f7f7f7',
    padding: '2px 6px 2px 6px',
    fontSize: '12px',
    borderRadius: '2px',
    width: 'fit-content',
    display: 'inline',
  };
  let badgeStatus: 'success' | 'processing' | 'error' | 'default' | 'warning' =
    'default';
  switch (props.record?.status) {
    case OfferStatus.APPROVAL:
      badgeStatus = 'processing';
      tagStyle = {
        ...tagStyle,
        color: '#6a6047',
        borderColor: '#c9c897',
        border: '1px solid #9bc997',
        backgroundColor: '#fff8df',
      };
      break;

    case OfferStatus.RETURNED:
      badgeStatus = 'error';
      tagStyle = {
        ...tagStyle,
        color: '#8e6161',
        borderColor: '#ddbebe',
        border: '1px solid #ddbebe',
        backgroundColor: '#fff4f1',
      };
      break;
    case OfferStatus.AGREEMENT:
      badgeStatus = 'default';
      tagStyle = {
        ...tagStyle,
        backgroundColor: '#f0f3ff',
      };
      break;
  }
  return (
    <>
      <Descriptions
        size="small"
        title=""
        bordered
        // column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        labelStyle={{ width: '150px' }}
        // style={{ marginLeft: '20px' }}
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
      >
        {props.record && (
          <Descriptions.Item
            label={
              <Tooltip title="При сборе этой суммы, оферта будет исполнена и инвестиции состоятся, даже если максимальная сумма не будет собрана до истечения срока оферты">
                <span>
                  Минимальная сумма <QuestionCircleOutlined />
                </span>
              </Tooltip>
            }
          >
            <Progress
              type="circle"
              percent={1}
              width={40}
              style={{ marginRight: 13 }}
            />
            <NumberField
              style={{ fontSize: 20, position: 'relative', top: 5 }}
              options={{
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }}
              value={0}
            />{' '}
            <span style={{ fontSize: 20, position: 'relative', top: 5 }}>
              ₽
            </span>
          </Descriptions.Item>
        )}

        <Descriptions.Item
          label={
            <Tooltip
              title={
                'Для каких целей берется займ: аукцион (целевой процентный займ) или пополнение оборотных средств (процентный займ)'
              }
            >
              <span>
                Тип займа <QuestionCircleOutlined />
              </span>
            </Tooltip>
          }
          span={1}
        >
          <TextField value={props.record?.loanType} />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}
