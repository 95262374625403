import {
  CrudFilter,
  CrudFilters,
  IResourceComponentsProps,
  useGetIdentity,
  useNavigation,
} from '@pankod/refine-core';
import {
  Button,
  CreateButton,
  DateField,
  EditButton,
  EmailField,
  FilterDropdown,
  FilterDropdownProps,
  getDefaultSortOrder,
  Input,
  List,
  NumberField,
  Select,
  Show,
  ShowButton,
  Space,
  Table,
  Tag,
  TagField,
  TextField,
  Tooltip,
  useTable,
} from '@pankod/refine-antd';

import {
  SolutionOutlined,
  FileAddOutlined,
  UserOutlined,
  BankOutlined,
} from '@ant-design/icons';
import { IAgent, IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { Role } from '../../enums/role';
import { ModalAgentList } from 'components/users/modalAgentList';
import { UserContext } from 'UserProvider';
import { OfferStatus } from 'enums/offer.status';
import { UserType } from 'enums/user.type';

const { Search } = Input;

export const AgentList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const [searchingInn, setSearchingInn] = useState<string | null>(null);

  const initialFilter: CrudFilters = [];

  const { tableProps, sorter, setFilters } = useTable<IAgent>({
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    initialFilter,
    initialPageSize: 100,
    hasPagination: true,
  });

  useEffect(() => {
    if (searchingInn !== null) {
      const statusFilter: CrudFilter = {
        field: 'orgInn',
        operator: 'eq',
        value: searchingInn,
      };
      setFilters([statusFilter]);
    } else {
      setFilters([]);
    }
  }, [searchingInn]);

  return (
    <List
      headerProps={{
        extra: (
          <Space direction="vertical">
            <CreateButton
              style={{ marginTop: 10, float: 'right' }}
              type={'primary'}
              children="Создать агента"
            />
            <div style={{ float: 'right' }}>
              <Search
                allowClear
                placeholder="Введите ИНН для поиска"
                style={{ width: '250px' }}
                onSearch={(value) => setSearchingInn(value)}
              />
            </div>
          </Space>
        ),
      }}
    >
      <Table
        {...tableProps}
        pagination={{
          pageSizeOptions: ['10', '20', '50', '100'], // Установите доступные варианты количества на странице
          showSizeChanger: true, // Показать выпадающий список выбора количества на странице
        }}
        onChange={(value) => value}
        rowKey="uuid"
      >
        <Table.Column
          dataIndex="uuid"
          key="uuid"
          width={50}
          title="№"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder('phone', sorter)}
          sorter
        />
        <Table.Column
          key="function"
          title="Функции"
          render={(_, record: IAgent) => (
            <ShowButton size="small" icon={false} recordItemId={record?.uuid}>
              <TextField value={'Показать'} />
            </ShowButton>
          )}
        />
        <Table.Column
          dataIndex="orgName"
          key="orgName"
          title="Компания"
          render={(value) => (
            <TextField style={{ wordBreak: 'normal' }} value={value} />
          )}
          defaultSortOrder={getDefaultSortOrder('orgName', sorter)}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Input />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="lastName"
          key="lastName"
          title="Фамилия"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder('lastName', sorter)}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Input />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="firstName"
          key="firstName"
          title="Имя"
          render={(value) => (
            <TextField style={{ wordBreak: 'normal' }} value={value} />
          )}
          defaultSortOrder={getDefaultSortOrder('firstName', sorter)}
          sorter
        />
        <Table.Column
          dataIndex="middleName"
          key="middleName"
          title="Отчество"
          render={(value) => (
            <TextField style={{ wordBreak: 'normal' }} value={value} />
          )}
          defaultSortOrder={getDefaultSortOrder('firstName', sorter)}
          sorter
        />

        <Table.Column
          dataIndex="type"
          key="type"
          title="Тип"
          render={(value) => {
            switch (value) {
              case UserType.IP:
                return (
                  <Tag
                    style={{
                      wordBreak: 'normal',
                    }}
                    icon={<UserOutlined />}
                    color="#55acee"
                  >
                    {value}
                  </Tag>
                );

              default:
                return (
                  <Tag
                    style={{
                      wordBreak: 'normal',
                    }}
                    icon={<BankOutlined />}
                    color="#ff8b00"
                  >
                    {value}
                  </Tag>
                );
            }
          }}
          defaultSortOrder={getDefaultSortOrder('role', sorter)}
          sorter
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Выберите роли"
                options={Object.values(Role).map((status) => ({
                  label: status,
                  value: status,
                }))}
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="Создан"
          render={(value) => (
            <DateField value={value} format={'DD.MM.YYYY, HH:mm'} />
          )}
          defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
          sorter
        />
      </Table>
    </List>
  );
};
