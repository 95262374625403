import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useGetIdentity,
  useNavigation,
  useNotification,
  useShow,
  useUpdate,
} from '@pankod/refine-core';
import {
  Alert,
  AntdBreadcrumb,
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Col,
  DateField,
  Descriptions,
  Divider,
  EditButton,
  EmailField,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  NumberField,
  Row,
  Show,
  ShowButton,
  Space,
  Spin,
  Table,
  TagField,
  TextField,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import { SolutionOutlined } from '@ant-design/icons';

import { IPicture, IUser } from 'interfaces';
import React, { useState } from 'react';
import {
  MinusSquareOutlined,
  PlusSquareOutlined,
  ApiOutlined,
  FileSearchOutlined,
  CheckOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Role } from '../../enums/role';
import { UserType } from '../../enums/user.type';
import { ScanImage } from '../../components/offers/scanImage';
import { UserStatus } from '../../enums/user.status';
import { axiosInstance } from '../../App';
import { DocumentType } from '../../enums/document.type';
import { ModalAgentList } from 'components/users/modalAgentList';
import { PersonalDocuments } from 'pages/profile/documents';
import { ModalSendMessage } from 'components/users/modalSendMessage';

const { Text } = Typography;

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const showManual = process.env.REACT_APP_PROD === '0';
  type CategoryMutationResult = {
    id: number;
    title: string;
  };
  const { mutate } = useUpdate<CategoryMutationResult>();
  const showModal = () => {
    setIsModalVisible(true);
  };
  const showModalMinus = () => {
    setIsModalMinusVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOkMinus = () => {
    setIsModalMinusVisible(false);
  };
  const handleCancelMinus = () => {
    setIsModalMinusVisible(false);
  };
  const { data: me } = useGetIdentity();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalMinusVisible, setIsModalMinusVisible] = useState(false);
  const [tinkoffDisabled, setTinkoffDisabled] = useState<boolean>(false);
  const [tinkoffButtonText, setTinkoffButtonText] =
    useState<string>('Подключить Tinkoff');
  const [depositButton, setDepositButton] = useState<boolean>(false);
  const [minusButton, setMinusButton] = useState<boolean>(false);
  const { queryResult } = useShow<IUser>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;
  const [tinkoffMonitorDisabled, setTinkoffMonitorDisabled] =
    useState<boolean>(false);
  const userTypeName = 'Физическое лицо';

  let fio;
  if (record && record?.lastName && record?.firstName && record?.middleName) {
    fio = record?.lastName + ' ' + record?.firstName + ' ' + record?.middleName;
  } else {
    fio = 'Новый пользователь';
  }

  const onDeposit = async (values: any) => {
    setDepositButton(true);
    const mainAccount = +values.mainAccount + +values.sum;
    mutate({
      resource: 'users',
      values: {
        mainAccount,
      },
      id: values.uuid,
    });
  };
  const onMinus = async (values: any) => {
    setMinusButton(true);
    const mainAccount = +values.mainAccount - +values.sum;
    mutate({
      resource: 'users',
      values: {
        mainAccount,
      },
      id: values.uuid,
    });
  };
  const { open: notify } = useNotification();
  const onTinkoffLink = async (event: any) => {
    setTinkoffDisabled(true);
    if (notify) {
      try {
        const result = await axiosInstance.post(
          process.env.REACT_APP_BACKEND_URL +
            '/api/v1/tinkoff/user/' +
            record?.uuid,
          {},
        );
        setTinkoffButtonText('Tinkoff подключен');
        notify({
          description: 'Бенефициар добавлен',
          message: 'Успешная синхронизация с Tinkoff',
          type: 'success',
          key: '1',
          undoableTimeout: 20000,
        });
        setTinkoffMonitorDisabled(false);
        refetch();
      } catch (e: any) {
        setTinkoffDisabled(false);
        let message = '';
        for (const key in e.response.data.errorDetails) {
          message += `${key}: ${e.response.data.errorDetails[key]} `;
        }
        notify({
          description: e.response.data.errorMessage,
          message,
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };
  const onTinkoffMonitor = async (event: any) => {
    setTinkoffMonitorDisabled(true);
    if (notify) {
      try {
        const result = await axiosInstance.get(
          process.env.REACT_APP_BACKEND_URL +
            '/api/v1/tinkoff/monitor/' +
            record?.uuid,
        );
        // setTinkoffMonitorDisabled(false);
        notify({
          description: 'Результат проверки в Росфинмониторинге',
          message: result.data.monitoringStatus,
          type: 'success',
          key: '1',
          undoableTimeout: 20000,
        });
        refetch();
      } catch (e: any) {
        setTinkoffMonitorDisabled(false);
        let message = '';
        for (const key in e.response.data.errorDetails) {
          message += `${key}: ${e.response.data.errorDetails[key]} `;
        }
        notify({
          description: e.response.data.errorMessage,
          message,
          type: 'error',
          key: '2',
          undoableTimeout: 20000,
        });
      }
    }
  };

  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  let initialFilter: CrudFilters = [];
  if (me) {
    if (me.role === Role.AGENT) {
      initialFilter = [
        {
          field: 'agent.uuid',
          operator: 'eq',
          value: me.uuid,
        },
      ];
    }
  }
  const { tableProps } = useTable<IUser>({
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    initialFilter,
  });
  let avatar;
  if (record) {
    avatar = record.pictures?.find(
      (p: IPicture) => p.type === DocumentType.AVATAR,
    );
  }

  const [modalSendMsg, setModalSendMsg] = useState<boolean>(false);
  const showSendMessage = async () => {
    setModalSendMsg(true);
  };

  return record ? (
    <>
      {open && (
        <ModalAgentList
          uuid={selectedUuid}
          open={open}
          cancel={() => setOpen(false)}
        />
      )}
      <Show
        isLoading={isLoading}
        headerProps={{
          extra: (
            <>
              <div className="my-space">
                <CanAccess resource="users" action="edit">
                  <EditButton
                    style={{ marginLeft: 10, marginTop: 5 }}
                    children="Править"
                  />
                </CanAccess>
                {/* {!record?.tinkoffBankDetailsId &&
                  record.status !== UserStatus.ACCOUNT &&
                  record.status !== UserStatus.PRE_ACCOUNT && (
                    <CanAccess resource="users" action="balance">
                      <Button
                        style={{ marginLeft: 10, marginTop: 5 }}
                        type={'default'}
                        onClick={onTinkoffLink}
                        icon={<ApiOutlined />}
                        disabled={tinkoffDisabled}
                      >
                        {tinkoffButtonText}
                      </Button>
                    </CanAccess>
                  )}
                {record?.tinkoffBankDetailsId && (
                  <CanAccess resource="users" action="balance">
                    <Button
                      style={{ marginLeft: 10, marginTop: 5 }}
                      type={'default'}
                      onClick={onTinkoffLink}
                      icon={<ApiOutlined />}
                      disabled
                    >
                      Tinkoff подключен
                    </Button>
                  </CanAccess>
                )} */}
                {/* {record?.monitoringStatus !== 'READY' &&
                  record.status !== UserStatus.ACCOUNT &&
                  record.status !== UserStatus.PRE_ACCOUNT && (
                    <CanAccess resource="users" action="balance">
                      <Button
                        style={{ marginLeft: 10, marginTop: 5 }}
                        type={'default'}
                        onClick={onTinkoffMonitor}
                        icon={<FileSearchOutlined />}
                        disabled={!record.tinkoffBankDetailsId}
                      >
                        Проверка в Росфинмониторинге
                      </Button>
                    </CanAccess>
                  )} */}
                {/* {record?.monitoringStatus === 'READY' && (
                  <CanAccess resource="users" action="balance">
                    <Button
                      style={{ marginLeft: 10, marginTop: 5 }}
                      type={'default'}
                      onClick={onTinkoffMonitor}
                      icon={<CheckOutlined />}
                      disabled
                    >
                      Проверка пройдена
                    </Button>
                  </CanAccess>
                )}
                <CanAccess resource="users" action="balance">
                  {showManual && (
                    <>
                      <Button
                        style={{ marginLeft: 10, marginTop: 5 }}
                        type={'primary'}
                        onClick={showModal}
                        icon={<PlusSquareOutlined />}
                      >
                        добавить средства
                      </Button>
                      <Button
                        style={{ marginLeft: 10, marginTop: 5 }}
                        danger
                        type={'primary'}
                        onClick={showModalMinus}
                        icon={<MinusSquareOutlined />}
                      >
                        вычесть средства
                      </Button>
                    </>
                  )}
                </CanAccess> */}
              </div>
            </>
          ),
        }}
        contentProps={{
          style: {
            background: 'none',
            // padding: '16px',
          },
        }}
      ></Show>
    </>
  ) : (
    <Spin style={{ padding: 20 }} />
  );
};
